function uppercaseString(route: string) {
  if (route === 'api-keys') {
    return 'API Keys';
  }

  if (route === 'api-logs') {
    return 'Logs';
  }

  if (route === 'sso') {
    return 'SSO';
  }

  return route
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export function buildPageTitle(pathname: string): string {
  const { 1: primaryRoute, 2: secondaryRoute } = pathname.split('/');

  const isEnvironmentId = primaryRoute === '[environmentId]';
  const routeName = isEnvironmentId ? secondaryRoute : primaryRoute;
  const title = uppercaseString(routeName || 'WorkOS');

  return title;
}
