import classnames from 'classnames';
import React from 'react';

export interface CardBodyProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

export const CardBody: React.FC<Readonly<CardBodyProps>> = ({
  className,
  children,
  ...props
}) => (
  <div {...props} className={classnames('p-4', className)}>
    {children}
  </div>
);
