import { useAdapterStatus, useFeatureToggle } from '@flopflip/react-broadcast';
import React, { FC } from 'react';
import { Database, Grid, Key, Server, Sliders, Terminal } from 'react-feather';
import { useCurrentEnvironment } from 'utils/current-environment-context';
import { SidebarItemLink } from './sidebar-item-link';

interface EnvironmentSidebarItemsProps {}

export const EnvironmentSidebarItems: FC<
  Readonly<EnvironmentSidebarItemsProps>
> = () => {
  const { currentEnvironment } = useCurrentEnvironment();
  const { isReady } = useAdapterStatus();
  const isAuditTrailEnabled = useFeatureToggle('auditTrailDashboard2');

  const sidebarItems = [
    {
      name: 'Organizations',
      paths: [
        '/[environmentId]/organizations',
        '/organizations',
        '/sso/connections',
        'directory-sync/connections',
      ],
      path: '/[environmentId]/organizations',
      icon: <Grid size={16} />,
    },
    {
      name: 'Configuration',
      paths: ['/configuration'],
      path: '/[environmentId]/configuration',
      icon: <Sliders size={16} />,
    },
    {
      name: 'Webhooks',
      paths: ['/webhooks'],
      path: '/[environmentId]/webhooks',
      icon: <Terminal size={16} />,
    },
    {
      name: 'API Keys',
      paths: ['/api-keys'],
      path: '/[environmentId]/api-keys',
      icon: <Key size={16} />,
    },
    {
      name: 'Logs',
      paths: ['/api-logs'],
      path: '/[environmentId]/api-logs',
      icon: <Database size={16} />,
    },
    ...(isReady && isAuditTrailEnabled
      ? [
          {
            name: 'Audit Trail',
            paths: ['/audit-trail'],
            path: '/[environmentId]/audit-trail',
            icon: <Server size={16} />,
          },
        ]
      : []),
  ];

  return (
    <div className="flex mt-2">
      <div className="mb-7">
        <div className="border-gray-lightmode-200 dark:border-gray-darkmode-200 border-l h-full mx-2" />
      </div>

      <div className="flex flex-col mt-2">
        {sidebarItems.map((item) => (
          <SidebarItemLink
            key={`sidebar-item-${item.name}`}
            currentEnvironment={currentEnvironment}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};
