import classnames from 'classnames';
import React from 'react';

export interface PageBodyProps {
  className?: string;
}

export const PageBody: React.FC<Readonly<PageBodyProps>> = ({
  children,
  className,
}) => <div className={classnames('flex flex-col', className)}>{children}</div>;
