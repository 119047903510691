import {
  Avatar,
  Dropdown,
  DropdownItemProps,
  Text,
} from '@workos-inc/component-library';
import { Select } from 'components/select';
import { AuthedUserFragment } from 'graphql/generated';
import { useUserRole } from 'hooks/use-user-role';
import router from 'next/router';
import { useTheme } from 'next-themes';
import React, { FC } from 'react';
import { ChevronDown, Monitor, Moon, Sun } from 'react-feather';
import { useAuth } from 'utils/auth-context';
import { trackThemeChanged } from 'utils/telemetry';

const getDropdownRows = (
  user: AuthedUserFragment | undefined,
  isUserAdmin: boolean,
) => {
  const DROPDOWN_ROWS: DropdownItemProps[] = [
    {
      key: 'dropdown-userinfo',
      divider: true,
      component: (
        <div>
          <span className="block text-sm font-bold text-gray-lightmode-400 dark:text-gray-darkmode-400">
            {user?.name}
          </span>
          <span className="block text-xs font-medium text-gray-lightmode-300 dark:text-gray-darkmode-300 mt-1">
            {user?.email}
          </span>
        </div>
      ),
    },
  ];

  if (isUserAdmin) {
    return [
      ...DROPDOWN_ROWS,
      {
        label: 'Profile',
        onClick: () => router.push('/profile'),
        divider: true,
      },
    ];
  }

  return DROPDOWN_ROWS;
};

export const UserDropdown: FC = () => {
  const { user } = useAuth();
  const { theme, setTheme, resolvedTheme } = useTheme();
  const { isUserAdmin } = useUserRole();

  const DROPDOWN_ROWS = getDropdownRows(user, isUserAdmin);

  const onChangeTheme = (event: { value: string }) => {
    setTheme(event.value);
    trackThemeChanged({ theme: event.value });

    const theme = event.value === 'system' ? resolvedTheme : event.value;

    analytics.identify({
      theme,
    });
  };

  const getDropdownItems = (): DropdownItemProps[] => [
    ...DROPDOWN_ROWS,
    {
      label: 'Theme',
      component: (
        <Select
          className="ml-6 w-32"
          id="theme"
          name="theme"
          onChange={onChangeTheme}
          options={[
            {
              label: (
                <span className="flex items-center">
                  <Sun className="mr-2" size={14} />
                  Light
                </span>
              ),
              value: 'light',
            },
            {
              label: (
                <span className="flex items-center">
                  <Moon className="mr-2" size={14} />
                  Dark
                </span>
              ),
              value: 'dark',
            },
            {
              label: (
                <span className="flex items-center">
                  <Monitor className="mr-2" size={14} />
                  System
                </span>
              ),
              value: 'system',
            },
          ]}
          placeholder="Choose theme"
          size="small"
          value={theme}
        />
      ),
      divider: true,
    },
    {
      label: 'Logout',
      onClick: () => router.push('/api/logout'),
    },
  ];

  return (
    <Dropdown content={getDropdownItems()}>
      <div
        className="flex items-center px-2 py-1 ml-2"
        data-testid="dropdown-trigger"
      >
        <Avatar>{user?.name?.charAt(0)}</Avatar>
        <Text as="h6" className="hidden ml-2 lg:block mr-2" weight="medium">
          {user?.name}
        </Text>

        <ChevronDown size={16} />
      </div>
    </Dropdown>
  );
};
