import { Text } from '@workos-inc/component-library';
import { useBeacon } from 'hooks/use-beacon';
import React, { FC } from 'react';

export const DashboardLoadError: FC = (): JSX.Element => {
  const [openBeacon] = useBeacon();
  return (
    <div className="text-center h-screen flex items-center justify-center">
      <div>
        <Text as="h1" className="mb-2" size="xlarge" weight="medium">
          We've encountered an error with this request.
        </Text>

        <Text as="p" className="max-w-md mx-auto">
          There was an issue with this request. Please try again. If the problem
          continues, please{' '}
          <span
            className="text-primary font-medium cursor-pointer"
            onClick={openBeacon}
          >
            reach out to us
          </span>
          .
        </Text>
      </div>
    </div>
  );
};
