import { useBeacon } from 'hooks/use-beacon';
import React, { FC } from 'react';
import { Book, BookOpen, MessageCircle, Settings } from 'react-feather';
import { useCurrentEnvironment } from 'utils/current-environment-context';
import { SidebarItem, SidebarItemLink } from './sidebar-item-link';

interface GeneralSidebarItemsProps {}

export const GeneralSidebarItems: FC<
  Readonly<GeneralSidebarItemsProps>
> = () => {
  const { currentEnvironment } = useCurrentEnvironment();
  const [openBeacon] = useBeacon();

  const sidebarItems: SidebarItem[] = [
    {
      name: 'Workspace',
      paths: ['/settings/'],
      path: '/settings/team',
      icon: <Settings size={16} />,
    },
    {
      name: 'Get Started',
      paths: ['/get-started'],
      path: '/get-started',
      icon: <BookOpen size={16} />,
    },
    {
      name: 'Docs',
      paths: ['/docs'],
      path: '/docs',
      href: process.env.NEXT_PUBLIC_DOCS_URL,
      newTab: true,
      icon: <Book size={16} />,
    },
    {
      name: 'Help',
      paths: ['/help'],
      icon: <MessageCircle size={16} />,
      onClick: openBeacon,
    },
  ];

  return (
    <div className="flex flex-col mt-6">
      {sidebarItems.map((item) => (
        <SidebarItemLink
          key={`sidebar-item-${item.name}`}
          currentEnvironment={currentEnvironment}
          item={item}
        />
      ))}
    </div>
  );
};
