import { Text } from '@workos-inc/component-library';
import classnames from 'classnames';
import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';

export interface PageTitleProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'title'
  > {
  title: ReactNode;
  titleSuffix?: ReactNode;
  reverseTruncateTitle?: boolean;
  subtitle?: ReactNode;
}

export const PageTitle: FC<Readonly<PageTitleProps>> = ({
  className,
  title,
  titleSuffix,
  reverseTruncateTitle = false,
  subtitle,
  children,
  ...props
}) => (
  <div {...props} className={classnames('pb-6', className)}>
    {subtitle && (
      <Text uppercase as="p" className="mb-1" size="small">
        {subtitle}
      </Text>
    )}

    <div className="flex justify-between items-center">
      <div className="min-w-0 h-10">
        <div className="flex items-center">
          <Text
            as="h2"
            className={classnames('break-all', {
              'mr-4': !!titleSuffix,
            })}
            size="xlarge"
            style={
              reverseTruncateTitle
                ? {
                    direction: 'rtl',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }
                : undefined
            }
            weight="medium"
          >
            {title}
          </Text>
          {titleSuffix && <div>{titleSuffix}</div>}
        </div>
      </div>

      <div className="flex ml-4">{children}</div>
    </div>
  </div>
);
