/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useAuth } from 'utils/auth-context';

interface BeaconOptions {
  text?: string;
  emailSupport?: string;
}

export function useBeacon(options?: BeaconOptions): [() => void] {
  const [isBeaconEnabled, setBeacon] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    setBeacon(
      Boolean(
        typeof window === 'object' &&
          'Beacon' in window &&
          process.env.NEXT_PUBLIC_BEACON_ID,
      ),
    );
  }, []);

  async function openBeacon() {
    if (isBeaconEnabled) {
      const w = window as any;

      const identity = user ? { name: user.name, email: user.email } : {};

      if (identity) {
        w.Beacon('identify', identity);
      }

      w.Beacon('init', process.env.NEXT_PUBLIC_BEACON_ID);

      if (options?.text) {
        w.Beacon('prefill', {
          text: options.text,
        });
      }

      return w.Beacon('open');
    }

    const emailSupport = options?.emailSupport
      ? options?.emailSupport
      : 'support@workos.com';
    return window.location.assign(`mailto:${emailSupport}`);
  }

  return [openBeacon];
}
