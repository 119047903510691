import { CurrentTeamFragment } from 'graphql/generated';
import React, { createContext, useContext, useState } from 'react';

interface ContextProps {
  currentTeam: CurrentTeamFragment | null | undefined;
  setCurrentTeam: (value: CurrentTeamFragment) => void;
  hasPaymentMethod: boolean;
  setHasPaymentMethod: (value: boolean) => void;
}

export const CurrentTeamContext = createContext<ContextProps | null>(null);

export interface CurrentTeamProviderProps {}

export const CurrentTeamProvider: React.FC<
  Readonly<CurrentTeamProviderProps>
> = ({ children }) => {
  const [currentTeam, setCurrentTeam] = useState<CurrentTeamFragment | null>();
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

  return (
    <CurrentTeamContext.Provider
      value={{
        currentTeam,
        setCurrentTeam,
        setHasPaymentMethod,
        hasPaymentMethod,
      }}
    >
      {children}
    </CurrentTeamContext.Provider>
  );
};

export function useCurrentTeam(): ContextProps {
  const currentTeamContext = useContext(CurrentTeamContext);
  if (!currentTeamContext) {
    throw new TypeError(
      '`useCurrentTeam` must be called from within an `CurrentTeamProvider`',
    );
  }

  return currentTeamContext;
}
