import { useAdapterStatus } from '@flopflip/react-broadcast';
import { CreditCardRequired } from 'components/billing/credit-card-required';
import { Loader } from 'components/loader';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { buildPageTitle } from 'utils/build-page-title';
import { useCurrentEnvironment } from 'utils/current-environment-context';
import { useCurrentTeam } from 'utils/current-team-context';

interface CreditCardCheckProps {
  children: React.ReactNode;
}

export const CreditCardCheck: FC<Readonly<CreditCardCheckProps>> = ({
  children,
}) => {
  const router = useRouter();
  const { currentEnvironment } = useCurrentEnvironment();
  const { currentTeam, hasPaymentMethod } = useCurrentTeam();

  const { isReady } = useAdapterStatus();
  const shouldShowCreditCardRequiredPrompt =
    !router.route.startsWith('/settings') &&
    currentEnvironment?.sandbox === false &&
    !hasPaymentMethod;

  if (!currentTeam || !isReady) {
    return <Loader size="md" />;
  }

  return (
    <>
      <Head>
        <title>{buildPageTitle(router.pathname)} | WorkOS</title>
      </Head>
      {shouldShowCreditCardRequiredPrompt ? <CreditCardRequired /> : children}
    </>
  );
};
