import { Text } from '@workos-inc/component-library';
import classnames from 'classnames';
import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';

export interface CardHeaderProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
  description?: string | ReactNode;
}

export const CardHeader: FC<Readonly<CardHeaderProps>> = ({
  className,
  children,
  description,
  title,
}) => (
  <div
    className={classnames(
      'border-b p-4',
      'border-gray-lightmode-200',
      'dark:border-gray-darkmode-200',
      className,
    )}
  >
    <div className="flex items-center justify-between">
      {(title || description) && (
        <div>
          <Text as="h3" weight="medium">
            {title}
          </Text>

          {description && (
            <Text multiline as="p" className="max-w-2xl">
              {description}
            </Text>
          )}
        </div>
      )}

      {children}
    </div>
  </div>
);
