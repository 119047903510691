import { ThemeProvider } from 'next-themes';
import React, { createContext, FC, useContext, useEffect } from 'react';
import { useAuth } from './auth-context';

type Theme = 'dark' | 'light' | undefined;

interface ContextProps {
  theme: Theme;
}

const DarkModeContext = createContext<ContextProps>({ theme: undefined });

export interface DarkModeProviderProps {
  theme: Theme;
}

export const DarkModeProvider: FC<Readonly<DarkModeProviderProps>> = ({
  children,
  theme,
}) => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
        .matches;

      analytics.identify({
        theme: isDarkMode ? 'dark' : 'light',
      });
    }
  }, [user]);

  return (
    <ThemeProvider
      disableTransitionOnChange
      enableSystem
      attribute="class"
      forcedTheme={theme}
    >
      <DarkModeContext.Provider value={{ theme }}>
        {children}
      </DarkModeContext.Provider>
    </ThemeProvider>
  );
};

export function useDarkMode(): ContextProps {
  const darkModeContext = useContext(DarkModeContext);

  if (!darkModeContext) {
    throw new TypeError(
      '`useDarkMode` must be called from within an `DarkModeProvider`',
    );
  }

  return darkModeContext;
}
