import * as E from 'fp-ts/Either';
import * as t from 'io-ts';
import Bugsnag from 'utils/bugsnag';
import {
  AnalyticEvent,
  ApiLogIdCopied,
  BillingLinkViewed,
  DomainAddedToOrganization,
  EmailNotificationPreferencesUpdated,
  OrganizationCreated,
  ThemeChanged,
  UserSignedUp,
  WebhookSecretCopied,
} from './events';

const createTracker = <T extends AnalyticEvent>(dto: T) => (
  traits: t.TypeOf<T> = {},
) => {
  const decoded = dto.decode(traits);

  if (E.isLeft(decoded)) {
    return Bugsnag.notify(
      new TypeError(`Malformed Analytic Event Payload: ${dto.name}`),
    );
  }

  analytics.track(dto.name, decoded.right);
};

export const trackApiLogIdCopied = createTracker<ApiLogIdCopied>(
  ApiLogIdCopied,
);

export const trackBillingLinkViewed = createTracker<BillingLinkViewed>(
  BillingLinkViewed,
);

export const trackDomainAddedToOrganization = createTracker<DomainAddedToOrganization>(
  DomainAddedToOrganization,
);

export const trackEmailNotificationPreferencesUpdated = createTracker<EmailNotificationPreferencesUpdated>(
  EmailNotificationPreferencesUpdated,
);

export const trackOrganizationCreated = createTracker<OrganizationCreated>(
  OrganizationCreated,
);

export const trackThemeChanged = createTracker<ThemeChanged>(ThemeChanged);

export const trackUserSignedUp = createTracker<UserSignedUp>(UserSignedUp);

export const trackWebhookSecretCopied = createTracker<WebhookSecretCopied>(
  WebhookSecretCopied,
);
