import assert from 'assert';
import { DashboardLoadError } from 'components/dashboard-load-error';
import { Loader } from 'components/loader';
import { OnboardingGuard } from 'components/onboarding/onboarding-guard';
import { UnauthenticatedPage } from 'components/page';
import { EnvironmentFragment } from 'graphql/generated';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useAuth } from 'utils/auth-context';
import Bugsnag, { setBugsnagSession } from 'utils/bugsnag';
import { useCurrentEnvironment } from 'utils/current-environment-context';
import { useCurrentTeam } from 'utils/current-team-context';
import { sdk } from 'utils/sdk';

export interface WithPageProps {
  isProtected?: boolean;
  metaTitle: string;
  metaCanonical?: string;
  theme?: 'light' | 'dark' | undefined;
}

// Auth HOC to set user to context
export const withPage = (
  WrappedComponent: FC<unknown>,
  { isProtected = true, metaTitle, metaCanonical, theme }: WithPageProps,
): (() => JSX.Element) => {
  const Component = (): JSX.Element => {
    const router = useRouter();
    const { user: authUser, setUser } = useAuth();
    const {
      setAvailableEnvironments,
      setCurrentEnvironment,
    } = useCurrentEnvironment();
    const { setCurrentTeam, setHasPaymentMethod } = useCurrentTeam();

    const [error, setError] = useState(false);

    const isAuthenticatingRef = useRef<boolean>(false);

    useEffect(() => {
      const authenticateUser = async () => {
        try {
          const {
            currentEnvironment,
            currentTeam,
            me,
          } = await sdk().dashboardSession();
          const environmentOnPageLoad = await determineEnvironmentOnPageLoad(
            currentEnvironment,
          );

          setAvailableEnvironments(currentEnvironment.projectV2.environments);
          setCurrentEnvironment(environmentOnPageLoad);
          setUser(me);
          setBugsnagSession(me);
          assert.ok(currentTeam);
          setCurrentTeam(currentTeam);
          setHasPaymentMethod(
            Boolean(currentTeam?.billingDetails?.hasPaymentMethod),
          );
          isAuthenticatingRef.current = false;

          // Redirect to the dashboard root if we're on the login page and we're authenticated
          if (router.pathname === '/login') {
            return void router.push('/');
          }
        } catch (error) {
          isAuthenticatingRef.current = false;
          if (isProtected) {
            if (error instanceof Error) {
              const { message } = error;
              // EnvironmentID not found in the URL, re-direct to the home page
              if (error.message.includes('Environment not found')) {
                await router.replace('/');
              } else if (!error.message.includes('Forbidden')) {
                setError(true);
                Bugsnag.notify(
                  new Error('Dashboard failed to load'),
                  (event) => {
                    event.addMetadata('ErrorMessage', {
                      message,
                    });
                  },
                );
                return;
              }
            }
            await router.replace(`/login?redirect=${router.asPath}`);
          }
        }
      };

      const determineEnvironmentOnPageLoad = async (
        environmentFromSession: EnvironmentFragment,
      ): Promise<EnvironmentFragment> => {
        const environmentIdFromRoute = router.query?.environmentId;
        let environmentOnPageLoad: EnvironmentFragment = environmentFromSession;
        if (environmentIdFromRoute) {
          const { environment } = await sdk().environment({
            id: environmentIdFromRoute as string,
          });
          environmentOnPageLoad = environment;
        }
        return environmentOnPageLoad;
      };

      // The router.isReady check is critical on first page load, because it needs to resolve
      // the [environmentId] dynamic route to a value
      if (
        !authUser &&
        !isAuthenticatingRef.current &&
        router.isReady &&
        (isProtected || router.pathname === '/login')
      ) {
        isAuthenticatingRef.current = true;
        void authenticateUser();
      }
    }, [
      authUser,
      router,
      setAvailableEnvironments,
      setCurrentEnvironment,
      setCurrentTeam,
      setHasPaymentMethod,
      setUser,
    ]);

    if (isProtected) {
      if (error) {
        return <DashboardLoadError />;
      }

      return authUser ? (
        <OnboardingGuard>
          <Head>
            <title>{metaTitle} | WorkOS</title>
            {metaCanonical && (
              <link
                href={`${process.env.NEXT_PUBLIC_DASHBOARD2_URL}/${metaCanonical}`}
                rel="canonical"
              />
            )}
          </Head>

          <WrappedComponent />
        </OnboardingGuard>
      ) : (
        <div className="w-full flex items-center justify-center mt-40">
          <Loader size="md" />
        </div>
      );
    }

    return (
      <UnauthenticatedPage>
        <Head>
          <title>{metaTitle} | WorkOS</title>
          {metaCanonical && (
            <link
              href={`${process.env.NEXT_PUBLIC_DASHBOARD2_URL}/${metaCanonical}`}
              rel="canonical"
            />
          )}
        </Head>

        <WrappedComponent />
      </UnauthenticatedPage>
    );
  };

  Component.theme = theme;

  return Component;
};
