export enum OnboardingStep {
  Initial = 'initial',
  InviteTeam = 'invite-team',
}

export const parseOnboardingStep = (
  value: string,
): OnboardingStep | undefined => {
  switch (value) {
    case OnboardingStep.Initial:
      return OnboardingStep.Initial;
    case OnboardingStep.InviteTeam:
      return OnboardingStep.InviteTeam;
    default:
      return undefined;
  }
};
