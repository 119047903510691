import classnames from 'classnames';
import { Logo } from 'components/logo';
import React from 'react';
import { useCurrentTeam } from 'utils/current-team-context';
import { EnvironmentDropdown } from './environment-dropdown';
import { EnvironmentSidebarItems, GeneralSidebarItems } from './sidebar-items';

interface SidebarProps {}

export const Sidebar: React.FC<Readonly<SidebarProps>> = () => {
  const { currentTeam } = useCurrentTeam();

  return (
    <div
      className={classnames(
        'w-60 flex flex-col p-6 border-r',
        'bg-gray-lightmode-100 border-gray-lightmode-200',
        'dark:bg-gray-darkmode-100 dark:border-gray-darkmode-200',
      )}
      data-testid="sidebar"
    >
      <div>
        <Logo logomark={false} size="18px" />

        <span
          className="line-clamp-2 text-sm mb-10 mt-2 font-medium"
          title={currentTeam?.name}
        >
          {currentTeam?.name}
        </span>
      </div>

      <EnvironmentDropdown />
      <EnvironmentSidebarItems />
      <GeneralSidebarItems />
    </div>
  );
};
