import { NextPageContext } from 'next';
import nookies from 'nookies';

export type Cookies = {
  csrfToken: string;
};

export function getCookies(context?: NextPageContext): Cookies {
  const cookies = nookies.get(context);

  if (Object.keys(cookies).length > 0) {
    return {
      csrfToken: cookies._csrf || '',
    };
  }

  return { csrfToken: '' };
}
