import classnames from 'classnames';
import { UserDropdown } from 'components/user-dropdown';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useCurrentEnvironment } from 'utils/current-environment-context';
import { Feedback } from './feedback';

export interface TopbarProps {}

const environmentAgnosticPages = [
  '/settings',
  '/settings/team',
  '/settings/billing',
];

export const Topbar: FC<Readonly<TopbarProps>> = () => {
  const router = useRouter();
  const { currentEnvironment } = useCurrentEnvironment();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16">
      {currentEnvironment &&
        !environmentAgnosticPages.includes(router.pathname) && (
          <div
            className={classnames([
              'block',
              'fixed',
              'font-bold',
              'left-60',
              'mx-auto',
              'overflow-ellipsis',
              'overflow-hidden',
              'px-3',
              'py-1',
              'right-0',
              'rounded-b-md',
              'select-none',
              'text-center',
              'text-xs',
              'w-32',
              'whitespace-nowrap',
              {
                'bg-orange-light text-orange-darkest':
                  currentEnvironment.sandbox,
                'bg-green-lighten text-green-darkest': !currentEnvironment.sandbox,
              },
            ])}
            data-testid="environment-badge"
            title={currentEnvironment.name}
          >
            {currentEnvironment.name}
          </div>
        )}

      <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8 items-center">
        <div className="flex-1 flex items-center">
          <div id="back-button" />
        </div>
        <Feedback />
        <div className="flex items-center">
          <UserDropdown />
        </div>
      </div>
    </div>
  );
};
