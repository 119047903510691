export const ENVIRONMENT_AGNOSTIC_ROUTES = new Set([
  '/auth/create-account',
  '/auth/confirm-account',
  '/auth/onboarding',
  '/auth/onboarding/invite-team',
  '/auth/reset-password',
  '/auth/verify',
  '/get-started',
  '/login',
  '/profile',
  '/settings/billing',
  '/settings/team',
  '/signup',
]);

export const isEnvironmentAgnosticRoute = (route: string): boolean =>
  ENVIRONMENT_AGNOSTIC_ROUTES.has(route);
