import {
  Button,
  Popover,
  PopoverClose,
  Text,
} from '@workos-inc/component-library';
import axios from 'axios';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC, useState } from 'react';
import { Feather } from 'react-feather';

export interface FeedbackProps {}

export const Feedback: FC<Readonly<FeedbackProps>> = () => {
  const [feedback, setFeedback] = useState('');
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [sent, setSent] = useState(false);

  const onSendFeedback = async () => {
    setSendingFeedback(true);
    await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/feedback`,
      {
        message: feedback,
        page: window.location.href,
      },
      {
        withCredentials: true,
      },
    );
    setSendingFeedback(false);
    setSent(true);
  };

  return (
    <Popover
      className="w-80"
      onCloseAutoFocus={() => {
        setFeedback('');
        setSent(false);
      }}
      title="Feedback"
      trigger={
        <Button appearance="minimal" iconLeft={<Feather size={16} />}>
          Feedback?
        </Button>
      }
    >
      {sent ? (
        <div className="text-center p-8 -mt-4">
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 4 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <Feather className="m-auto text-primary dark:text-gray-darkmode-400" />
          </motion.div>

          <motion.div
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 4 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <Text as="h2" className="mt-4" weight="medium">
              Thanks for your time
            </Text>
          </motion.div>

          <motion.div
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 4 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <Text multiline as="p" className="mt-1">
              Your feedback improves WorkOS.
            </Text>
          </motion.div>
        </div>
      ) : (
        <>
          <textarea
            autoFocus={true}
            className={classnames(
              'w-full resize-none outline-none rounded border p-3 text-sm h-28 ease-in-out transition duration-100',
              'bg-white text-gray-lightmode-400 border-gray-lightmode-200 placeholder-gray-lightmode-300',
              'dark:bg-gray-darkmode-50 dark:text-gray-darkmode-400 dark:border-gray-darkmode-200 dark:placeholder-gray-darkmode-300',
            )}
            id="feedback"
            name="feedback"
            onChange={(event) => setFeedback(event.target.value)}
            placeholder="Help us improve our Dashboard."
            value={feedback}
          />

          <div className="flex items-center justify-between mt-2">
            <PopoverClose asChild>
              <Button appearance="minimal" size="small">
                Cancel
              </Button>
            </PopoverClose>

            <Button
              appearance="primary"
              disabled={feedback.length === 0 || sendingFeedback}
              onClick={onSendFeedback}
              size="small"
            >
              Send Feedback
            </Button>
          </div>
        </>
      )}
    </Popover>
  );
};
