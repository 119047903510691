import { AuthedUserFragment } from 'graphql/generated';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface ContextProps {
  user: AuthedUserFragment | undefined;
  isAuthenticated: boolean;
  setUser: (user: AuthedUserFragment | undefined) => void;
}

const AuthContext = createContext<ContextProps | null>(null);

export interface AuthProviderProps {}

export const AuthProvider: React.FC<Readonly<AuthProviderProps>> = ({
  children,
}) => {
  const [user, setUser] = useState<AuthedUserFragment>();

  useEffect(() => {
    if (user) {
      const { id, email } = user;
      analytics.identify(id, { email });
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): ContextProps {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new TypeError(
      '`useAuth` must be called from within an `AuthProvider`',
    );
  }

  return authContext;
}
