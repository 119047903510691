import { Alert, Button } from '@workos-inc/component-library';
import { Link } from 'components/link';
import { useRouter } from 'next/router';
import React from 'react';

interface CreditCardRequiredProps {}

const BILLING_SETTINGS_URL = '/settings/billing';

export const CreditCardRequired: React.FC<
  Readonly<CreditCardRequiredProps>
> = () => {
  const router = useRouter();

  return (
    <div className="w-full">
      <Alert appearance="green">
        Hey there! To access production features, you’ll need to enter your
        credit card details for our standard pay-as-you-go plan. Don’t worry, we
        won’t start charging you until you have a linked connection in
        production.
        <br />
        Head on over to the{' '}
        <Link appearance="highlight" href={BILLING_SETTINGS_URL}>
          Billing page
        </Link>{' '}
        to enter in the details.
      </Alert>

      <Button
        appearance="primary"
        className="mt-6"
        onClick={() => {
          void router.push(BILLING_SETTINGS_URL);
        }}
      >
        Upgrade to Starter Plan
      </Button>
    </div>
  );
};
