import React, { FC } from 'react';

export interface LogoProps {
  color?: string;
  size: string;
  logomark?: boolean;
  className?: string;
  wordmark?: boolean;
}

export const Logo: FC<Readonly<LogoProps>> = ({
  className,
  size,
  logomark = true,
  wordmark = true,
}) => (
  <>
    {wordmark && logomark && (
      <svg
        className={className}
        fill="none"
        height={size}
        viewBox="0 0 880 168"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="text-black dark:text-white">
          <path
            d="M255.264 25.257H279.579L297.086 97.3994C300.332 111.016 300.976 119.125 300.976 119.125H301.304C301.304 119.125 302.435 111.186 305.681 97.3994L322.373 25.257H349.934L367.599 97.3994C371.003 111.502 371.818 119.125 371.818 119.125H372.304C372.304 119.125 372.632 111.502 375.866 97.3994L392.729 25.257H417.043L386.249 143.598H359.174L340.536 71.9417C336.646 56.3801 336.318 49.4139 336.318 49.4139H335.99C335.99 49.4139 335.661 56.3801 332.099 71.9417L314.592 143.598H286.715L255.264 25.257Z"
            fill="currentColor"
          />
          <path
            d="M412.984 100.606C412.984 73.5314 430.491 56.0246 456.921 56.0246C483.181 56.0246 500.688 73.5314 500.688 100.606C500.688 127.839 483.181 145.516 456.921 145.516C430.503 145.504 412.984 127.839 412.984 100.606ZM477.99 100.606C477.99 83.0993 469.565 73.2031 456.921 73.2031C443.305 73.2031 435.682 84.2299 435.682 100.606C435.682 118.441 444.107 128.325 456.921 128.325C470.537 128.325 477.99 117.298 477.99 100.606Z"
            fill="currentColor"
          />
          <path
            d="M511.546 57.3515H533.101V73.2413H533.587C537.319 64.9742 546.231 56.7072 561.464 56.7072C564.054 56.7072 565.683 57.0354 566.813 57.3515V78.9067H566.169C566.169 78.9067 564.224 78.2624 558.875 78.2624C542.182 78.2624 533.101 88.1464 533.101 106.626V143.584H511.546V57.3515Z"
            fill="currentColor"
          />
          <path
            d="M576.868 25.257H598.423V63.1883C598.423 85.558 598.095 89.6065 598.095 89.6065H598.423L630.519 57.3527H657.424L619.833 94.6397L663.442 143.598H637.984L606.532 107.928L598.423 115.867V143.586H576.868V25.257Z"
            fill="currentColor"
          />
          <path
            d="M663.757 84.8601C663.757 48.3876 686.455 24.0727 720.496 24.0727C754.537 24.0727 777.235 48.3876 777.235 84.8601C777.235 121.333 754.537 145.647 720.496 145.647C686.455 145.647 663.757 121.333 663.757 84.8601ZM753.722 84.8601C753.722 60.0588 740.434 43.3545 720.483 43.3545C700.533 43.3545 687.257 60.0588 687.257 84.8601C687.257 109.661 700.545 126.366 720.483 126.366C740.422 126.366 753.722 109.661 753.722 84.8601Z"
            fill="currentColor"
          />
          <path
            d="M785.488 104.207H810.131C810.131 118.151 819.699 125.932 834.288 125.932C846.604 125.932 855.041 119.768 855.041 111.015C855.041 101.131 848.561 98.5292 827.966 94.4808C809.158 90.7484 789.22 84.4265 789.22 59.7833C789.22 39.2007 806.727 23.6392 833.473 23.6392C861.35 23.6392 878.541 38.2281 878.541 60.2696H853.898C853.898 49.5711 845.631 42.9209 833.473 42.9209C821.158 42.9209 813.535 48.9146 813.535 57.6679C813.535 66.9076 818.884 70.4698 835.419 73.8739C859.892 79.0651 880 81.8127 880 109.045C880 130.625 861.192 145.214 833.473 145.214C805.268 145.214 785.488 128.838 785.488 104.207Z"
            fill="currentColor"
          />
        </g>
        <g className="text-primary">
          <path
            d="M0 83.8807C0 87.5586 0.967855 91.2364 2.83904 94.3981L36.7785 153.179C40.2628 159.18 45.5537 164.084 52.1351 166.277C65.1044 170.601 78.5253 165.051 84.9131 153.953L93.1076 139.758L60.7813 83.8807L94.9143 24.7126L103.109 10.5174C105.561 6.25879 108.851 2.77452 112.723 0H109.174H60.0715C50.8446 0 42.3275 4.9038 37.7463 12.9047L2.83904 73.3634C0.967855 76.525 0 80.2029 0 83.8807Z"
            fill="currentColor"
          />
          <path
            d="M193.571 83.8802C193.571 80.2023 192.603 76.5245 190.732 73.3628L156.341 13.8075C149.953 2.77395 136.532 -2.77508 123.563 1.48348C116.982 3.67728 111.691 8.58108 108.206 14.5818L100.464 27.9382L132.79 83.8802L98.6569 143.048L90.4624 157.244C88.0105 161.438 84.7198 164.986 80.8484 167.761H84.3972H133.5C142.727 167.761 151.244 162.857 155.825 154.856L190.732 94.3975C192.603 91.2359 193.571 87.558 193.571 83.8802Z"
            fill="currentColor"
          />
        </g>
      </svg>
    )}
    {wordmark && !logomark && (
      <svg
        className={className}
        fill="none"
        height={size}
        viewBox="0 0 634 125"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="text-black dark:text-white">
          <path
            d="M0 2H24.6564L42.4089 75.1554C45.7005 88.9629 46.3539 97.1858 46.3539 97.1858H46.6868C46.6868 97.1858 47.8333 89.1355 51.1249 75.1554L68.0515 2H95.9995L113.912 75.1554C117.364 89.4561 118.19 97.1858 118.19 97.1858H118.683C118.683 97.1858 119.016 89.4561 122.296 75.1554L139.395 2H164.051L132.824 122.002H105.369L86.4698 49.3402C82.5248 33.5601 82.192 26.4961 82.192 26.4961H81.8591C81.8591 26.4961 81.5262 33.5601 77.9141 49.3402L60.1615 122.002H31.893L0 2Z"
            fill="currentColor"
          />
          <path
            d="M159.934 78.5817C159.934 51.1268 177.687 33.3743 204.488 33.3743C231.117 33.3743 248.87 51.1268 248.87 78.5817C248.87 106.197 231.117 124.122 204.488 124.122C177.699 124.11 159.934 106.197 159.934 78.5817ZM225.853 78.5817C225.853 60.8291 217.309 50.794 204.488 50.794C190.681 50.794 182.951 61.9756 182.951 78.5817C182.951 96.6671 191.494 106.69 204.488 106.69C218.296 106.69 225.853 95.5083 225.853 78.5817Z"
            fill="currentColor"
          />
          <path
            d="M259.88 34.72H281.737V50.833H282.23C286.015 42.4498 295.052 34.0667 310.499 34.0667C313.125 34.0667 314.777 34.3995 315.923 34.72V56.5779H315.27C315.27 56.5779 313.298 55.9245 307.873 55.9245C290.947 55.9245 281.737 65.9473 281.737 84.6861V122.164H259.88V34.72Z"
            fill="currentColor"
          />
          <path
            d="M326.12 2.17383H347.977V40.6377C347.977 63.3216 347.645 67.4269 347.645 67.4269H347.977L380.524 34.7202H407.806L369.687 72.5307L413.909 122.176H388.093L356.2 86.0054L347.977 94.0557V122.164H326.12V2.17383Z"
            fill="currentColor"
          />
          <path
            d="M414.229 62.615C414.229 25.6305 437.245 0.974121 471.764 0.974121C506.283 0.974121 529.3 25.6305 529.3 62.615C529.3 99.5995 506.283 124.256 471.764 124.256C437.245 124.256 414.229 99.5995 414.229 62.615ZM505.457 62.615C505.457 37.4655 491.982 20.5266 471.752 20.5266C451.521 20.5266 438.059 37.4655 438.059 62.615C438.059 87.7645 451.534 104.703 471.752 104.703C491.97 104.703 505.457 87.7645 505.457 62.615Z"
            fill="currentColor"
          />
          <path
            d="M537.669 82.233H562.658C562.658 96.3734 572.36 104.263 587.154 104.263C599.642 104.263 608.198 98.013 608.198 89.1368C608.198 79.114 601.627 76.4757 580.743 72.3704C561.672 68.5857 541.453 62.175 541.453 37.1858C541.453 16.3142 559.206 0.53418 586.328 0.53418C614.596 0.53418 632.029 15.328 632.029 37.679H607.039C607.039 26.8302 598.656 20.0867 586.328 20.0867C573.84 20.0867 566.11 26.1645 566.11 35.0407C566.11 44.4101 571.534 48.0223 588.301 51.4742C613.117 56.7383 633.508 59.5245 633.508 87.1396C633.508 109.022 614.436 123.816 586.328 123.816C557.727 123.816 537.669 107.21 537.669 82.233Z"
            fill="currentColor"
          />
        </g>
      </svg>
    )}

    {logomark && !wordmark && (
      <svg
        className={className}
        fill="none"
        height={size}
        viewBox="0 0 240 208"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="text-primary">
          <path
            d="M0 104C0 108.56 1.2 113.12 3.52 117.04L45.5999 189.92C49.9199 197.36 56.4799 203.44 64.6399 206.16C80.7199 211.52 97.3599 204.64 105.28 190.88L115.44 173.28L75.3599 104L117.68 30.64L127.84 13.04C130.88 7.75999 134.96 3.44 139.76 0H135.36H74.4799C63.0399 0 52.4799 6.07999 46.7999 16L3.52 90.9599C1.2 94.8799 0 99.4399 0 104Z"
            fill="currentColor"
          />
          <path
            d="M240 103.999C240 99.4391 238.8 94.8791 236.48 90.9591L193.84 17.1192C185.92 3.43923 169.28 -3.44076 153.2 1.83923C145.04 4.55923 138.48 10.6392 134.16 18.0792L124.56 34.6392L164.64 103.999L122.32 177.359L112.16 194.959C109.12 200.159 105.04 204.559 100.24 207.999H104.64H165.52C176.96 207.999 187.52 201.919 193.2 191.999L236.48 117.039C238.8 113.119 240 108.559 240 103.999Z"
            fill="currentColor"
          />
        </g>
      </svg>
    )}
  </>
);
