import { Popover, PopoverClose } from '@workos-inc/component-library';
import classnames from 'classnames';
import React, { FC } from 'react';
import { ChevronDown } from 'react-feather';
import { useCurrentEnvironment } from 'utils/current-environment-context';
import { EnvironmentDisplay } from './environment-display';

interface EnvironmentDropdownProps {}

export const EnvironmentDropdown: FC<
  Readonly<EnvironmentDropdownProps>
> = () => {
  const {
    availableEnvironments,
    currentEnvironment,
    setCurrentEnvironment,
  } = useCurrentEnvironment();

  if (!currentEnvironment) {
    return null;
  }

  return (
    <Popover
      align="end"
      className="w-192"
      title="Environments"
      trigger={
        <div
          className="flex group justify-between appearance-none"
          data-testid={`environment-dropdown-${currentEnvironment.id}`}
        >
          <EnvironmentDisplay
            environment={currentEnvironment}
            isHoverEnabled={false}
          />

          <div className="flex items-center">
            <div
              className={classnames(
                'border h-min p-1 rounded w-min transition duration-200 ease-in-out cursor-pointer',
                'text-gray-lightmode-300 border-gray-lightmode-200',
                'dark:text-gray-darkmode-300 dark:border-gray-darkmode-200',
                'group-hover:text-primary group-hover:border-primary',
                'dark:group-hover:text-gray-darkmode-400 dark:group-hover:border-gray-darkmode-400',
              )}
            >
              <ChevronDown className={classnames('text-current')} size={14} />
            </div>
          </div>
        </div>
      }
    >
      <>
        {availableEnvironments.map((environment) => (
          <PopoverClose key={`environment-popover-${environment.id}`} asChild>
            <div
              className="py-2 appearance-none"
              data-testid={`environment-popover-${environment.id}`}
              onClick={() => setCurrentEnvironment(environment)}
              role="button"
            >
              <EnvironmentDisplay
                environment={environment}
                isHoverEnabled={true}
              />
            </div>
          </PopoverClose>
        ))}
      </>
    </Popover>
  );
};
