import { useFeatureToggle } from '@flopflip/react-broadcast';
import { CreditCardCheck } from 'components/billing/credit-card-check';
import { Sidebar } from 'components/sidebar';
import { Topbar } from 'components/topbar';
import React, { FC } from 'react';
import { SWRConfig } from 'swr';
import { PageBody } from './page-body';
import { PageTitle } from './page-title';

export interface PageProps {}

export interface Page {
  Title: typeof PageTitle;
  Body: typeof PageBody;
}

export const Page: FC<Readonly<PageProps>> & Page = ({ children }) => {
  const disableRevalidateOnFocus = useFeatureToggle('disableRevalidateOnFocus');
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: !disableRevalidateOnFocus,
      }}
    >
      <div className="h-screen flex overflow-hidden min-w-256">
        <Sidebar />

        <div className="flex-1 overflow-auto focus:outline-none" tabIndex={0}>
          <Topbar />

          <main className="flex-1 relative pb-6 z-0 mt-6">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <CreditCardCheck>{children}</CreditCardCheck>
            </div>
          </main>
        </div>
      </div>
    </SWRConfig>
  );
};

Page.Title = PageTitle;
Page.Body = PageBody;
