import classnames from 'classnames';
import React from 'react';

export interface LoaderProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  size?: 'sm' | 'md' | 'lg';
}

const loaderSizeSm = 'h-8 w-8';
const loaderSizeMd = 'h-12 w-12';
const loaderSizeLg = 'h-16 w-16';

export const Loader: React.FC<Readonly<LoaderProps>> = ({
  className,
  children,
  size = 'sm',
  ...props
}) => (
  <div
    {...props}
    className={classnames('flex items-center justify-center', className)}
  >
    <svg
      className={classnames(
        'animate-spin -ml-1 mr-3 text-gray-lightmode-300 dark:text-gray-darkmode-300',
        {
          [loaderSizeSm]: size === 'sm',
          [loaderSizeMd]: size === 'md',
          [loaderSizeLg]: size === 'lg',
        },
      )}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      />
    </svg>
    <div className="text-gray-lightmode-300 dark:text-gray-darkmode-300">
      {children}
    </div>
  </div>
);
