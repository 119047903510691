import 'tailwindcss/tailwind.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Toaster } from '@workos-inc/component-library';
import { BugsnagErrorBoundary } from 'components/error-boundaries';
import { usePageView } from 'hooks/use-page-view';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React, { FC } from 'react';
import { AuthProvider } from 'utils/auth-context';
import { CurrentEnvironmentProvider } from 'utils/current-environment-context';
import { CurrentTeamProvider } from 'utils/current-team-context';
import { DarkModeProvider } from 'utils/dark-mode-context';
import { FeatureFlagsProvider } from 'utils/feature-flags-context';
import { initDataDogRum } from 'utils/telemetry/datadog';

const stripePromise = loadStripe(
  `${process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`,
);

const title = 'WorkOS | Your app, Enterprise Ready';
const description =
  'WorkOS Dashboard - WorkOS provides an API for Single Sign-On (SSO), Directory Sync (SCIM), Audit Trail (SIEM), and more. Easily integrate Okta SAML, Google OAuth, and more.';

const MyApp: FC<
  Readonly<{
    Component: AppProps['Component'] & { theme: 'light' | 'dark' | undefined };
    pageProps: AppProps['pageProps'];
  }>
> = ({ Component, pageProps }) => {
  usePageView();

  initDataDogRum();

  return (
    <BugsnagErrorBoundary>
      <Head>
        <meta content="width=1023" name="viewport" />

        <title>{title}</title>
        <meta content={description} name="description" />

        {/* Schema.org for Google */}
        <meta content={title} itemProp="name" />
        <meta content={description} itemProp="description" />
      </Head>

      <Elements stripe={stripePromise}>
        <AuthProvider>
          <CurrentEnvironmentProvider>
            <CurrentTeamProvider>
              <FeatureFlagsProvider>
                <DarkModeProvider theme={Component.theme}>
                  <Component {...pageProps} />
                  <Toaster />
                </DarkModeProvider>
              </FeatureFlagsProvider>
            </CurrentTeamProvider>
          </CurrentEnvironmentProvider>
        </AuthProvider>
      </Elements>
    </BugsnagErrorBoundary>
  );
};

export default MyApp;
