import adapter from '@flopflip/launchdarkly-adapter';
import {
  ConfigureFlopFlip,
  useAdapterReconfiguration,
} from '@flopflip/react-broadcast';
import { AuthedUserFragment } from 'graphql/generated';
import React, { useEffect, useMemo } from 'react';
import { useAuth } from 'utils/auth-context';

const toLDUser = (user: AuthedUserFragment, key: string) => ({
  key,
  name: user.name,
  email: user.email,
});

export interface FeatureFlagsContextProps {}

export const FeatureFlagsProvider: React.FC<
  Readonly<FeatureFlagsContextProps>
> = ({ children }) => {
  const { user: authUser } = useAuth();
  const reconfigure = useAdapterReconfiguration();

  const launchDarklyUser = useMemo(
    () => (authUser ? toLDUser(authUser, authUser.id) : { key: '' }),
    [authUser],
  );

  useEffect(() => {
    if (authUser) {
      reconfigure({ user: launchDarklyUser }, { shouldOverwrite: false });
    }
  }, [authUser, reconfigure, launchDarklyUser]);

  const adapterArgs = useMemo(
    () => ({
      sdk: {
        clientSideId: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID,
      },
      user: launchDarklyUser,
    }),
    [launchDarklyUser],
  );

  return (
    <ConfigureFlopFlip
      key={`launch-darkly-config-${authUser?.id || 'no-user'}`}
      adapter={adapter}
      adapterArgs={adapterArgs}
    >
      {children}
    </ConfigureFlopFlip>
  );
};
