import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const usePageView = (): void => {
  const { pathname } = useRouter();

  useEffect(() => {
    analytics.page(pathname);
  }, [pathname]);
};
