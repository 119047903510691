import classnames from 'classnames';
import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface CardFooterProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const CardFooter: FC<Readonly<CardFooterProps>> = ({
  className,
  children,
  ...props
}) => (
  <div
    {...props}
    className={classnames(
      'border-t p-4',
      'border-gray-lightmode-200',
      'dark:border-gray-darkmode-200',
      className,
    )}
  >
    {children}
  </div>
);
