import { Loader } from 'components/loader';
import { Page } from 'components/page';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useCurrentTeam } from 'utils/current-team-context';
import {
  OnboardingStep,
  parseOnboardingStep,
} from 'utils/onboarding/onboarding-step';
import { unreachable } from 'utils/unreachable';

export interface OnboardingGuardProps {}

export const OnboardingGuard: React.FC<Readonly<OnboardingGuardProps>> = ({
  children,
}) => {
  const router = useRouter();

  const { currentTeam } = useCurrentTeam();

  if (!currentTeam) {
    return (
      <div className="w-full flex items-center justify-center mt-40">
        <Loader size="md" />
      </div>
    );
  }

  switch (currentTeam.onboarding.__typename) {
    case 'TeamOnboarded': {
      if (router.asPath.startsWith('/auth/onboarding')) {
        void router.push('/');

        return null;
      }

      return <Page>{children}</Page>;
    }
    case 'TeamOnboarding': {
      const nextStep =
        parseOnboardingStep(currentTeam.onboarding.nextStep) ??
        // If we can't parse the step, we'll assume that the team needs to be
        // onboarded and just send them to the first step in the process.
        OnboardingStep.Initial;

      const onboardingPath = (() => {
        switch (nextStep) {
          case OnboardingStep.Initial:
            return '/auth/onboarding';
          case OnboardingStep.InviteTeam:
            return '/auth/onboarding/invite-team';
          default:
            return unreachable(nextStep);
        }
      })();

      if (router.pathname !== onboardingPath) {
        void router.push(onboardingPath);

        return null;
      }

      return <>{children}</>;
    }
    default:
      return unreachable(currentTeam.onboarding);
  }
};
