import { GraphQLClient } from 'graphql-request';
import { NextPageContext } from 'next';
import { getCookies } from 'utils/cookies';
import { getSdkWithHooks } from '../graphql/generated';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sdk = (context?: NextPageContext) => {
  const { csrfToken } = getCookies(context);
  const graphQLClient = new GraphQLClient(
    `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    {
      headers: {
        'x-csrf-token': csrfToken || '',
      },
      credentials: 'include',
    },
  );

  return getSdkWithHooks(graphQLClient);
};
