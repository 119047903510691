import { Listbox, Transition } from '@headlessui/react';
import { Label } from '@workos-inc/component-library';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Check, ChevronDown, ChevronUp } from 'react-feather';

export interface SelectProps<T> {
  className?: string;
  id: string;
  inline?: boolean;
  disabled?: boolean;
  placeholder: string;
  options: OptionType<T>[];
  name: string;
  onChange: (event: {
    name: string;
    value: T;
    label: string | JSX.Element;
  }) => void;
  value?: T;
  label?: string;
  size?: 'medium' | 'small';
}

export interface OptionType<T> {
  label: string | JSX.Element;
  value: T;
}

export const Select = <T extends string>({
  className,
  id,
  inline = false,
  disabled = false,
  placeholder,
  options,
  name,
  onChange,
  value,
  label,
  size = 'medium',
}: Readonly<SelectProps<T>>): JSX.Element => {
  const defaultValue = options.find(
    (option: OptionType<T>) => option.value === value,
  );
  const [selectedOption, selectOption] = useState<OptionType<T> | null>(
    defaultValue || null,
  );

  const onSelectOption = (event: OptionType<T>) => {
    selectOption(event);
    onChange({ name, ...event });
  };

  return (
    <div
      className={classnames({ 'flex items-center': inline }, className)}
      id={id}
    >
      {label && (
        <Label
          className={classnames({ 'mb-2': !inline, 'w-44': inline })}
          htmlFor={id}
        >
          {label}
        </Label>
      )}
      <Listbox
        as="div"
        className="relative w-full"
        disabled={disabled}
        onChange={onSelectOption}
        value={selectedOption}
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className={classnames(
                'border w-full transition duration-200 ease-in-out text-left relative rounded',
                'bg-white border-gray-lightmode-200',
                'dark:bg-gray-darkmode-50 dark:border-gray-darkmode-200',
                'focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary',
                {
                  'px-2 py-1.5 leading-6 text-sm': size === 'medium',
                  'px-2 py-1 text-sm': size === 'small',
                  'text-gray-lightmode-400 dark:text-gray-darkmode-400':
                    selectedOption?.value,
                },
                {
                  'text-gray-lightmode-300 dark:text-gray-darkmode-300': !selectedOption?.value,
                },
              )}
            >
              <div className="flex items-center justify-between">
                {selectedOption && selectedOption.label
                  ? selectedOption.label
                  : placeholder}
                {open ? (
                  <ChevronUp
                    className={classnames({ 'ml-3': size === 'small' })}
                    size={16}
                  />
                ) : (
                  <ChevronDown
                    className={classnames({ 'ml-3': size === 'small' })}
                    size={16}
                  />
                )}
              </div>
            </Listbox.Button>

            <div className="relative z-10">
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                show={open}
              >
                <Listbox.Options
                  className={classnames(
                    'absolute w-full py-1 mt-2 border overflow-auto text-sm rounded shadow max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none',
                    'bg-white border-gray-lightmode-200',
                    'dark:bg-gray-darkmode-50 dark:border-gray-darkmode-200',
                  )}
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className="focus:outline-none cursor-pointer"
                      value={option}
                    >
                      {({ active, selected }) => {
                        const isSelected = selected || option.value === value;

                        return (
                          <span
                            className={classnames(
                              'p-2 block select-none cursor-pointer',
                              'text-gray-lightmode-300 hover:text-gray-lightmode-400 hover:bg-gray-lightmode-100',
                              'dark:text-gray-darkmode-300 dark:hover-text-gray-darkmode-400 dark:hover:bg-gray-darkmode-200',
                              {
                                '!text-primary-darken dark:!text-gray-darkmode-400':
                                  active || isSelected,
                              },
                            )}
                          >
                            <div className="flex items-center justify-between">
                              {option.label}
                              {isSelected && <Check size={16} />}
                            </div>
                          </span>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};
