import { Button, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { IntegrationGraphic } from 'components/integration-graphic';
import { withPage } from 'components/with-page';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

const cards = [
  {
    title: 'Add the Admin Portal to your App',
    url: '/admin-portal/guide',
    image: '/static/admin-portal.svg',
    description:
      'A single place for IT admins to configure enterprise features in your app.',
  },
  {
    title: 'Support Directory Sync',
    url: '/directory-sync/guide/introduction',
    image: '/static/directory-sync.svg',
    description:
      'Integrate with any customer’s directory to get real-time organization updates.',
  },
  {
    title: 'Read the API Reference',
    url: '/reference',
    image: '/static/api-reference.svg',
    description:
      'Dig into the details and learn how your app can integrate with WorkOS.',
  },
];

export const GetStarted: FC<Readonly<unknown>> = () => {
  const router = useRouter();

  return (
    <>
      <header className="mb-12">
        <Text as="h1" className="mb-1" size="xlarge" weight="medium">
          Get Started with Single Sign-On
        </Text>
        <Text as="h2" size="large">
          Let's make your app enterprise ready.
        </Text>
      </header>

      <section className="bg-gray-lightmode-100 p-8 rounded-lg shadow-lg mb-14 relative overflow-hidden dark:bg-gray-darkmode-100">
        <div className="max-w-lg">
          <Text as="h3" className="mb-2" size="large" weight="medium">
            One hour integration
          </Text>
          <Text multiline>
            Our holistic integration guide covers everything you need to add
            Single Sign-On to your application. Get started to add support for a
            dozen identity providers in just under an hour.
          </Text>

          <Button
            appearance="primary"
            onClick={() =>
              router.push(
                `${process.env.NEXT_PUBLIC_DOCS_URL}/sso/guide/introduction`,
              )
            }
            size="large"
          >
            Get Started
          </Button>
        </div>

        <IntegrationGraphic className="absolute max-w-sm right-16 top-0 -mt-4 hidden xl:block" />
      </section>

      <section>
        <Text as="h3" className="mb-6" size="large">
          Or, explore other documentation.
        </Text>

        <div className="grid grid-cols-1 gap-14 md:grid-cols-2 lg:grid-cols-3">
          {cards.map((card) => (
            <a
              key={card.title}
              className="cursor-pointer transform hover:-translate-y-1 transition duration-200 ease-in-out"
              href={`${process.env.NEXT_PUBLIC_DOCS_URL}${card.url}`}
              rel="noreferrer"
              target="_blank"
            >
              <Card>
                <Card.Header className="flex justify-center h-40 bg-gradient-radial from-gradient-light to-gradient-lighten dark:from-gradient-dark dark:to-gradient-darken">
                  <img alt={card.title} src={card.image} />
                </Card.Header>

                <Card.Body>
                  <Text as="h4" className="mb-1">
                    {card.title}
                  </Text>
                  <Text multiline as="p">
                    {card.description}
                  </Text>
                </Card.Body>
              </Card>
            </a>
          ))}
        </div>
      </section>
    </>
  );
};

export default withPage(GetStarted, {
  metaTitle: 'Get Started',
  metaCanonical: 'get-started',
});
