import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { ClientError } from 'graphql-request/dist/types';
import useSWR, {
  SWRConfiguration as SWRConfigInterface,
  Key as SWRKeyInterface,
} from 'swr';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AddAdpConnectionSslCertificateInput = {
  connectionId: Scalars['String'];
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type AddAdpConnectionSslCertificateResult =
  | AdpConnectionSslCertificateAdded
  | ConnectionNotFound
  | InvalidAdpConnectionSslCertificate;

export type AddPaymentMethodPayload = {
  __typename?: 'AddPaymentMethodPayload';
  team: Team;
};

export type AddUsersToTeamInput = {
  users: Array<UserInviteInput>;
};

export type AddUsersToTeamPayload = {
  __typename?: 'AddUsersToTeamPayload';
  /** The list of memberships of the users that were just invited to the team. */
  invitedMembers?: Maybe<Array<UsersTeam>>;
};

export type AdpConnectionSslCertificate = {
  __typename?: 'AdpConnectionSslCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type AdpConnectionSslCertificateAdded = {
  __typename?: 'AdpConnectionSslCertificateAdded';
  adpConnectionSslCertificate: AdpConnectionSslCertificate;
};

export type AdpConnectionSslCertificateDeleted = {
  __typename?: 'AdpConnectionSslCertificateDeleted';
  certificateId: Scalars['String'];
};

export type AdpConnectionSslCertificateNotFound = {
  __typename?: 'AdpConnectionSslCertificateNotFound';
  certificateId: Scalars['String'];
};

export type AttributeMap = {
  __typename?: 'AttributeMap';
  attributes: Attributes;
  id: Scalars['ID'];
};

export type Attributes = {
  __typename?: 'Attributes';
  email: Scalars['String'];
  firstName: Scalars['String'];
  idpId: Scalars['String'];
  lastName: Scalars['String'];
};

export type BambooHrDirectory = {
  __typename?: 'BambooHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<Scalars['String']>>;
  subdomain?: Maybe<Scalars['String']>;
};

export type BreatheHrDirectory = {
  __typename?: 'BreatheHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type Connection = {
  __typename?: 'Connection';
  adpConnectionSslCertificates: Array<AdpConnectionSslCertificate>;
  /** @deprecated Please use `attributeMap` instead. */
  attribute_map?: Maybe<AttributeMap>;
  attributeMap?: Maybe<AttributeMap>;
  connectionDomains: Array<ConnectionDomain>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  oauthCredential?: Maybe<OAuthCredential>;
  oauthSessions: OAuthSessionsList;
  oidc_client_id?: Maybe<Scalars['String']>;
  oidc_client_secret?: Maybe<Scalars['String']>;
  oidc_discovery_endpoint?: Maybe<Scalars['String']>;
  oidc_redirect_uri?: Maybe<Scalars['String']>;
  oidcSessions: OidcSessionsList;
  organization?: Maybe<Organization>;
  organizationDomains: Array<OrganizationDomain>;
  saml_acs_url: Scalars['String'];
  saml_entity_id?: Maybe<Scalars['String']>;
  saml_idp_metadata_url?: Maybe<Scalars['String']>;
  saml_idp_url?: Maybe<Scalars['String']>;
  saml_relying_party_private_key?: Maybe<Scalars['String']>;
  saml_relying_party_public_key?: Maybe<Scalars['String']>;
  saml_sp_metadata_url: Scalars['String'];
  /** @deprecated Please use `samlX509Certificates` instead. */
  saml_x509_certs?: Maybe<Array<Scalars['String']>>;
  samlSessions: SamlSessionsList;
  samlX509Certificates: Array<SamlX509Certificate>;
  state: ConnectionState;
  /** @deprecated Please use `state` instead. */
  status: ConnectionStatus;
  type: ConnectionType;
  updatedAt: Scalars['DateTime'];
};

export type ConnectionOauthSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OAuthSessionState>;
};

export type ConnectionOidcSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OidcSessionState>;
};

export type ConnectionSamlSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SamlSessionState>;
};

export type ConnectionCreated = {
  __typename?: 'ConnectionCreated';
  connection: Connection;
};

export type ConnectionDomain = {
  __typename?: 'ConnectionDomain';
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type ConnectionError = {
  __typename?: 'ConnectionError';
  error: Scalars['String'];
  field: Scalars['String'];
  solution: Scalars['String'];
};

export type ConnectionList = {
  __typename?: 'ConnectionList';
  data: Array<Connection>;
  listMetadata: ListMetadata;
};

export type ConnectionNotFound = {
  __typename?: 'ConnectionNotFound';
  connectionId: Scalars['String'];
};

export type ConnectionSession = OAuthSession | OidcSession | SamlSession;

export type ConnectionSessionError =
  | DecryptionFailedError
  | InvalidAttributesError
  | InvalidX509CertError
  | MalformedSamlResponseError
  | ProfileNotAllowedOutsideOrganizationError;

/** Enum represents the error code of a Connection Session Error. */
export enum ConnectionSessionErrorCode {
  DecryptionFailed = 'DecryptionFailed',
  InvalidAttributes = 'InvalidAttributes',
  InvalidX509Cert = 'InvalidX509Cert',
  MalformedSamlResponse = 'MalformedSamlResponse',
  ProfileNotAllowedOutsideOrganization = 'ProfileNotAllowedOutsideOrganization',
}

/** Enum represents the state of the connection. */
export enum ConnectionState {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
}

/** Enum represents the status of the connection. */
export enum ConnectionStatus {
  Linked = 'Linked',
  Unlinked = 'Unlinked',
}

/** Enum represents the type of connection. */
export enum ConnectionType {
  Adfssaml = 'ADFSSAML',
  AdpOidc = 'AdpOidc',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  MagicLink = 'MagicLink',
  MicrosoftOAuth = 'MicrosoftOAuth',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  RipplingSaml = 'RipplingSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  VMwareSaml = 'VMwareSAML',
}

export type ConnectionUpdated = {
  __typename?: 'ConnectionUpdated';
  connection: Connection;
};

export type ConnectionUpdatedFromMetadataUrl = {
  __typename?: 'ConnectionUpdatedFromMetadataUrl';
  connection: Connection;
};

export type ConnectionUpdatedFromMetadataXml = {
  __typename?: 'ConnectionUpdatedFromMetadataXml';
  connection: Connection;
};

export type CreateBillingPortalSessionPayload = {
  __typename?: 'CreateBillingPortalSessionPayload';
  billingPortalSessionUrl: Scalars['String'];
};

export type CreateConnectionInput = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
  type: ConnectionType;
};

export type CreateConnectionResult =
  | ConnectionCreated
  | OauthConnectionMissingCredentials
  | OrganizationNotFound;

export type CreateDirectoryCustomAttributeInput = {
  environmentId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateDirectoryCustomAttributeResult = DirectoryCustomAttributeCreated;

export type CreateKeyInput = {
  environmentId: Scalars['String'];
  expireAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CreateKeyResult = KeyCreated;

export type CreateOauthCredentialsInput = {
  environmentId: Scalars['String'];
  provider: ConnectionType;
};

export type CreateOauthCredentialsPayload = {
  __typename?: 'CreateOauthCredentialsPayload';
  oauthCredentials?: Maybe<OAuthCredential>;
};

export type DecryptionFailedError = {
  __typename?: 'DecryptionFailedError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type DeleteAdpConnectionSslCertificateInput = {
  certificateId: Scalars['String'];
};

export type DeleteAdpConnectionSslCertificateResult =
  | AdpConnectionSslCertificateDeleted
  | AdpConnectionSslCertificateNotFound;

export type DeleteDirectoryCustomAttributeInput = {
  directoryCustomAttributeId: Scalars['ID'];
};

export type DeleteDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeDeleted
  | DirectoryCustomAttributeNotFound;

export type DeleteDirectoryInput = {
  directoryId: Scalars['String'];
};

export type DeleteDirectoryPayload = {
  __typename?: 'DeleteDirectoryPayload';
  directory?: Maybe<Directory>;
};

export type DeleteOrganizationInput = {
  organizationId: Scalars['String'];
};

export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type DeleteSamlX509CertificateInput = {
  samlX509CertificateId: Scalars['String'];
};

export type DeleteSamlX509CertificateResult =
  | SamlX509CertificateDeleted
  | SamlX509CertificateNotFound;

export type Directory = {
  __typename?: 'Directory';
  attributeMap?: Maybe<DirectoryAttributeMap>;
  bamboo_hr_api_key?: Maybe<Scalars['String']>;
  bamboo_hr_custom_fields?: Maybe<Array<Scalars['String']>>;
  bamboo_hr_subdomain?: Maybe<Scalars['String']>;
  /** @deprecated Please use `bearerToken` instead. */
  bearer_token?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  breatheHrApiKey?: Maybe<Scalars['String']>;
  client_channel_token?: Maybe<Scalars['String']>;
  client_refresh_token?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Please use `attributeMap` instead. */
  directoryAttributeMap?: Maybe<DirectoryAttributeMap>;
  directoryGroups: DirectoryGroupsList;
  directorySyncRun: DirectorySyncRun;
  directorySyncRuns: DirectorySyncRunsList;
  directoryUser: DirectoryUser;
  directoryUsers: DirectoryUsersList;
  endpoint?: Maybe<Scalars['String']>;
  fourthHrOrganizationId?: Maybe<Scalars['String']>;
  fourthHrPassword?: Maybe<Scalars['String']>;
  fourthHrUsername?: Maybe<Scalars['String']>;
  gusto_company_id?: Maybe<Scalars['String']>;
  gusto_refresh_token?: Maybe<Scalars['String']>;
  hibobApiToken?: Maybe<Scalars['String']>;
  hibobServiceUserId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  primaryDomain?: Maybe<OrganizationDomain>;
  provider?: Maybe<DirectoryProvider>;
  rippling_api_key?: Maybe<Scalars['String']>;
  setup_url?: Maybe<Scalars['String']>;
  state: DirectoryState;
  summary: DirectorySummary;
  type: DirectoryType;
  updatedAt: Scalars['DateTime'];
  workday_raas_endpoint?: Maybe<Scalars['String']>;
  workday_raas_group_endpoint?: Maybe<Scalars['String']>;
  workday_raas_password?: Maybe<Scalars['String']>;
  workday_raas_username?: Maybe<Scalars['String']>;
};

export type DirectoryDirectoryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type DirectoryDirectorySyncRunArgs = {
  id: Scalars['String'];
};

export type DirectoryDirectorySyncRunsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
};

export type DirectoryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type DirectoryDirectoryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type DirectoryAttributeMap = {
  __typename?: 'DirectoryAttributeMap';
  attributes: DirectoryAttributes;
  /** @deprecated Please use `attributes.emails` instead. */
  emails_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.externalId` instead. */
  external_id_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.firstName` instead. */
  first_name_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.groupName` instead. */
  group_name_attribute?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Please use `attributes.lastName` instead. */
  last_name_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.username` instead. */
  username_attribute?: Maybe<Scalars['String']>;
};

export type DirectoryAttributes = {
  __typename?: 'DirectoryAttributes';
  emails?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type DirectoryCustomAttribute = {
  __typename?: 'DirectoryCustomAttribute';
  createdAt: Scalars['DateTime'];
  environment: Environment;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DirectoryCustomAttributeCreated = {
  __typename?: 'DirectoryCustomAttributeCreated';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryCustomAttributeDeleted = {
  __typename?: 'DirectoryCustomAttributeDeleted';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryCustomAttributeList = {
  __typename?: 'DirectoryCustomAttributeList';
  data: Array<DirectoryCustomAttribute>;
};

export type DirectoryCustomAttributeMapping = {
  __typename?: 'DirectoryCustomAttributeMapping';
  attribute: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customAttribute: DirectoryCustomAttribute;
  directory: Directory;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DirectoryCustomAttributeMappingInput = {
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
};

export type DirectoryCustomAttributeMappingList = {
  __typename?: 'DirectoryCustomAttributeMappingList';
  data: Array<DirectoryCustomAttributeMapping>;
};

export type DirectoryCustomAttributeMappingsSet = {
  __typename?: 'DirectoryCustomAttributeMappingsSet';
  mappings: Array<DirectoryCustomAttributeMapping>;
};

export type DirectoryCustomAttributeNotFound = {
  __typename?: 'DirectoryCustomAttributeNotFound';
  directoryCustomAttributeId: Scalars['String'];
};

export type DirectoryCustomAttributeUpdated = {
  __typename?: 'DirectoryCustomAttributeUpdated';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryGroup = {
  __typename?: 'DirectoryGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  summary: DirectoryGroupSummary;
};

export type DirectoryGroupsList = {
  __typename?: 'DirectoryGroupsList';
  data: Array<DirectoryGroup>;
  listMetadata: ListMetadata;
};

export type DirectoryGroupSummary = {
  __typename?: 'DirectoryGroupSummary';
  memberCount: Scalars['Int'];
};

export type DirectoryList = {
  __typename?: 'DirectoryList';
  data: Array<Directory>;
  listMetadata: ListMetadata;
};

export type DirectoryNotFound = {
  __typename?: 'DirectoryNotFound';
  directoryId: Scalars['String'];
};

export type DirectoryProvider =
  | BambooHrDirectory
  | BreatheHrDirectory
  | FourthHrDirectory
  | GustoDirectory
  | HibobDirectory
  | RipplingDirectory
  | WorkdayDirectory;

/** An error pertaining to the directory provider. */
export type DirectoryProviderError = {
  __typename?: 'DirectoryProviderError';
  message: Scalars['String'];
};

export type DirectoryProviderErrors = {
  __typename?: 'DirectoryProviderErrors';
  errors: Array<DirectoryProviderError>;
};

/** Enum represents the state of the Directory. */
export enum DirectoryState {
  Deleting = 'Deleting',
  InvalidCredentials = 'InvalidCredentials',
  Linked = 'Linked',
  Unlinked = 'Unlinked',
  Validating = 'Validating',
}

export type DirectorySummary = {
  __typename?: 'DirectorySummary';
  groupCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type DirectorySyncRun = {
  __typename?: 'DirectorySyncRun';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  directoryId: Scalars['String'];
  errors: Array<DirectorySyncRunError>;
  groupCounts: DirectorySyncRunResourceCounts;
  id: Scalars['String'];
  state: DirectorySyncRunState;
  updatedAt: Scalars['DateTime'];
  userCounts: DirectorySyncRunResourceCounts;
};

export type DirectorySyncRunError =
  | MalformedDirectoryGroupError
  | MalformedDirectoryUserError;

/** Enum represents the error code of a Directory Sync Run. */
export enum DirectorySyncRunErrorCode {
  MalformedDirectoryGroup = 'MalformedDirectoryGroup',
  MalformedDirectoryUser = 'MalformedDirectoryUser',
}

export type DirectorySyncRunResourceCounts = {
  __typename?: 'DirectorySyncRunResourceCounts';
  created: Scalars['Float'];
  deleted: Scalars['Float'];
  processed: Scalars['Float'];
  updated: Scalars['Float'];
};

export type DirectorySyncRunsList = {
  __typename?: 'DirectorySyncRunsList';
  data: Array<DirectorySyncRun>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the directory sync run. */
export enum DirectorySyncRunState {
  Completed = 'Completed',
  Error = 'Error',
  New = 'New',
  Running = 'Running',
}

/** Enum represents the type of Directory. */
export enum DirectoryType {
  AzureScimv2_0 = 'AzureSCIMV2_0',
  BambooHr = 'BambooHR',
  BreatheHr = 'BreatheHr',
  FourthHr = 'FourthHr',
  GenericScimv1_1 = 'GenericSCIMV1_1',
  GenericScimv2_0 = 'GenericSCIMV2_0',
  GSuiteDirectory = 'GSuiteDirectory',
  Gusto = 'Gusto',
  Hibob = 'Hibob',
  JumpCloudScimV2_0 = 'JumpCloudScimV2_0',
  OktaScimv1_1 = 'OktaSCIMV1_1',
  OktaScimv2_0 = 'OktaSCIMV2_0',
  Rippling = 'Rippling',
  S3 = 'S3',
  Workday = 'Workday',
}

export type DirectoryUpdated = {
  __typename?: 'DirectoryUpdated';
  directory: Directory;
};

export type DirectoryUser = {
  __typename?: 'DirectoryUser';
  directoryGroups: Array<DirectoryGroup>;
  emails?: Maybe<DirectoryUserEmail>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idpId: Scalars['String'];
  /** The JSON representation of the Directory User, as returned by the REST API. */
  json: Scalars['JSON'];
  lastName?: Maybe<Scalars['String']>;
  state: DirectoryUserState;
  username?: Maybe<Scalars['String']>;
};

export type DirectoryUserEmail = {
  __typename?: 'DirectoryUserEmail';
  primary: Scalars['Boolean'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type DirectoryUsersList = {
  __typename?: 'DirectoryUsersList';
  data: Array<DirectoryUser>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the directory user. */
export enum DirectoryUserState {
  Active = 'Active',
  Inactive = 'Inactive',
  Suspended = 'Suspended',
}

/** Represents the email categories that can be unsubscribed from. */
export enum EmailNotificationCategory {
  Connection = 'Connection',
  Error = 'Error',
}

export type EmailNotificationPreference = {
  __typename?: 'EmailNotificationPreference';
  category: EmailNotificationCategory;
  preference: EmailNotificationPreferenceConfiguration;
};

/** Represents the possible configurations for email notification preferences. */
export enum EmailNotificationPreferenceConfiguration {
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed',
}

export type EmailNotificationPreferences = {
  __typename?: 'EmailNotificationPreferences';
  preferences: Array<EmailNotificationPreference>;
};

export type EnterTeamDetailsPayload = {
  __typename?: 'EnterTeamDetailsPayload';
  team?: Maybe<Team>;
  user?: Maybe<User>;
};

export type Environment = {
  __typename?: 'Environment';
  allowProfilesOutsideOrganization?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use `clientId` instead. */
  client_id: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated Please use `logoUrl` instead. */
  logo_url?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  portalSettings: PortalSettings;
  projectV2: ProjectV2;
  sandbox: Scalars['Boolean'];
  /** @deprecated Please use `ssoDemoApplication` instead. */
  sso_demo_application?: Maybe<SsoDemoApplication>;
  ssoDemoApplication?: Maybe<SsoDemoApplication>;
};

export type EnvironmentNotFound = {
  __typename?: 'EnvironmentNotFound';
  environmentId: Scalars['String'];
};

export type EnvironmentUpdated = {
  __typename?: 'EnvironmentUpdated';
  environment: Environment;
};

export type Event = {
  __typename?: 'Event';
  action: EventAction;
  actor: EventActor;
  group: Scalars['String'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  location: Scalars['String'];
  longitude?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['JSON']>;
  occurredAt: Scalars['DateTime'];
  target: EventTarget;
  type?: Maybe<Scalars['String']>;
};

export type EventAction = {
  __typename?: 'EventAction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EventActor = {
  __typename?: 'EventActor';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EventList = {
  __typename?: 'EventList';
  data: Array<Event>;
  listMetadata: ListMetadata;
};

export type EventTarget = {
  __typename?: 'EventTarget';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ExpectedAttributes = {
  __typename?: 'ExpectedAttributes';
  email_attribute: Scalars['String'];
  first_name_attribute: Scalars['String'];
  idp_id_attribute: Scalars['String'];
  last_name_attribute: Scalars['String'];
};

export type ExpireKeyInput = {
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  keyId: Scalars['String'];
};

export type ExpireKeyResult = KeyAlreadyExpired | KeyExpired | KeyNotFound;

export type FourthHrDirectory = {
  __typename?: 'FourthHrDirectory';
  organizationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type GustoDirectory = {
  __typename?: 'GustoDirectory';
  companyId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type HibobDirectory = {
  __typename?: 'HibobDirectory';
  apiToken?: Maybe<Scalars['String']>;
  serviceUserId?: Maybe<Scalars['String']>;
};

export type InvalidAdpConnectionSslCertificate = {
  __typename?: 'InvalidAdpConnectionSslCertificate';
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type InvalidAttributesError = {
  __typename?: 'InvalidAttributesError';
  code: ConnectionSessionErrorCode;
  expected_attributes: ExpectedAttributes;
  reason: Scalars['String'];
  received_attributes: Array<ReceivedAttribute>;
};

export type InvalidRedirectUriProcotol = {
  __typename?: 'InvalidRedirectUriProcotol';
  message: Scalars['String'];
  uri: Scalars['String'];
};

export type InvalidSamlX509Certificate = {
  __typename?: 'InvalidSamlX509Certificate';
  validationError: SamlX509CertificateValidationErrorCode;
};

export type InvalidX509CertError = {
  __typename?: 'InvalidX509CertError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type Key = {
  __typename?: 'Key';
  createdAt: Scalars['DateTime'];
  displayValue: Scalars['String'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: KeyType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type KeyAlreadyExpired = {
  __typename?: 'KeyAlreadyExpired';
  key: Key;
};

export type KeyCreated = {
  __typename?: 'KeyCreated';
  key: Key;
};

export type KeyExpired = {
  __typename?: 'KeyExpired';
  expiredKey: Key;
  newKey?: Maybe<Key>;
};

export type KeyList = {
  __typename?: 'KeyList';
  data: Array<Key>;
  listMetadata: ListMetadata;
};

export type KeyNotFound = {
  __typename?: 'KeyNotFound';
  keyId: Scalars['String'];
};

/** Enum represents a scope of API keys that can be returned. */
export enum KeyScope {
  Active = 'Active',
  All = 'All',
  RecentlyExpired = 'RecentlyExpired',
}

/** Enum representing a type of key. */
export enum KeyType {
  Secret = 'SECRET',
}

export type ListMetadata = {
  __typename?: 'ListMetadata';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type MalformedDirectoryGroupError = {
  __typename?: 'MalformedDirectoryGroupError';
  code: DirectorySyncRunErrorCode;
  reason: Scalars['String'];
};

export type MalformedDirectoryUserError = {
  __typename?: 'MalformedDirectoryUserError';
  code: DirectorySyncRunErrorCode;
  reason: Scalars['String'];
};

export type MalformedSamlResponseError = {
  __typename?: 'MalformedSamlResponseError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type MetadataFetchFailed = {
  __typename?: 'MetadataFetchFailed';
  reason: Scalars['String'];
};

export type MetadataParseFailed = {
  __typename?: 'MetadataParseFailed';
  reason: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAdpConnectionSslCertificate: AddAdpConnectionSslCertificateResult;
  addPaymentMethod: AddPaymentMethodPayload;
  addUsersToTeam: AddUsersToTeamPayload;
  addUserToTeam: UsersTeam;
  changeRole: UsersTeam;
  createBillingPortalSession: CreateBillingPortalSessionPayload;
  createConnection: CreateConnectionResult;
  createDirectory: Directory;
  createDirectoryCustomAttribute: CreateDirectoryCustomAttributeResult;
  createEnvironment: Environment;
  createKey: CreateKeyResult;
  createOauthCredentials: CreateOauthCredentialsPayload;
  createOrganization: Organization;
  createProject: ProjectV2;
  createRedirectUri: RedirectUri;
  createWebhookEndpoint: WebhookEndpoint;
  deleteAdpConnectionSslCertificate: DeleteAdpConnectionSslCertificateResult;
  deleteConnection: Scalars['String'];
  deleteDirectory: DeleteDirectoryPayload;
  deleteDirectoryCustomAttribute: DeleteDirectoryCustomAttributeResult;
  deleteOrganization: DeleteOrganizationPayload;
  deleteRedirectUri: Scalars['String'];
  deleteSamlX509Certificate: DeleteSamlX509CertificateResult;
  deleteWebhookEndpoint: Scalars['String'];
  expireKey: ExpireKeyResult;
  onboarding_completeStep: OnboardingCompleteStepPayload;
  onboarding_enterTeamDetails: EnterTeamDetailsPayload;
  portal_addAdpConnectionSslCertificate: Portal_AddAdpConnectionSslCertificateResult;
  portal_createConnection: Portal_Connection;
  portal_createDirectory: Portal_Directory;
  portal_deleteDirectory: Portal_DeleteDirectoryPayload;
  portal_deleteDraftConnection: Scalars['String'];
  portal_loaded: SuccessResponse;
  portal_ready: SuccessResponse;
  portal_setDirectoryCustomAttributeMappings: Portal_SetDirectoryCustomAttributeMappingsResult;
  portal_trackEvent: SuccessResponse;
  portal_updateAttributeMap?: Maybe<AttributeMap>;
  portal_updateConnection: Portal_UpdateConnectionResult;
  portal_updateDirectory: Portal_Directory;
  portal_updateDirectoryAttributeMap?: Maybe<Portal_UpdateDirectoryAttributeMapPayload>;
  portalIdentityProviderRequest: Scalars['Boolean'];
  provisionTestAppAndUser: Team;
  removeUserFromTeam: Scalars['String'];
  resetKey: Key;
  setDirectoryCustomAttributeMappings: SetDirectoryCustomAttributeMappingsResult;
  setDomainsForOrganization: SetDomainsForOrganizationPayload;
  setEmailNotificationPreferences: SetEmailNotificationPreferencesResult;
  setRedirectUris: SetRedirectUrisResult;
  testWebhookEndpoint: Webhook;
  updateAttributeMap?: Maybe<AttributeMap>;
  updateConnection: UpdateConnectionResult;
  updateConnectionFromMetadataUrl: UpdateConnectionFromMetadataUrlResult;
  updateConnectionFromMetadataXml: UpdateConnectionFromMetadataXmlResult;
  updateDirectory: UpdateDirectoryResult;
  updateDirectoryAttributeMap?: Maybe<DirectoryAttributeMap>;
  updateDirectoryCustomAttribute: UpdateDirectoryCustomAttributeResult;
  updateEnvironment: UpdateEnvironmentResult;
  updateOAuthCredential?: Maybe<OAuthCredential>;
  updateOrganization: UpdateOrganizationPayload;
  updatePortalSettings: PortalSettings;
  updateProjectV2: ProjectV2;
  updateRedirectUri: RedirectUri;
  updateSession?: Maybe<Session>;
  updateTeam: Team;
  updateWebhookEndpoint: WebhookEndpoint;
  upsertSamlX509Certificate: UpsertSamlX509CertificateResult;
};

export type MutationAddAdpConnectionSslCertificateArgs = {
  input: AddAdpConnectionSslCertificateInput;
};

export type MutationAddPaymentMethodArgs = {
  billingEmail: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
};

export type MutationAddUsersToTeamArgs = {
  input: AddUsersToTeamInput;
};

export type MutationAddUserToTeamArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: UsersOrganizationsRole;
};

export type MutationChangeRoleArgs = {
  role: UsersOrganizationsRole;
  usersOrganizationsId: Scalars['String'];
};

export type MutationCreateBillingPortalSessionArgs = {
  returnUrl: Scalars['String'];
};

export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};

export type MutationCreateDirectoryArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization_id: Scalars['String'];
  type: DirectoryType;
};

export type MutationCreateDirectoryCustomAttributeArgs = {
  input: CreateDirectoryCustomAttributeInput;
};

export type MutationCreateEnvironmentArgs = {
  name: Scalars['String'];
  project_id: Scalars['String'];
  sandbox: Scalars['Boolean'];
};

export type MutationCreateKeyArgs = {
  input: CreateKeyInput;
};

export type MutationCreateOauthCredentialsArgs = {
  input: CreateOauthCredentialsInput;
};

export type MutationCreateOrganizationArgs = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  environmentId: Scalars['String'];
  name: Scalars['String'];
};

export type MutationCreateProjectArgs = {
  name: Scalars['String'];
};

export type MutationCreateRedirectUriArgs = {
  environmentId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type MutationCreateWebhookEndpointArgs = {
  endpointUrl: Scalars['String'];
  environmentId: Scalars['String'];
  events: Array<Scalars['String']>;
};

export type MutationDeleteAdpConnectionSslCertificateArgs = {
  input: DeleteAdpConnectionSslCertificateInput;
};

export type MutationDeleteConnectionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteDirectoryArgs = {
  input: DeleteDirectoryInput;
};

export type MutationDeleteDirectoryCustomAttributeArgs = {
  input: DeleteDirectoryCustomAttributeInput;
};

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

export type MutationDeleteRedirectUriArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSamlX509CertificateArgs = {
  input: DeleteSamlX509CertificateInput;
};

export type MutationDeleteWebhookEndpointArgs = {
  id: Scalars['String'];
};

export type MutationExpireKeyArgs = {
  input: ExpireKeyInput;
};

export type MutationOnboarding_CompleteStepArgs = {
  input: OnboardingCompleteStepInput;
};

export type MutationOnboarding_EnterTeamDetailsArgs = {
  input: OnboardingEnterTeamDetailsInput;
};

export type MutationPortal_AddAdpConnectionSslCertificateArgs = {
  input: Portal_AddAdpConnectionSslCertificateInput;
};

export type MutationPortal_CreateConnectionArgs = {
  connection_type: ConnectionType;
  domains?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  organization_domain_ids?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationPortal_CreateDirectoryArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: DirectoryType;
};

export type MutationPortal_DeleteDirectoryArgs = {
  input: Portal_DeleteDirectoryInput;
};

export type MutationPortal_DeleteDraftConnectionArgs = {
  id: Scalars['String'];
};

export type MutationPortal_LoadedArgs = {
  legacy?: InputMaybe<Scalars['Boolean']>;
  started_at: Scalars['Float'];
};

export type MutationPortal_ReadyArgs = {
  legacy?: InputMaybe<Scalars['Boolean']>;
  started_at: Scalars['Float'];
};

export type MutationPortal_SetDirectoryCustomAttributeMappingsArgs = {
  input: Portal_SetDirectoryCustomAttributeMappingsInput;
};

export type MutationPortal_TrackEventArgs = {
  attributes?: InputMaybe<Scalars['JSON']>;
  event_name: Scalars['String'];
};

export type MutationPortal_UpdateAttributeMapArgs = {
  attribute_map_id: Scalars['String'];
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_UpdateConnectionArgs = {
  input: Portal_UpdateConnectionInput;
};

export type MutationPortal_UpdateDirectoryArgs = {
  bamboo_hr_api_key?: InputMaybe<Scalars['String']>;
  bamboo_hr_subdomain?: InputMaybe<Scalars['String']>;
  bob_api_token?: InputMaybe<Scalars['String']>;
  bob_service_user_id?: InputMaybe<Scalars['String']>;
  breatheHrApiKey?: InputMaybe<Scalars['String']>;
  directory_id: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  fourthHrOrganizationId?: InputMaybe<Scalars['String']>;
  fourthHrPassword?: InputMaybe<Scalars['String']>;
  fourthHrUsername?: InputMaybe<Scalars['String']>;
  rippling_api_key?: InputMaybe<Scalars['String']>;
  workday_raas_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_group_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_password?: InputMaybe<Scalars['String']>;
  workday_raas_username?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_UpdateDirectoryAttributeMapArgs = {
  input: Portal_UpdateDirectoryAttributeMapInput;
};

export type MutationPortalIdentityProviderRequestArgs = {
  identityProvider: Scalars['String'];
};

export type MutationProvisionTestAppAndUserArgs = {
  input: ProvisionTestAppAndUserInput;
};

export type MutationRemoveUserFromTeamArgs = {
  usersOrganizationsId: Scalars['String'];
};

export type MutationResetKeyArgs = {
  id: Scalars['String'];
};

export type MutationSetDirectoryCustomAttributeMappingsArgs = {
  input: SetDirectoryCustomAttributeMappingsInput;
};

export type MutationSetDomainsForOrganizationArgs = {
  input: SetDomainsForOrganizationInput;
};

export type MutationSetEmailNotificationPreferencesArgs = {
  input: SetEmailNotificationPreferencesInput;
};

export type MutationSetRedirectUrisArgs = {
  input: SetRedirectUrisInput;
};

export type MutationTestWebhookEndpointArgs = {
  event: WebhookEventFixtures;
  id: Scalars['String'];
};

export type MutationUpdateAttributeMapArgs = {
  attribute_map_id: Scalars['String'];
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};

export type MutationUpdateConnectionFromMetadataUrlArgs = {
  input: UpdateConnectionFromMetadataUrlInput;
};

export type MutationUpdateConnectionFromMetadataXmlArgs = {
  input: UpdateConnectionFromMetadataXmlInput;
};

export type MutationUpdateDirectoryArgs = {
  input: UpdateDirectoryInput;
};

export type MutationUpdateDirectoryAttributeMapArgs = {
  directory_attribute_map_id: Scalars['String'];
  emails_attribute?: InputMaybe<Scalars['String']>;
  external_id_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  group_name_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
  username_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateDirectoryCustomAttributeArgs = {
  input: UpdateDirectoryCustomAttributeInput;
};

export type MutationUpdateEnvironmentArgs = {
  input: UpdateEnvironmentInput;
};

export type MutationUpdateOAuthCredentialArgs = {
  client_id?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  oauth_credential_id: Scalars['String'];
};

export type MutationUpdateOrganizationArgs = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePortalSettingsArgs = {
  default_redirect_link: Scalars['String'];
  portal_settings_id: Scalars['String'];
};

export type MutationUpdateProjectV2Args = {
  name: Scalars['String'];
  project_id: Scalars['String'];
};

export type MutationUpdateRedirectUriArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  redirectUriId: Scalars['String'];
  uri?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSessionArgs = {
  session: UpdateSessionInput;
};

export type MutationUpdateTeamArgs = {
  id: Scalars['String'];
  provider?: InputMaybe<TeamsProvider>;
  sdk_language?: InputMaybe<Scalars['String']>;
  sso_onboarding_step?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateWebhookEndpointArgs = {
  endpointUrl?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<WebhookEndpointState>;
  webhookEndpointId: Scalars['String'];
};

export type MutationUpsertSamlX509CertificateArgs = {
  input: UpsertSamlX509CertificateInput;
};

export type OauthConnectionMissingCredentials = {
  __typename?: 'OauthConnectionMissingCredentials';
  connectionId?: Maybe<Scalars['String']>;
};

export type OAuthCredential = {
  __typename?: 'OAuthCredential';
  /** @deprecated Please use `clientId` instead. */
  client_id?: Maybe<Scalars['String']>;
  /** @deprecated Please use `clientSecret` instead. */
  client_secret?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  /** @deprecated Please use `externalKey` instead. */
  external_key: Scalars['String'];
  externalKey: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated Please use `redirectUri` instead. */
  redirect_uri?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  state: OAuthCredentialState;
  type: ConnectionType;
};

export type OAuthCredentialList = {
  __typename?: 'OAuthCredentialList';
  data: Array<OAuthCredential>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OAuth Credential. */
export enum OAuthCredentialState {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

export type OAuthSession = {
  __typename?: 'OAuthSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: OAuthSessionState;
  /** @deprecated Please use `stateParameter` instead. */
  state_parameter: Scalars['String'];
  stateParameter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OAuthSessionsList = {
  __typename?: 'OAuthSessionsList';
  data: Array<OAuthSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OAuth Session. */
export enum OAuthSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type OidcSession = {
  __typename?: 'OidcSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: OidcSessionState;
  stateParameter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OidcSessionsList = {
  __typename?: 'OidcSessionsList';
  data: Array<OidcSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OIDC Session. */
export enum OidcSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type OnboardingCompanyDetailsInput = {
  name: Scalars['String'];
  size: Scalars['String'];
};

export type OnboardingCompleteStepInput = {
  /** The next step in the onboarding process, if one exists. */
  nextStep?: InputMaybe<Scalars['String']>;
};

export type OnboardingCompleteStepPayload = {
  __typename?: 'OnboardingCompleteStepPayload';
  team?: Maybe<Team>;
};

export type OnboardingEnterTeamDetailsInput = {
  company: OnboardingCompanyDetailsInput;
  /** Whether the CRM integration should be disabled. */
  noCrm?: InputMaybe<Scalars['Boolean']>;
  urgency: Scalars['String'];
  user: OnboardingUserDetailsInput;
};

export type OnboardingUserDetailsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  allowProfilesOutsideOrganization: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  domains: Array<OrganizationDomain>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationDomain = {
  __typename?: 'OrganizationDomain';
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type OrganizationList = {
  __typename?: 'OrganizationList';
  data: Array<Organization>;
  listMetadata: ListMetadata;
};

export type OrganizationNotFound = {
  __typename?: 'OrganizationNotFound';
  organizationId: Scalars['String'];
};

export type PasswordlessSession = {
  __typename?: 'PasswordlessSession';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: PasswordlessSessionState;
  updatedAt: Scalars['DateTime'];
};

export type PasswordlessSessionList = {
  __typename?: 'PasswordlessSessionList';
  data: Array<PasswordlessSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the Passwordless Session. */
export enum PasswordlessSessionState {
  Delivered = 'Delivered',
  Expired = 'Expired',
  New = 'New',
  Pending = 'Pending',
  Verified = 'Verified',
}

export type Portal_AddAdpConnectionSslCertificateInput = {
  connectionId: Scalars['String'];
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type Portal_AddAdpConnectionSslCertificateResult =
  | ConnectionNotFound
  | Portal_AdpConnectionSslCertificateAdded
  | Portal_InvalidAdpConnectionSslCertificate;

export type Portal_AdpConnectionSslCertificate = {
  __typename?: 'Portal_AdpConnectionSslCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type Portal_AdpConnectionSslCertificateAdded = {
  __typename?: 'Portal_AdpConnectionSslCertificateAdded';
  adpConnectionSslCertificate: Portal_AdpConnectionSslCertificate;
};

export type Portal_Connection = {
  __typename?: 'portal_Connection';
  adpConnectionSslCertificates: Array<Portal_AdpConnectionSslCertificate>;
  /** @deprecated Please use `attributeMap` instead. */
  attribute_map?: Maybe<AttributeMap>;
  attributeMap?: Maybe<AttributeMap>;
  connectionDomains: Array<ConnectionDomain>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  oauthSessions: OAuthSessionsList;
  oidc_client_id?: Maybe<Scalars['String']>;
  oidc_client_secret?: Maybe<Scalars['String']>;
  oidc_discovery_endpoint?: Maybe<Scalars['String']>;
  oidc_redirect_uri?: Maybe<Scalars['String']>;
  oidcSessions: OidcSessionsList;
  organization?: Maybe<Organization>;
  organizationDomains: Array<OrganizationDomain>;
  saml_acs_url: Scalars['String'];
  saml_entity_id?: Maybe<Scalars['String']>;
  saml_idp_metadata_url?: Maybe<Scalars['String']>;
  saml_idp_url?: Maybe<Scalars['String']>;
  saml_relying_party_private_key?: Maybe<Scalars['String']>;
  saml_relying_party_public_key?: Maybe<Scalars['String']>;
  saml_sp_metadata_url: Scalars['String'];
  /** @deprecated Please use `samlX509Certificates` instead. */
  saml_x509_certs?: Maybe<Array<Scalars['String']>>;
  samlSessions: SamlSessionsList;
  samlX509Certificates: Array<Portal_SamlX509Certificate>;
  state: ConnectionState;
  /** @deprecated Please use `state` instead. */
  status: ConnectionStatus;
  type: ConnectionType;
};

export type Portal_ConnectionOauthSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OAuthSessionState>;
};

export type Portal_ConnectionOidcSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OidcSessionState>;
};

export type Portal_ConnectionSamlSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SamlSessionState>;
};

export type Portal_ConnectionList = {
  __typename?: 'portal_ConnectionList';
  data: Array<Portal_Connection>;
  listMetadata: ListMetadata;
};

export type Portal_ConnectionUpdated = {
  __typename?: 'Portal_ConnectionUpdated';
  connection: Portal_Connection;
};

export type Portal_DeleteDirectoryInput = {
  id: Scalars['String'];
};

export type Portal_DeleteDirectoryPayload = {
  __typename?: 'Portal_DeleteDirectoryPayload';
  directory?: Maybe<Directory>;
};

export type Portal_Directory = {
  __typename?: 'portal_Directory';
  attributeMap?: Maybe<DirectoryAttributeMap>;
  bamboo_hr_api_key?: Maybe<Scalars['String']>;
  bamboo_hr_subdomain?: Maybe<Scalars['String']>;
  /** @deprecated Please use `bearerToken` instead */
  bearer_token?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  bob_api_token?: Maybe<Scalars['String']>;
  bob_service_user_id?: Maybe<Scalars['String']>;
  breatheHrApiKey?: Maybe<Scalars['String']>;
  countsSummary: Portal_DirectoryCountsSummary;
  createdAt: Scalars['DateTime'];
  customAttributeMappings: Array<Portal_DirectoryCustomAttributeMapping>;
  directorySyncRuns: Portal_DirectorySyncRunsList;
  directoryUser: DirectoryUser;
  directoryUsers: DirectoryUsersList;
  endpoint?: Maybe<Scalars['String']>;
  environment: Environment;
  fourthHrOrganizationId?: Maybe<Scalars['String']>;
  fourthHrPassword?: Maybe<Scalars['String']>;
  fourthHrUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  rippling_api_key?: Maybe<Scalars['String']>;
  setup_url?: Maybe<Scalars['String']>;
  state: DirectoryState;
  type: DirectoryType;
  updatedAt: Scalars['DateTime'];
  workday_raas_endpoint?: Maybe<Scalars['String']>;
  workday_raas_group_endpoint?: Maybe<Scalars['String']>;
  workday_raas_password?: Maybe<Scalars['String']>;
  workday_raas_username?: Maybe<Scalars['String']>;
};

export type Portal_DirectoryDirectorySyncRunsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
};

export type Portal_DirectoryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type Portal_DirectoryDirectoryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Portal_DirectoryCountsSummary = {
  __typename?: 'Portal_DirectoryCountsSummary';
  groupCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type Portal_DirectoryCustomAttribute = {
  __typename?: 'Portal_DirectoryCustomAttribute';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Portal_DirectoryCustomAttributeList = {
  __typename?: 'Portal_DirectoryCustomAttributeList';
  data: Array<Portal_DirectoryCustomAttribute>;
};

export type Portal_DirectoryCustomAttributeMapping = {
  __typename?: 'Portal_DirectoryCustomAttributeMapping';
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
  id: Scalars['ID'];
};

export type Portal_DirectoryCustomAttributeMappingInput = {
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
};

export type Portal_DirectoryCustomAttributeMappingsSet = {
  __typename?: 'Portal_DirectoryCustomAttributeMappingsSet';
  mappings: Array<Portal_DirectoryCustomAttributeMapping>;
};

export type Portal_DirectoryList = {
  __typename?: 'portal_DirectoryList';
  data: Array<Portal_Directory>;
  listMetadata: ListMetadata;
};

export type Portal_DirectorySyncRun = {
  __typename?: 'Portal_DirectorySyncRun';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  directoryId: Scalars['String'];
  errors: Array<DirectorySyncRunError>;
  groupCounts: Portal_DirectorySyncRunResourceCounts;
  id: Scalars['String'];
  state: DirectorySyncRunState;
  updatedAt: Scalars['DateTime'];
  userCounts: Portal_DirectorySyncRunResourceCounts;
};

export type Portal_DirectorySyncRunResourceCounts = {
  __typename?: 'Portal_DirectorySyncRunResourceCounts';
  created: Scalars['Float'];
  deleted: Scalars['Float'];
  processed: Scalars['Float'];
  updated: Scalars['Float'];
};

export type Portal_DirectorySyncRunsList = {
  __typename?: 'portal_DirectorySyncRunsList';
  data: Array<Portal_DirectorySyncRun>;
  listMetadata: ListMetadata;
};

export type Portal_InvalidAdpConnectionSslCertificate = {
  __typename?: 'Portal_InvalidAdpConnectionSslCertificate';
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type Portal_PortalSession = {
  __typename?: 'portal_PortalSession';
  appName: Scalars['String'];
  id: Scalars['ID'];
  organization: Organization;
  portalSettings: PortalSettings;
  returnUrl: Scalars['String'];
  state: PortalSessionState;
};

export type Portal_SamlX509Certificate = {
  __typename?: 'portal_SamlX509Certificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type Portal_SetDirectoryCustomAttributeMappingsInput = {
  directoryId: Scalars['ID'];
  mappings: Array<Portal_DirectoryCustomAttributeMappingInput>;
};

export type Portal_SetDirectoryCustomAttributeMappingsResult =
  | DirectoryCustomAttributeNotFound
  | DirectoryNotFound
  | Portal_DirectoryCustomAttributeMappingsSet;

export type Portal_UpdateConnectionInput = {
  connectionId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  oidc?: InputMaybe<Portal_UpdateOidcConnectionInput>;
  saml?: InputMaybe<Portal_UpdateSamlConnectionInput>;
};

export type Portal_UpdateConnectionResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdated
  | VerifyConnectionFailed;

export type Portal_UpdateDirectoryAttributeMapInput = {
  directoryAttributeMapId: Scalars['String'];
  emailsAttribute?: InputMaybe<Scalars['String']>;
  externalIdAttribute?: InputMaybe<Scalars['String']>;
  firstNameAttribute?: InputMaybe<Scalars['String']>;
  groupNameAttribute?: InputMaybe<Scalars['String']>;
  lastNameAttribute?: InputMaybe<Scalars['String']>;
  usernameAttribute?: InputMaybe<Scalars['String']>;
};

export type Portal_UpdateDirectoryAttributeMapPayload = {
  __typename?: 'Portal_UpdateDirectoryAttributeMapPayload';
  directoryAttributeMap?: Maybe<DirectoryAttributeMap>;
};

export type Portal_UpdateOidcConnectionInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  discoveryEndpoint?: InputMaybe<Scalars['String']>;
};

export type Portal_UpdateSamlConnectionInput = {
  entityId?: InputMaybe<Scalars['String']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']>;
  idpUrl?: InputMaybe<Scalars['String']>;
  relyingPartyPrivateKey?: InputMaybe<Scalars['String']>;
  x509Certs?: InputMaybe<Array<Scalars['String']>>;
};

/** Enum represents the state of an Admin Portal session. */
export enum PortalSessionState {
  Exchanged = 'Exchanged',
  Pending = 'Pending',
}

export type PortalSettings = {
  __typename?: 'PortalSettings';
  defaultRedirectLink: Scalars['String'];
  faviconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  /** @deprecated Please use `connectionType` instead. */
  connection_type: ConnectionType;
  connectionType: ConnectionType;
  email?: Maybe<Scalars['String']>;
  /** @deprecated Please use `firstName` instead. */
  first_name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Please use `idpId` instead. */
  idp_id?: Maybe<Scalars['String']>;
  idpId?: Maybe<Scalars['String']>;
  /** @deprecated Please use `lastName` instead. */
  last_name?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Please use `rawAttributes` instead. */
  raw_attributes?: Maybe<Scalars['JSON']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  state: ProfileState;
  updatedAt: Scalars['DateTime'];
};

export type ProfileByIdpIdInput = {
  connectionId: Scalars['String'];
  idpId: Scalars['String'];
};

export type ProfileNotAllowedOutsideOrganizationError = {
  __typename?: 'ProfileNotAllowedOutsideOrganizationError';
  code: ConnectionSessionErrorCode;
  organizationDomains: Array<Scalars['String']>;
  profileDomain: Scalars['String'];
  reason: Scalars['String'];
};

/** Enum represents the state of the profile. */
export enum ProfileState {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

export type ProjectV2 = {
  __typename?: 'ProjectV2';
  environments: Array<Environment>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProvisionTestAppAndUserInput = {
  environmentId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  connection: Connection;
  connections: ConnectionList;
  currentEnvironment: Environment;
  currentTeam?: Maybe<Team>;
  directories: DirectoryList;
  directory: Directory;
  directoryAttributeMap: DirectoryAttributeMap;
  directoryCustomAttributeMappings: DirectoryCustomAttributeMappingList;
  directoryCustomAttributes: DirectoryCustomAttributeList;
  directorySyncRun: DirectorySyncRun;
  directoryUser: DirectoryUser;
  emailNotificationPreferences: EmailNotificationPreferences;
  environment: Environment;
  event: Event;
  eventActions: Array<EventAction>;
  events: EventList;
  generateEventsCSV: Scalars['String'];
  keys: KeyList;
  me: User;
  oauthCredentialsByType: OAuthCredentialList;
  organization: Organization;
  organizations: OrganizationList;
  passwordlessSessions: PasswordlessSessionList;
  portal_connections: Portal_ConnectionList;
  portal_connectionSession: ConnectionSession;
  portal_directories: Portal_DirectoryList;
  portal_directory: Portal_Directory;
  portal_directoryCustomAttributes: Portal_DirectoryCustomAttributeList;
  portal_portalSession: Portal_PortalSession;
  profile: Profile;
  profileByIdpId: Profile;
  /** @deprecated Please use `profileByIdpId` instead. */
  profileForIdpId: Profile;
  redirectUris: RedirectUriList;
  request: Request;
  requests: RequestList;
  session: Session;
  verifyConnection: VerifyConnectionResponse;
  webhook: Webhook;
  webhookEndpoint: WebhookEndpoint;
  webhookEndpoints: WebhookEndpointList;
  webhookEvents: Array<WebhookEvent>;
};

export type QueryConnectionArgs = {
  id: Scalars['String'];
};

export type QueryConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};

export type QueryDirectoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};

export type QueryDirectoryArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryAttributeMapArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryCustomAttributeMappingsArgs = {
  directoryId: Scalars['String'];
};

export type QueryDirectoryCustomAttributesArgs = {
  environmentId: Scalars['String'];
};

export type QueryDirectorySyncRunArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type QueryEnvironmentArgs = {
  id: Scalars['String'];
};

export type QueryEventArgs = {
  id: Scalars['String'];
};

export type QueryEventActionsArgs = {
  environmentId: Scalars['String'];
};

export type QueryEventsArgs = {
  action?: InputMaybe<Array<Scalars['String']>>;
  action_type?: InputMaybe<Array<Scalars['String']>>;
  actor_id?: InputMaybe<Array<Scalars['String']>>;
  actor_name?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  group?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gte?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lte?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Array<Scalars['String']>>;
  target_name?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGenerateEventsCsvArgs = {
  action?: InputMaybe<Array<Scalars['String']>>;
  action_type?: InputMaybe<Array<Scalars['String']>>;
  actor_id?: InputMaybe<Array<Scalars['String']>>;
  actor_name?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  group?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gte?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lte?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Array<Scalars['String']>>;
  target_name?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryKeysArgs = {
  environmentId: Scalars['String'];
  scope?: InputMaybe<KeyScope>;
};

export type QueryOauthCredentialsByTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  type: ConnectionType;
};

export type QueryOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPasswordlessSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  connectionId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_ConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPortal_ConnectionSessionArgs = {
  id: Scalars['String'];
};

export type QueryPortal_DirectoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_DirectoryArgs = {
  id: Scalars['String'];
};

export type QueryPortal_DirectoryCustomAttributesArgs = {
  directoryId: Scalars['String'];
};

export type QueryProfileArgs = {
  id: Scalars['String'];
};

export type QueryProfileByIdpIdArgs = {
  input: ProfileByIdpIdInput;
};

export type QueryProfileForIdpIdArgs = {
  idp_id: Scalars['String'];
  project_id: Scalars['String'];
};

export type QueryRedirectUrisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryRequestArgs = {
  id: Scalars['String'];
};

export type QueryRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']>;
  created_at_lt?: InputMaybe<Scalars['DateTime']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  method?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type QueryVerifyConnectionArgs = {
  connection_id: Scalars['String'];
};

export type QueryWebhookArgs = {
  id: Scalars['String'];
};

export type QueryWebhookEndpointArgs = {
  id: Scalars['String'];
};

export type QueryWebhookEndpointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type ReceivedAttribute = {
  __typename?: 'ReceivedAttribute';
  attribute: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type RedirectUri = {
  __typename?: 'RedirectURI';
  /** @deprecated Please use `isDefault` instead. */
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type RedirectUriList = {
  __typename?: 'RedirectUriList';
  data: Array<RedirectUri>;
  listMetadata: ListMetadata;
};

export type RedirectUrisSet = {
  __typename?: 'RedirectUrisSet';
  redirectUris: Array<RedirectUri>;
};

export type Request = {
  __typename?: 'Request';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  method: Scalars['String'];
  path: Scalars['String'];
  /** @deprecated Please use `requestBody` instead. */
  request_body?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `requestHeaders` instead. */
  request_headers: Scalars['JSON'];
  /** @deprecated Please use `requestId` instead. */
  request_id?: Maybe<Scalars['String']>;
  /** @deprecated Please use `requestQuery` instead. */
  request_query?: Maybe<Scalars['JSON']>;
  requestBody?: Maybe<Scalars['JSON']>;
  requestHeaders: Scalars['JSON'];
  requestId?: Maybe<Scalars['String']>;
  requestQuery?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `responseBody` instead. */
  response_body?: Maybe<Scalars['JSON']>;
  responseBody?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
};

export type RequestList = {
  __typename?: 'RequestList';
  data: Array<Request>;
  listMetadata: ListMetadata;
};

export type RipplingDirectory = {
  __typename?: 'RipplingDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type SamlSession = {
  __typename?: 'SAMLSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  /** @deprecated Please use `samlRequest` instead. */
  saml_request?: Maybe<Scalars['String']>;
  /** @deprecated Please use `samlResponse` instead. */
  saml_response?: Maybe<Scalars['String']>;
  samlRequest?: Maybe<Scalars['String']>;
  samlResponse?: Maybe<Scalars['String']>;
  state: SamlSessionState;
  /** @deprecated Please use `stateParameter` instead. */
  state_parameter: Scalars['String'];
  stateParameter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SamlSessionsList = {
  __typename?: 'SAMLSessionsList';
  data: Array<SamlSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the SAML Session. */
export enum SamlSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type SamlX509Certificate = {
  __typename?: 'SamlX509Certificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type SamlX509CertificateDeleted = {
  __typename?: 'SamlX509CertificateDeleted';
  samlX509Certificate: SamlX509Certificate;
};

export type SamlX509CertificateNotFound = {
  __typename?: 'SamlX509CertificateNotFound';
  samlX509CertificateId: Scalars['String'];
};

export type SamlX509CertificateUpserted = {
  __typename?: 'SamlX509CertificateUpserted';
  samlX509Certificate: SamlX509Certificate;
};

/** Enum represents why the SAML X509 Certificate was not valid. */
export enum SamlX509CertificateValidationErrorCode {
  ExpiredCertificate = 'ExpiredCertificate',
  MalformedCertificate = 'MalformedCertificate',
}

export type Session = {
  __typename?: 'Session';
  currentEnvironment: Environment;
};

export type SetDirectoryCustomAttributeMappingsInput = {
  directoryId: Scalars['ID'];
  mappings: Array<DirectoryCustomAttributeMappingInput>;
};

export type SetDirectoryCustomAttributeMappingsResult =
  | DirectoryCustomAttributeMappingsSet
  | DirectoryCustomAttributeNotFound
  | DirectoryNotFound;

export type SetDomainsForOrganizationInput = {
  domains: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type SetDomainsForOrganizationPayload = {
  __typename?: 'SetDomainsForOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type SetEmailNotificationPreferenceInput = {
  category: EmailNotificationCategory;
  preference: EmailNotificationPreferenceConfiguration;
};

export type SetEmailNotificationPreferencesInput = {
  preferences: Array<SetEmailNotificationPreferenceInput>;
};

export type SetEmailNotificationPreferencesResult = EmailNotificationPreferences;

export type SetRedirectUriInput = {
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  uri: Scalars['String'];
};

export type SetRedirectUrisInput = {
  environmentId: Scalars['String'];
  redirectUris: Array<SetRedirectUriInput>;
};

export type SetRedirectUrisResult =
  | InvalidRedirectUriProcotol
  | RedirectUrisSet;

export type SsoDemoApplication = {
  __typename?: 'SSODemoApplication';
  /** @deprecated Please use `connectionType` instead. */
  connection_type: ConnectionType;
  connectionType: ConnectionType;
  id: Scalars['ID'];
  /** @deprecated Please use `idpId` instead. */
  idp_id?: Maybe<Scalars['String']>;
  idpId?: Maybe<Scalars['String']>;
};

export type SsoDemoUser = {
  __typename?: 'SSODemoUser';
  /** @deprecated Please use `connectionType` instead. */
  connection_type: ConnectionType;
  connectionType: ConnectionType;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Please use `idpId` instead. */
  idp_id?: Maybe<Scalars['String']>;
  idpId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type Team = {
  __typename?: 'Team';
  billingDetails: TeamBillingDetails;
  endpoint_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  onboarding: TeamOnboardingStatus;
  projectsV2: Array<ProjectV2>;
  sdk_language?: Maybe<Scalars['String']>;
  sso_onboarding_step?: Maybe<Scalars['Int']>;
  ssoDemoUser?: Maybe<SsoDemoUser>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  users_teams: Array<UsersTeam>;
};

export type TeamBillingDetails = {
  __typename?: 'TeamBillingDetails';
  connections: TeamConnectionStats;
  email?: Maybe<Scalars['String']>;
  /** Whether the team has a payment method setup. */
  hasPaymentMethod: Scalars['Boolean'];
};

export type TeamConnectionStats = {
  __typename?: 'TeamConnectionStats';
  enterpriseConnectionCount: Scalars['Float'];
};

export type TeamOnboarded = {
  __typename?: 'TeamOnboarded';
  status: Scalars['String'];
};

export type TeamOnboarding = {
  __typename?: 'TeamOnboarding';
  /** The next onboarding step the team needs to go through. */
  nextStep: Scalars['String'];
  status: Scalars['String'];
};

export type TeamOnboardingStatus = TeamOnboarded | TeamOnboarding;

/** Enum represents the login provider for the team. */
export enum TeamsProvider {
  Adfs = 'ADFS',
  Azure = 'Azure',
  Email = 'Email',
  Google = 'Google',
  Okta = 'Okta',
}

export type UpdateBambooHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
};

export type UpdateBreatheHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateConnectionFromMetadataUrlInput = {
  connectionId: Scalars['String'];
  metadataUrl: Scalars['String'];
};

export type UpdateConnectionFromMetadataUrlResult =
  | ConnectionNotFound
  | ConnectionUpdatedFromMetadataUrl
  | MetadataFetchFailed;

export type UpdateConnectionFromMetadataXmlInput = {
  connectionId: Scalars['String'];
  metadataXml: Scalars['String'];
};

export type UpdateConnectionFromMetadataXmlResult =
  | ConnectionNotFound
  | ConnectionUpdatedFromMetadataXml
  | MetadataParseFailed;

export type UpdateConnectionInput = {
  connectionId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  oidc?: InputMaybe<UpdateOidcConnectionInput>;
  saml?: InputMaybe<UpdateSamlConnectionInput>;
};

export type UpdateConnectionResult =
  | ConnectionNotFound
  | ConnectionUpdated
  | InvalidSamlX509Certificate;

export type UpdateDirectoryCustomAttributeInput = {
  directoryCustomAttributeId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeNotFound
  | DirectoryCustomAttributeUpdated;

export type UpdateDirectoryInput = {
  bambooHr?: InputMaybe<UpdateBambooHrDirectoryInput>;
  breatheHr?: InputMaybe<UpdateBreatheHrDirectoryInput>;
  directoryId: Scalars['String'];
  fourthHr?: InputMaybe<UpdateFourthHrDirectoryInput>;
  hibob?: InputMaybe<UpdateHibobDirectoryInput>;
  rippling?: InputMaybe<UpdateRipplingDirectoryInput>;
  workday?: InputMaybe<UpdateWorkdayDirectoryInput>;
};

export type UpdateDirectoryResult =
  | DirectoryNotFound
  | DirectoryProviderErrors
  | DirectoryUpdated;

export type UpdateEnvironmentInput = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  environmentId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateEnvironmentResult = EnvironmentNotFound | EnvironmentUpdated;

export type UpdateFourthHrDirectoryInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateHibobDirectoryInput = {
  apiToken?: InputMaybe<Scalars['String']>;
  serviceUserId?: InputMaybe<Scalars['String']>;
};

export type UpdateOidcConnectionInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  discoveryEndpoint?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type UpdateRipplingDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateSamlConnectionInput = {
  entityId?: InputMaybe<Scalars['String']>;
  idpEntityId?: InputMaybe<Scalars['String']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']>;
  idpUrl?: InputMaybe<Scalars['String']>;
  relyingPartyPrivateKey?: InputMaybe<Scalars['String']>;
  x509Certs?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateSessionInput = {
  currentEnvironmentId?: InputMaybe<Scalars['String']>;
  currentProjectID?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkdayDirectoryInput = {
  groupEndpoint?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userEndpoint?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpsertSamlX509CertificateInput = {
  connectionId: Scalars['String'];
  value: Scalars['String'];
};

export type UpsertSamlX509CertificateResult =
  | ConnectionNotFound
  | SamlX509CertificateUpserted;

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  users_teams: Array<UsersTeam>;
};

export type UserInviteInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: UsersOrganizationsRole;
};

/** Roles a user can have in an organization. */
export enum UsersOrganizationsRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

/** States a user can have in an organization. */
export enum UsersOrganizationsState {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
}

export type UsersTeam = {
  __typename?: 'UsersTeam';
  id: Scalars['ID'];
  role: UsersOrganizationsRole;
  state: UsersOrganizationsState;
  team: Team;
  user: User;
  user_id: Scalars['String'];
};

export type VerifyConnectionFailed = {
  __typename?: 'VerifyConnectionFailed';
  connectionErrors: Array<ConnectionError>;
};

export type VerifyConnectionResponse = {
  __typename?: 'VerifyConnectionResponse';
  connectionErrors: Array<ConnectionError>;
  verified: Scalars['Boolean'];
};

export type Webhook = {
  __typename?: 'Webhook';
  attempt: Scalars['Int'];
  /** @deprecated Please use `deliverAfter` instead. */
  deliver_after?: Maybe<Scalars['DateTime']>;
  deliverAfter?: Maybe<Scalars['DateTime']>;
  event: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
  request_body?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `requestBody` instead. */
  requestBody?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `responseBody` instead. */
  response_body?: Maybe<Scalars['JSON']>;
  responseBody?: Maybe<Scalars['JSON']>;
  state: WebhookState;
  /** @deprecated Please use `webhookEndpoint.id` instead. */
  webhook_endpoint_id: Scalars['String'];
};

export type WebhookEndpoint = {
  __typename?: 'WebhookEndpoint';
  createdAt: Scalars['DateTime'];
  /** @deprecated Please use `endpointUrl` instead. */
  endpoint_url: Scalars['String'];
  endpointUrl: Scalars['String'];
  events: Array<Scalars['String']>;
  id: Scalars['ID'];
  secret: Scalars['String'];
  state: WebhookEndpointState;
  webhooks: WebhookList;
};

export type WebhookEndpointWebhooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type WebhookEndpointList = {
  __typename?: 'WebhookEndpointList';
  data: Array<WebhookEndpoint>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the webhook endpoint. */
export enum WebhookEndpointState {
  Active = 'Active',
  InActive = 'InActive',
}

export type WebhookEvent = {
  __typename?: 'WebhookEvent';
  description: Scalars['String'];
  fixtures: Array<WebhookFixture>;
  id: Scalars['ID'];
};

/** Enum represents event fixtures of Webhooks */
export enum WebhookEventFixtures {
  ConnectionActivated = 'ConnectionActivated',
  ConnectionDeactivated = 'ConnectionDeactivated',
  ConnectionDeleted = 'ConnectionDeleted',
  ConnectionUpdatedLinked = 'ConnectionUpdatedLinked',
  ConnectionUpdatedUnlinked = 'ConnectionUpdatedUnlinked',
  DirectoryActivated = 'DirectoryActivated',
  DirectoryDeactivated = 'DirectoryDeactivated',
  DirectoryDeleted = 'DirectoryDeleted',
  GroupCreated = 'GroupCreated',
  GroupDeleted = 'GroupDeleted',
  GroupUpdated = 'GroupUpdated',
  UserAddedToGroup = 'UserAddedToGroup',
  UserCreated = 'UserCreated',
  UserDeleted = 'UserDeleted',
  UserRemovedFromGroup = 'UserRemovedFromGroup',
  UserUpdated = 'UserUpdated',
}

export type WebhookFixture = {
  __typename?: 'WebhookFixture';
  name: Scalars['String'];
  payload: Scalars['JSON'];
};

export type WebhookList = {
  __typename?: 'WebhookList';
  data: Array<Webhook>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the webhook. */
export enum WebhookState {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Scheduled = 'Scheduled',
}

export type WorkdayDirectory = {
  __typename?: 'WorkdayDirectory';
  groupEndpoint?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  userEndpoint?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ConnectionAttributeMapFragment = {
  __typename?: 'AttributeMap';
  id: string;
  attributes: {
    __typename?: 'Attributes';
    idpId: string;
    email: string;
    firstName: string;
    lastName: string;
  };
};

export type AuditTrailEventFragment = {
  __typename?: 'Event';
  id: string;
  group: string;
  location: string;
  type?: string | null | undefined;
  metadata?: any | null | undefined;
  occurredAt: string;
  action: { __typename?: 'EventAction'; name: string };
  actor: { __typename?: 'EventActor'; id: string; name: string };
  target: { __typename?: 'EventTarget'; id: string; name: string };
};

export type AuthedUserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null | undefined;
  email: string;
  users_teams: Array<{
    __typename?: 'UsersTeam';
    id: string;
    role: UsersOrganizationsRole;
    state: UsersOrganizationsState;
    user: {
      __typename?: 'User';
      id: string;
      name?: string | null | undefined;
      email: string;
    };
  }>;
};

export type ConnectionFragment = {
  __typename?: 'Connection';
  id: string;
  name: string;
  type: ConnectionType;
  oidc_client_id?: string | null | undefined;
  oidc_client_secret?: string | null | undefined;
  oidc_discovery_endpoint?: string | null | undefined;
  oidc_redirect_uri?: string | null | undefined;
  state: ConnectionState;
  saml_entity_id?: string | null | undefined;
  saml_sp_metadata_url: string;
  saml_acs_url: string;
  saml_idp_url?: string | null | undefined;
  saml_idp_metadata_url?: string | null | undefined;
  samlX509Certificates: Array<{
    __typename?: 'SamlX509Certificate';
    id: string;
    value: string;
    notBefore?: string | null | undefined;
    notAfter?: string | null | undefined;
  }>;
  adpConnectionSslCertificates: Array<{
    __typename?: 'AdpConnectionSslCertificate';
    createdAt: string;
    id: string;
    value: string;
  }>;
  attributeMap?:
    | {
        __typename?: 'AttributeMap';
        id: string;
        attributes: {
          __typename?: 'Attributes';
          idpId: string;
          email: string;
          firstName: string;
          lastName: string;
        };
      }
    | null
    | undefined;
  organization?:
    | { __typename?: 'Organization'; id: string; name: string }
    | null
    | undefined;
  organizationDomains: Array<{
    __typename?: 'OrganizationDomain';
    id: string;
    domain: string;
  }>;
};

export type CurrentTeamFragment = {
  __typename?: 'Team';
  id: string;
  name: string;
  billingDetails: {
    __typename?: 'TeamBillingDetails';
    email?: string | null | undefined;
    hasPaymentMethod: boolean;
  };
  onboarding:
    | { __typename: 'TeamOnboarded' }
    | { __typename: 'TeamOnboarding'; nextStep: string };
  ssoDemoUser?:
    | {
        __typename?: 'SSODemoUser';
        id: string;
        idp_id?: string | null | undefined;
        email?: string | null | undefined;
        password?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DirectoryAttributeMapFragment = {
  __typename?: 'DirectoryAttributeMap';
  id: string;
  external_id_attribute?: string | null | undefined;
  username_attribute?: string | null | undefined;
  emails_attribute?: string | null | undefined;
  first_name_attribute?: string | null | undefined;
  last_name_attribute?: string | null | undefined;
  group_name_attribute?: string | null | undefined;
};

export type DirectorySyncRunFragment = {
  __typename?: 'DirectorySyncRun';
  id: string;
  state: DirectorySyncRunState;
  updatedAt: string;
  errors: Array<
    | {
        __typename: 'MalformedDirectoryGroupError';
        code: DirectorySyncRunErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedDirectoryUserError';
        code: DirectorySyncRunErrorCode;
        reason: string;
      }
  >;
};

export type DirectoryUserFragment = {
  __typename?: 'DirectoryUser';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  username?: string | null | undefined;
  state: DirectoryUserState;
  directoryGroups: Array<{
    __typename?: 'DirectoryGroup';
    id: string;
    name: string;
  }>;
};

export type DirectoryFragment = {
  __typename?: 'Directory';
  id: string;
  type: DirectoryType;
  name: string;
  endpoint?: string | null | undefined;
  bearer_token?: string | null | undefined;
  state: DirectoryState;
  setup_url?: string | null | undefined;
  organization?:
    | {
        __typename?: 'Organization';
        id: string;
        name: string;
        domains: Array<{
          __typename?: 'OrganizationDomain';
          id: string;
          domain: string;
        }>;
      }
    | null
    | undefined;
  primaryDomain?:
    | { __typename?: 'OrganizationDomain'; domain: string }
    | null
    | undefined;
  provider?:
    | {
        __typename: 'BambooHrDirectory';
        subdomain?: string | null | undefined;
        apiKey?: string | null | undefined;
      }
    | { __typename: 'BreatheHrDirectory'; apiKey?: string | null | undefined }
    | {
        __typename: 'FourthHrDirectory';
        organizationId?: string | null | undefined;
        username?: string | null | undefined;
        password?: string | null | undefined;
      }
    | { __typename: 'GustoDirectory' }
    | {
        __typename: 'HibobDirectory';
        serviceUserId?: string | null | undefined;
        apiToken?: string | null | undefined;
      }
    | { __typename: 'RipplingDirectory'; apiKey?: string | null | undefined }
    | {
        __typename: 'WorkdayDirectory';
        userEndpoint?: string | null | undefined;
        groupEndpoint?: string | null | undefined;
        username?: string | null | undefined;
        password?: string | null | undefined;
      }
    | null
    | undefined;
  directoryAttributeMap?:
    | {
        __typename?: 'DirectoryAttributeMap';
        id: string;
        external_id_attribute?: string | null | undefined;
        username_attribute?: string | null | undefined;
        emails_attribute?: string | null | undefined;
        first_name_attribute?: string | null | undefined;
        last_name_attribute?: string | null | undefined;
        group_name_attribute?: string | null | undefined;
      }
    | null
    | undefined;
};

export type BambooHrDirectoryFragment = {
  __typename?: 'BambooHrDirectory';
  subdomain?: string | null | undefined;
  apiKey?: string | null | undefined;
};

export type BreatheHrDirectoryFragment = {
  __typename?: 'BreatheHrDirectory';
  apiKey?: string | null | undefined;
};

export type FourthHrDirectoryFragment = {
  __typename?: 'FourthHrDirectory';
  organizationId?: string | null | undefined;
  username?: string | null | undefined;
  password?: string | null | undefined;
};

export type HibobDirectoryFragment = {
  __typename?: 'HibobDirectory';
  serviceUserId?: string | null | undefined;
  apiToken?: string | null | undefined;
};

export type RipplingDirectoryFragment = {
  __typename?: 'RipplingDirectory';
  apiKey?: string | null | undefined;
};

export type WorkdayDirectoryFragment = {
  __typename?: 'WorkdayDirectory';
  userEndpoint?: string | null | undefined;
  groupEndpoint?: string | null | undefined;
  username?: string | null | undefined;
  password?: string | null | undefined;
};

export type EnvironmentWithProjectV2Fragment = {
  __typename?: 'Environment';
  id: string;
  name: string;
  logoUrl?: string | null | undefined;
  sandbox: boolean;
  clientId: string;
  projectV2: {
    __typename?: 'ProjectV2';
    id: string;
    name: string;
    environments: Array<{
      __typename?: 'Environment';
      id: string;
      name: string;
      logoUrl?: string | null | undefined;
      sandbox: boolean;
      clientId: string;
      portalSettings: {
        __typename?: 'PortalSettings';
        id: string;
        defaultRedirectLink: string;
      };
      ssoDemoApplication?:
        | {
            __typename?: 'SSODemoApplication';
            connectionType: ConnectionType;
            id: string;
            idpId?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
  portalSettings: {
    __typename?: 'PortalSettings';
    id: string;
    defaultRedirectLink: string;
  };
  ssoDemoApplication?:
    | {
        __typename?: 'SSODemoApplication';
        connectionType: ConnectionType;
        id: string;
        idpId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type EnvironmentFragment = {
  __typename?: 'Environment';
  id: string;
  name: string;
  logoUrl?: string | null | undefined;
  sandbox: boolean;
  clientId: string;
  portalSettings: {
    __typename?: 'PortalSettings';
    id: string;
    defaultRedirectLink: string;
  };
  ssoDemoApplication?:
    | {
        __typename?: 'SSODemoApplication';
        connectionType: ConnectionType;
        id: string;
        idpId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type KeyFragment = {
  __typename?: 'Key';
  id: string;
  createdAt: string;
  expiredAt?: string | null | undefined;
  name: string;
  displayValue: string;
};

export type NewlyCreatedKeyFragment = {
  __typename?: 'Key';
  value: string;
  id: string;
  createdAt: string;
  expiredAt?: string | null | undefined;
  name: string;
  displayValue: string;
};

export type OAuthCredentialFragment = {
  __typename?: 'OAuthCredential';
  id: string;
  state: OAuthCredentialState;
  redirect_uri?: string | null | undefined;
  client_id?: string | null | undefined;
  client_secret?: string | null | undefined;
};

export type OrganizationDomainFragment = {
  __typename?: 'OrganizationDomain';
  id: string;
  domain: string;
};

export type ProjectV2Fragment = {
  __typename?: 'ProjectV2';
  id: string;
  name: string;
  environments: Array<{
    __typename?: 'Environment';
    id: string;
    name: string;
    logoUrl?: string | null | undefined;
    sandbox: boolean;
    clientId: string;
    portalSettings: {
      __typename?: 'PortalSettings';
      id: string;
      defaultRedirectLink: string;
    };
    ssoDemoApplication?:
      | {
          __typename?: 'SSODemoApplication';
          connectionType: ConnectionType;
          id: string;
          idpId?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type RedirectUriFragment = {
  __typename?: 'RedirectURI';
  id: string;
  uri: string;
  isDefault: boolean;
};

export type SamlX509CertificateFragment = {
  __typename?: 'SamlX509Certificate';
  id: string;
  value: string;
  notBefore?: string | null | undefined;
  notAfter?: string | null | undefined;
};

export type WebhookEndpointFragment = {
  __typename?: 'WebhookEndpoint';
  id: string;
  endpoint_url: string;
  state: WebhookEndpointState;
  secret: string;
  events: Array<string>;
};

export type WebhookEventWithFixtureFragment = {
  __typename?: 'WebhookEvent';
  id: string;
  description: string;
  fixtures: Array<{
    __typename?: 'WebhookFixture';
    name: string;
    payload: any;
  }>;
};

export type AddAdpConnectionSslCertificateMutationVariables = Exact<{
  input: AddAdpConnectionSslCertificateInput;
}>;

export type AddAdpConnectionSslCertificateMutation = {
  __typename?: 'Mutation';
  addAdpConnectionSslCertificate:
    | {
        __typename: 'AdpConnectionSslCertificateAdded';
        adpConnectionSslCertificate: {
          __typename?: 'AdpConnectionSslCertificate';
          id: string;
          value: string;
        };
      }
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | { __typename: 'InvalidAdpConnectionSslCertificate' };
};

export type AddUserToTeamMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  role: UsersOrganizationsRole;
}>;

export type AddUserToTeamMutation = {
  __typename?: 'Mutation';
  addUserToTeam: {
    __typename?: 'UsersTeam';
    id: string;
    role: UsersOrganizationsRole;
    state: UsersOrganizationsState;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      name?: string | null | undefined;
    };
  };
};

export type AddPaymentMethodMutationVariables = Exact<{
  billingEmail: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
}>;

export type AddPaymentMethodMutation = {
  __typename?: 'Mutation';
  addPaymentMethod: {
    __typename?: 'AddPaymentMethodPayload';
    team: {
      __typename?: 'Team';
      id: string;
      name: string;
      billingDetails: {
        __typename?: 'TeamBillingDetails';
        email?: string | null | undefined;
        hasPaymentMethod: boolean;
      };
      onboarding:
        | { __typename: 'TeamOnboarded' }
        | { __typename: 'TeamOnboarding'; nextStep: string };
      ssoDemoUser?:
        | {
            __typename?: 'SSODemoUser';
            id: string;
            idp_id?: string | null | undefined;
            email?: string | null | undefined;
            password?: string | null | undefined;
          }
        | null
        | undefined;
    };
  };
};

export type AddUsersToTeamMutationVariables = Exact<{
  input: AddUsersToTeamInput;
}>;

export type AddUsersToTeamMutation = {
  __typename?: 'Mutation';
  addUsersToTeam: {
    __typename?: 'AddUsersToTeamPayload';
    invitedMembers?:
      | Array<{
          __typename?: 'UsersTeam';
          id: string;
          role: UsersOrganizationsRole;
        }>
      | null
      | undefined;
  };
};

export type CreateBillingPortalSessionMutationVariables = Exact<{
  returnUrl: Scalars['String'];
}>;

export type CreateBillingPortalSessionMutation = {
  __typename?: 'Mutation';
  createBillingPortalSession: {
    __typename?: 'CreateBillingPortalSessionPayload';
    billingPortalSessionUrl: string;
  };
};

export type CreateConnectionMutationVariables = Exact<{
  input: CreateConnectionInput;
}>;

export type CreateConnectionMutation = {
  __typename?: 'Mutation';
  createConnection:
    | {
        __typename: 'ConnectionCreated';
        connection: {
          __typename?: 'Connection';
          id: string;
          name: string;
          type: ConnectionType;
        };
      }
    | { __typename: 'OauthConnectionMissingCredentials' }
    | { __typename: 'OrganizationNotFound'; organizationId: string };
};

export type CreateDirectoryCustomAttributeMutationVariables = Exact<{
  input: CreateDirectoryCustomAttributeInput;
}>;

export type CreateDirectoryCustomAttributeMutation = {
  __typename?: 'Mutation';
  createDirectoryCustomAttribute: {
    __typename: 'DirectoryCustomAttributeCreated';
    directoryCustomAttribute: {
      __typename?: 'DirectoryCustomAttribute';
      id: string;
      name: string;
    };
  };
};

export type CreateDirectoryMutationVariables = Exact<{
  organizationId: Scalars['String'];
  name: Scalars['String'];
  type: DirectoryType;
  domain?: InputMaybe<Scalars['String']>;
}>;

export type CreateDirectoryMutation = {
  __typename?: 'Mutation';
  createDirectory: { __typename?: 'Directory'; id: string };
};

export type CreateKeyMutationVariables = Exact<{
  input: CreateKeyInput;
}>;

export type CreateKeyMutation = {
  __typename?: 'Mutation';
  createKey: {
    __typename: 'KeyCreated';
    key: {
      __typename?: 'Key';
      value: string;
      id: string;
      createdAt: string;
      expiredAt?: string | null | undefined;
      name: string;
      displayValue: string;
    };
  };
};

export type CreateOAuthCredentialsMutationVariables = Exact<{
  input: CreateOauthCredentialsInput;
}>;

export type CreateOAuthCredentialsMutation = {
  __typename?: 'Mutation';
  createOauthCredentials: {
    __typename?: 'CreateOauthCredentialsPayload';
    oauthCredentials?:
      | {
          __typename?: 'OAuthCredential';
          id: string;
          state: OAuthCredentialState;
          redirect_uri?: string | null | undefined;
          client_id?: string | null | undefined;
          client_secret?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type CreateOrganizationMutationVariables = Exact<{
  allowProfilesOutsideOrganization: Scalars['Boolean'];
  domains?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  environmentId: Scalars['String'];
  name: Scalars['String'];
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: { __typename?: 'Organization'; id: string };
};

export type CreateRedirectUriMutationVariables = Exact<{
  environmentId: Scalars['String'];
  uri: Scalars['String'];
  isDefault: Scalars['Boolean'];
}>;

export type CreateRedirectUriMutation = {
  __typename?: 'Mutation';
  createRedirectUri: {
    __typename?: 'RedirectURI';
    id: string;
    uri: string;
    default: boolean;
  };
};

export type CreateWebhookEndpointMutationVariables = Exact<{
  endpointUrl: Scalars['String'];
  environmentId: Scalars['String'];
  events: Array<Scalars['String']> | Scalars['String'];
}>;

export type CreateWebhookEndpointMutation = {
  __typename?: 'Mutation';
  createWebhookEndpoint: { __typename?: 'WebhookEndpoint'; id: string };
};

export type DeleteAdpConnectionSslCertificateMutationVariables = Exact<{
  input: DeleteAdpConnectionSslCertificateInput;
}>;

export type DeleteAdpConnectionSslCertificateMutation = {
  __typename?: 'Mutation';
  deleteAdpConnectionSslCertificate:
    | {
        __typename: 'AdpConnectionSslCertificateDeleted';
        certificateId: string;
      }
    | {
        __typename: 'AdpConnectionSslCertificateNotFound';
        certificateId: string;
      };
};

export type DeleteConnectionMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteConnectionMutation = {
  __typename?: 'Mutation';
  deleteConnection: string;
};

export type DeleteDirectoryCustomAttributeMutationVariables = Exact<{
  input: DeleteDirectoryCustomAttributeInput;
}>;

export type DeleteDirectoryCustomAttributeMutation = {
  __typename?: 'Mutation';
  deleteDirectoryCustomAttribute:
    | {
        __typename: 'DirectoryCustomAttributeDeleted';
        directoryCustomAttribute: {
          __typename?: 'DirectoryCustomAttribute';
          id: string;
          name: string;
        };
      }
    | { __typename: 'DirectoryCustomAttributeNotFound' };
};

export type DeleteDirectoryMutationVariables = Exact<{
  input: DeleteDirectoryInput;
}>;

export type DeleteDirectoryMutation = {
  __typename?: 'Mutation';
  deleteDirectory: {
    __typename?: 'DeleteDirectoryPayload';
    directory?: { __typename?: 'Directory'; id: string } | null | undefined;
  };
};

export type DeleteOrganizationMutationVariables = Exact<{
  input: DeleteOrganizationInput;
}>;

export type DeleteOrganizationMutation = {
  __typename?: 'Mutation';
  deleteOrganization: {
    __typename?: 'DeleteOrganizationPayload';
    organization?:
      | { __typename?: 'Organization'; id: string }
      | null
      | undefined;
  };
};

export type DeleteRedirectUriMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteRedirectUriMutation = {
  __typename?: 'Mutation';
  deleteRedirectUri: string;
};

export type DeleteSamlX509CertificateMutationVariables = Exact<{
  input: DeleteSamlX509CertificateInput;
}>;

export type DeleteSamlX509CertificateMutation = {
  __typename?: 'Mutation';
  deleteSamlX509Certificate:
    | {
        __typename?: 'SamlX509CertificateDeleted';
        samlX509Certificate: { __typename?: 'SamlX509Certificate'; id: string };
      }
    | {
        __typename?: 'SamlX509CertificateNotFound';
        samlX509CertificateId: string;
      };
};

export type DeleteWebhookEndpointMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteWebhookEndpointMutation = {
  __typename?: 'Mutation';
  deleteWebhookEndpoint: string;
};

export type ExpireKeyMutationVariables = Exact<{
  input: ExpireKeyInput;
}>;

export type ExpireKeyMutation = {
  __typename?: 'Mutation';
  expireKey:
    | { __typename: 'KeyAlreadyExpired' }
    | {
        __typename: 'KeyExpired';
        expiredKey: {
          __typename?: 'Key';
          id: string;
          createdAt: string;
          expiredAt?: string | null | undefined;
          name: string;
          displayValue: string;
        };
        newKey?:
          | {
              __typename?: 'Key';
              value: string;
              id: string;
              createdAt: string;
              expiredAt?: string | null | undefined;
              name: string;
              displayValue: string;
            }
          | null
          | undefined;
      }
    | { __typename: 'KeyNotFound' };
};

export type Onboarding_CompleteStepMutationVariables = Exact<{
  input: OnboardingCompleteStepInput;
}>;

export type Onboarding_CompleteStepMutation = {
  __typename?: 'Mutation';
  onboarding_completeStep: {
    __typename?: 'OnboardingCompleteStepPayload';
    team?:
      | {
          __typename?: 'Team';
          id: string;
          name: string;
          billingDetails: {
            __typename?: 'TeamBillingDetails';
            email?: string | null | undefined;
            hasPaymentMethod: boolean;
          };
          onboarding:
            | { __typename: 'TeamOnboarded' }
            | { __typename: 'TeamOnboarding'; nextStep: string };
          ssoDemoUser?:
            | {
                __typename?: 'SSODemoUser';
                id: string;
                idp_id?: string | null | undefined;
                email?: string | null | undefined;
                password?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type Onboarding_EnterTeamDetailsMutationVariables = Exact<{
  input: OnboardingEnterTeamDetailsInput;
}>;

export type Onboarding_EnterTeamDetailsMutation = {
  __typename?: 'Mutation';
  onboarding_enterTeamDetails: {
    __typename?: 'EnterTeamDetailsPayload';
    user?: { __typename?: 'User'; id: string } | null | undefined;
    team?: { __typename?: 'Team'; id: string } | null | undefined;
  };
};

export type ProvisionTestAppAndUserMutationVariables = Exact<{
  input: ProvisionTestAppAndUserInput;
}>;

export type ProvisionTestAppAndUserMutation = {
  __typename?: 'Mutation';
  provisionTestAppAndUser: {
    __typename?: 'Team';
    id: string;
    name: string;
    billingDetails: {
      __typename?: 'TeamBillingDetails';
      email?: string | null | undefined;
      hasPaymentMethod: boolean;
    };
    onboarding:
      | { __typename: 'TeamOnboarded' }
      | { __typename: 'TeamOnboarding'; nextStep: string };
    ssoDemoUser?:
      | {
          __typename?: 'SSODemoUser';
          id: string;
          idp_id?: string | null | undefined;
          email?: string | null | undefined;
          password?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type RemoveUserFromTeamMutationVariables = Exact<{
  usersOrganizationsId: Scalars['String'];
}>;

export type RemoveUserFromTeamMutation = {
  __typename?: 'Mutation';
  removeUserFromTeam: string;
};

export type SendTestWebhookMutationVariables = Exact<{
  webhookEndpointId: Scalars['String'];
  event: WebhookEventFixtures;
}>;

export type SendTestWebhookMutation = {
  __typename?: 'Mutation';
  testWebhookEndpoint: {
    __typename?: 'Webhook';
    id: string;
    state: WebhookState;
    metadata: any;
    request_body?: any | null | undefined;
    response_body?: any | null | undefined;
  };
};

export type SetDirectoryCustomAttributeMappingsMutationVariables = Exact<{
  input: SetDirectoryCustomAttributeMappingsInput;
}>;

export type SetDirectoryCustomAttributeMappingsMutation = {
  __typename?: 'Mutation';
  setDirectoryCustomAttributeMappings:
    | {
        __typename: 'DirectoryCustomAttributeMappingsSet';
        mappings: Array<{
          __typename?: 'DirectoryCustomAttributeMapping';
          id: string;
          attribute: string;
          directory: { __typename?: 'Directory'; id: string };
          customAttribute: {
            __typename?: 'DirectoryCustomAttribute';
            id: string;
          };
        }>;
      }
    | {
        __typename: 'DirectoryCustomAttributeNotFound';
        directoryCustomAttributeId: string;
      }
    | { __typename: 'DirectoryNotFound'; directoryId: string };
};

export type SetDomainsForOrganizationMutationVariables = Exact<{
  input: SetDomainsForOrganizationInput;
}>;

export type SetDomainsForOrganizationMutation = {
  __typename?: 'Mutation';
  setDomainsForOrganization: {
    __typename?: 'SetDomainsForOrganizationPayload';
    organization?:
      | {
          __typename?: 'Organization';
          id: string;
          domains: Array<{
            __typename?: 'OrganizationDomain';
            id: string;
            domain: string;
          }>;
        }
      | null
      | undefined;
  };
};

export type SetEmailNotificationPreferencesMutationVariables = Exact<{
  input: SetEmailNotificationPreferencesInput;
}>;

export type SetEmailNotificationPreferencesMutation = {
  __typename?: 'Mutation';
  setEmailNotificationPreferences: {
    __typename: 'EmailNotificationPreferences';
    preferences: Array<{
      __typename?: 'EmailNotificationPreference';
      category: EmailNotificationCategory;
      preference: EmailNotificationPreferenceConfiguration;
    }>;
  };
};

export type SetRedirectUrisMutationVariables = Exact<{
  input: SetRedirectUrisInput;
}>;

export type SetRedirectUrisMutation = {
  __typename?: 'Mutation';
  setRedirectUris:
    | { __typename: 'InvalidRedirectUriProcotol'; message: string; uri: string }
    | {
        __typename: 'RedirectUrisSet';
        redirectUris: Array<{
          __typename?: 'RedirectURI';
          uri: string;
          isDefault: boolean;
          id: string;
        }>;
      };
};

export type UpdateAttributeMapMutationVariables = Exact<{
  id: Scalars['String'];
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAttributeMapMutation = {
  __typename?: 'Mutation';
  updateAttributeMap?:
    | {
        __typename?: 'AttributeMap';
        id: string;
        attributes: {
          __typename?: 'Attributes';
          idpId: string;
          email: string;
          firstName: string;
          lastName: string;
        };
      }
    | null
    | undefined;
};

export type UpdateConnectionFromMetadataUrlMutationVariables = Exact<{
  input: UpdateConnectionFromMetadataUrlInput;
}>;

export type UpdateConnectionFromMetadataUrlMutation = {
  __typename?: 'Mutation';
  updateConnectionFromMetadataUrl:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'ConnectionUpdatedFromMetadataUrl';
        connection: {
          __typename?: 'Connection';
          id: string;
          state: ConnectionState;
          name: string;
          type: ConnectionType;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          samlX509Certificates: Array<{
            __typename?: 'SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: string | null | undefined;
            notAfter?: string | null | undefined;
          }>;
        };
      }
    | { __typename: 'MetadataFetchFailed'; reason: string };
};

export type UpdateConnectionFromMetadataXmlMutationVariables = Exact<{
  input: UpdateConnectionFromMetadataXmlInput;
}>;

export type UpdateConnectionFromMetadataXmlMutation = {
  __typename?: 'Mutation';
  updateConnectionFromMetadataXml:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'ConnectionUpdatedFromMetadataXml';
        connection: {
          __typename?: 'Connection';
          id: string;
          state: ConnectionState;
          name: string;
          type: ConnectionType;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          samlX509Certificates: Array<{
            __typename?: 'SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: string | null | undefined;
            notAfter?: string | null | undefined;
          }>;
        };
      }
    | { __typename: 'MetadataParseFailed'; reason: string };
};

export type UpdateConnectionMutationVariables = Exact<{
  input: UpdateConnectionInput;
}>;

export type UpdateConnectionMutation = {
  __typename?: 'Mutation';
  updateConnection:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'ConnectionUpdated';
        connection: {
          __typename?: 'Connection';
          id: string;
          state: ConnectionState;
          name: string;
          type: ConnectionType;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          samlX509Certificates: Array<{
            __typename?: 'SamlX509Certificate';
            id: string;
            value: string;
          }>;
        };
      }
    | { __typename: 'InvalidSamlX509Certificate' };
};

export type UpdateDirectoryAttributeMapMutationVariables = Exact<{
  directory_attribute_map_id: Scalars['String'];
  emails_attribute?: InputMaybe<Scalars['String']>;
  external_id_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  group_name_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
  username_attribute?: InputMaybe<Scalars['String']>;
}>;

export type UpdateDirectoryAttributeMapMutation = {
  __typename?: 'Mutation';
  updateDirectoryAttributeMap?:
    | {
        __typename?: 'DirectoryAttributeMap';
        id: string;
        external_id_attribute?: string | null | undefined;
        username_attribute?: string | null | undefined;
        emails_attribute?: string | null | undefined;
        first_name_attribute?: string | null | undefined;
        last_name_attribute?: string | null | undefined;
        group_name_attribute?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateDirectoryCustomAttributeMutationVariables = Exact<{
  input: UpdateDirectoryCustomAttributeInput;
}>;

export type UpdateDirectoryCustomAttributeMutation = {
  __typename?: 'Mutation';
  updateDirectoryCustomAttribute:
    | { __typename: 'DirectoryCustomAttributeNotFound' }
    | {
        __typename: 'DirectoryCustomAttributeUpdated';
        directoryCustomAttribute: {
          __typename?: 'DirectoryCustomAttribute';
          id: string;
          name: string;
        };
      };
};

export type UpdateDirectoryMutationVariables = Exact<{
  input: UpdateDirectoryInput;
}>;

export type UpdateDirectoryMutation = {
  __typename?: 'Mutation';
  updateDirectory:
    | { __typename: 'DirectoryNotFound'; directoryId: string }
    | {
        __typename: 'DirectoryProviderErrors';
        errors: Array<{
          __typename?: 'DirectoryProviderError';
          message: string;
        }>;
      }
    | {
        __typename: 'DirectoryUpdated';
        directory: {
          __typename?: 'Directory';
          id: string;
          type: DirectoryType;
          name: string;
          endpoint?: string | null | undefined;
          bearer_token?: string | null | undefined;
          state: DirectoryState;
          setup_url?: string | null | undefined;
          organization?:
            | {
                __typename?: 'Organization';
                id: string;
                name: string;
                domains: Array<{
                  __typename?: 'OrganizationDomain';
                  id: string;
                  domain: string;
                }>;
              }
            | null
            | undefined;
          primaryDomain?:
            | { __typename?: 'OrganizationDomain'; domain: string }
            | null
            | undefined;
          provider?:
            | {
                __typename: 'BambooHrDirectory';
                subdomain?: string | null | undefined;
                apiKey?: string | null | undefined;
              }
            | {
                __typename: 'BreatheHrDirectory';
                apiKey?: string | null | undefined;
              }
            | {
                __typename: 'FourthHrDirectory';
                organizationId?: string | null | undefined;
                username?: string | null | undefined;
                password?: string | null | undefined;
              }
            | { __typename: 'GustoDirectory' }
            | {
                __typename: 'HibobDirectory';
                serviceUserId?: string | null | undefined;
                apiToken?: string | null | undefined;
              }
            | {
                __typename: 'RipplingDirectory';
                apiKey?: string | null | undefined;
              }
            | {
                __typename: 'WorkdayDirectory';
                userEndpoint?: string | null | undefined;
                groupEndpoint?: string | null | undefined;
                username?: string | null | undefined;
                password?: string | null | undefined;
              }
            | null
            | undefined;
          directoryAttributeMap?:
            | {
                __typename?: 'DirectoryAttributeMap';
                id: string;
                external_id_attribute?: string | null | undefined;
                username_attribute?: string | null | undefined;
                emails_attribute?: string | null | undefined;
                first_name_attribute?: string | null | undefined;
                last_name_attribute?: string | null | undefined;
                group_name_attribute?: string | null | undefined;
              }
            | null
            | undefined;
        };
      };
};

export type ChangeRoleMutationVariables = Exact<{
  usersOrganizationsId: Scalars['String'];
  role: UsersOrganizationsRole;
}>;

export type ChangeRoleMutation = {
  __typename?: 'Mutation';
  changeRole: {
    __typename?: 'UsersTeam';
    id: string;
    role: UsersOrganizationsRole;
  };
};

export type UpdateOAuthCredentialMutationVariables = Exact<{
  id: Scalars['String'];
  client_secret?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['String']>;
}>;

export type UpdateOAuthCredentialMutation = {
  __typename?: 'Mutation';
  updateOAuthCredential?:
    | {
        __typename?: 'OAuthCredential';
        id: string;
        state: OAuthCredentialState;
        redirect_uri?: string | null | undefined;
        client_id?: string | null | undefined;
        client_secret?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  allowProfilesOutsideOrganization: Scalars['Boolean'];
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'UpdateOrganizationPayload';
    organization?:
      | { __typename?: 'Organization'; id: string; name: string }
      | null
      | undefined;
  };
};

export type UpdatePortalSettingsMutationVariables = Exact<{
  id: Scalars['String'];
  defaultRedirectLink: Scalars['String'];
}>;

export type UpdatePortalSettingsMutation = {
  __typename?: 'Mutation';
  updatePortalSettings: {
    __typename?: 'PortalSettings';
    id: string;
    defaultRedirectLink: string;
  };
};

export type UpdateSessionMutationVariables = Exact<{
  currentEnvironmentId?: InputMaybe<Scalars['String']>;
  currentProjectID?: InputMaybe<Scalars['String']>;
}>;

export type UpdateSessionMutation = {
  __typename?: 'Mutation';
  updateSession?:
    | {
        __typename?: 'Session';
        currentEnvironment: {
          __typename?: 'Environment';
          id: string;
          name: string;
          logoUrl?: string | null | undefined;
          sandbox: boolean;
          clientId: string;
          projectV2: {
            __typename?: 'ProjectV2';
            id: string;
            name: string;
            environments: Array<{
              __typename?: 'Environment';
              id: string;
              name: string;
              logoUrl?: string | null | undefined;
              sandbox: boolean;
              clientId: string;
              portalSettings: {
                __typename?: 'PortalSettings';
                id: string;
                defaultRedirectLink: string;
              };
              ssoDemoApplication?:
                | {
                    __typename?: 'SSODemoApplication';
                    connectionType: ConnectionType;
                    id: string;
                    idpId?: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          portalSettings: {
            __typename?: 'PortalSettings';
            id: string;
            defaultRedirectLink: string;
          };
          ssoDemoApplication?:
            | {
                __typename?: 'SSODemoApplication';
                connectionType: ConnectionType;
                id: string;
                idpId?: string | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type UpdateWebhookEndpointMutationVariables = Exact<{
  webhookEndpointId: Scalars['String'];
  endpointUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<WebhookEndpointState>;
  events?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type UpdateWebhookEndpointMutation = {
  __typename?: 'Mutation';
  updateWebhookEndpoint: { __typename?: 'WebhookEndpoint'; id: string };
};

export type ApiKeysQueryVariables = Exact<{
  environmentId: Scalars['String'];
}>;

export type ApiKeysQuery = {
  __typename?: 'Query';
  activeKeys: {
    __typename?: 'KeyList';
    data: Array<{
      __typename?: 'Key';
      id: string;
      createdAt: string;
      expiredAt?: string | null | undefined;
      name: string;
      displayValue: string;
    }>;
  };
  expiredKeys: {
    __typename?: 'KeyList';
    data: Array<{
      __typename?: 'Key';
      id: string;
      createdAt: string;
      expiredAt?: string | null | undefined;
      name: string;
      displayValue: string;
    }>;
  };
};

export type AuditTrailEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AuditTrailEventQuery = {
  __typename?: 'Query';
  event: {
    __typename?: 'Event';
    id: string;
    group: string;
    location: string;
    type?: string | null | undefined;
    metadata?: any | null | undefined;
    occurredAt: string;
    action: { __typename?: 'EventAction'; name: string };
    actor: { __typename?: 'EventActor'; id: string; name: string };
    target: { __typename?: 'EventTarget'; id: string; name: string };
  };
};

export type AuditTrailEventsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type AuditTrailEventsQuery = {
  __typename?: 'Query';
  events: {
    __typename?: 'EventList';
    data: Array<{
      __typename?: 'Event';
      id: string;
      group: string;
      location: string;
      type?: string | null | undefined;
      metadata?: any | null | undefined;
      occurredAt: string;
      action: { __typename?: 'EventAction'; name: string };
      actor: { __typename?: 'EventActor'; id: string; name: string };
      target: { __typename?: 'EventTarget'; id: string; name: string };
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type ConnectionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ConnectionQuery = {
  __typename?: 'Query';
  connection: {
    __typename?: 'Connection';
    id: string;
    name: string;
    type: ConnectionType;
    oidc_client_id?: string | null | undefined;
    oidc_client_secret?: string | null | undefined;
    oidc_discovery_endpoint?: string | null | undefined;
    oidc_redirect_uri?: string | null | undefined;
    state: ConnectionState;
    saml_entity_id?: string | null | undefined;
    saml_sp_metadata_url: string;
    saml_acs_url: string;
    saml_idp_url?: string | null | undefined;
    saml_idp_metadata_url?: string | null | undefined;
    samlX509Certificates: Array<{
      __typename?: 'SamlX509Certificate';
      id: string;
      value: string;
      notBefore?: string | null | undefined;
      notAfter?: string | null | undefined;
    }>;
    adpConnectionSslCertificates: Array<{
      __typename?: 'AdpConnectionSslCertificate';
      createdAt: string;
      id: string;
      value: string;
    }>;
    attributeMap?:
      | {
          __typename?: 'AttributeMap';
          id: string;
          attributes: {
            __typename?: 'Attributes';
            idpId: string;
            email: string;
            firstName: string;
            lastName: string;
          };
        }
      | null
      | undefined;
    organization?:
      | { __typename?: 'Organization'; id: string; name: string }
      | null
      | undefined;
    organizationDomains: Array<{
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    }>;
  };
};

export type ConnectionsByTypeQueryVariables = Exact<{
  environmentId: Scalars['String'];
  type: ConnectionType;
}>;

export type ConnectionsByTypeQuery = {
  __typename?: 'Query';
  oauthCredentialsByType: {
    __typename?: 'OAuthCredentialList';
    data: Array<{
      __typename?: 'OAuthCredential';
      id: string;
      state: OAuthCredentialState;
      redirect_uri?: string | null | undefined;
      client_id?: string | null | undefined;
      client_secret?: string | null | undefined;
    }>;
  };
};

export type ConnectionsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
}>;

export type ConnectionsQuery = {
  __typename?: 'Query';
  connections: {
    __typename?: 'ConnectionList';
    data: Array<{
      __typename?: 'Connection';
      id: string;
      type: ConnectionType;
      state: ConnectionState;
      createdAt: string;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type DashboardSessionQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardSessionQuery = {
  __typename?: 'Query';
  currentEnvironment: {
    __typename?: 'Environment';
    id: string;
    name: string;
    logoUrl?: string | null | undefined;
    sandbox: boolean;
    clientId: string;
    projectV2: {
      __typename?: 'ProjectV2';
      id: string;
      name: string;
      environments: Array<{
        __typename?: 'Environment';
        id: string;
        name: string;
        logoUrl?: string | null | undefined;
        sandbox: boolean;
        clientId: string;
        portalSettings: {
          __typename?: 'PortalSettings';
          id: string;
          defaultRedirectLink: string;
        };
        ssoDemoApplication?:
          | {
              __typename?: 'SSODemoApplication';
              connectionType: ConnectionType;
              id: string;
              idpId?: string | null | undefined;
            }
          | null
          | undefined;
      }>;
    };
    portalSettings: {
      __typename?: 'PortalSettings';
      id: string;
      defaultRedirectLink: string;
    };
    ssoDemoApplication?:
      | {
          __typename?: 'SSODemoApplication';
          connectionType: ConnectionType;
          id: string;
          idpId?: string | null | undefined;
        }
      | null
      | undefined;
  };
  me: {
    __typename?: 'User';
    id: string;
    name?: string | null | undefined;
    email: string;
    users_teams: Array<{
      __typename?: 'UsersTeam';
      id: string;
      role: UsersOrganizationsRole;
      state: UsersOrganizationsState;
      user: {
        __typename?: 'User';
        id: string;
        name?: string | null | undefined;
        email: string;
      };
    }>;
  };
  currentTeam?:
    | {
        __typename?: 'Team';
        id: string;
        name: string;
        billingDetails: {
          __typename?: 'TeamBillingDetails';
          email?: string | null | undefined;
          hasPaymentMethod: boolean;
        };
        onboarding:
          | { __typename: 'TeamOnboarded' }
          | { __typename: 'TeamOnboarding'; nextStep: string };
        ssoDemoUser?:
          | {
              __typename?: 'SSODemoUser';
              id: string;
              idp_id?: string | null | undefined;
              email?: string | null | undefined;
              password?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DirectoriesQueryVariables = Exact<{
  organizationId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type DirectoriesQuery = {
  __typename?: 'Query';
  directories: {
    __typename?: 'DirectoryList';
    data: Array<{
      __typename?: 'Directory';
      id: string;
      type: DirectoryType;
      name: string;
      state: DirectoryState;
      createdAt: string;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type DirectoryCustomAttributeMappingsQueryVariables = Exact<{
  directoryId: Scalars['String'];
}>;

export type DirectoryCustomAttributeMappingsQuery = {
  __typename?: 'Query';
  directoryCustomAttributeMappings: {
    __typename?: 'DirectoryCustomAttributeMappingList';
    data: Array<{
      __typename?: 'DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttribute: {
        __typename?: 'DirectoryCustomAttribute';
        name: string;
      };
      directory: { __typename?: 'Directory'; id: string };
    }>;
  };
};

export type DirectoryCustomAttributesQueryVariables = Exact<{
  environmentId: Scalars['String'];
}>;

export type DirectoryCustomAttributesQuery = {
  __typename?: 'Query';
  directoryCustomAttributes: {
    __typename?: 'DirectoryCustomAttributeList';
    data: Array<{
      __typename?: 'DirectoryCustomAttribute';
      id: string;
      name: string;
    }>;
  };
};

export type DirectoryGroupsQueryVariables = Exact<{
  directoryId: Scalars['String'];
}>;

export type DirectoryGroupsQuery = {
  __typename?: 'Query';
  directory: {
    __typename?: 'Directory';
    directoryGroups: {
      __typename?: 'DirectoryGroupsList';
      data: Array<{
        __typename?: 'DirectoryGroup';
        id: string;
        name: string;
        summary: { __typename?: 'DirectoryGroupSummary'; memberCount: number };
      }>;
    };
  };
};

export type DirectorySummaryQueryVariables = Exact<{
  directoryId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
}>;

export type DirectorySummaryQuery = {
  __typename?: 'Query';
  directory: {
    __typename?: 'Directory';
    state: DirectoryState;
    directorySyncRuns: {
      __typename?: 'DirectorySyncRunsList';
      data: Array<{
        __typename?: 'DirectorySyncRun';
        id: string;
        state: DirectorySyncRunState;
        updatedAt: string;
        errors: Array<
          | {
              __typename: 'MalformedDirectoryGroupError';
              code: DirectorySyncRunErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedDirectoryUserError';
              code: DirectorySyncRunErrorCode;
              reason: string;
            }
        >;
      }>;
    };
    summary: {
      __typename?: 'DirectorySummary';
      userCount: number;
      groupCount: number;
    };
  };
};

export type DirectoryUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type DirectoryUserQuery = {
  __typename?: 'Query';
  directoryUser: {
    __typename?: 'DirectoryUser';
    json: any;
    id: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    username?: string | null | undefined;
    state: DirectoryUserState;
    directoryGroups: Array<{
      __typename?: 'DirectoryGroup';
      id: string;
      name: string;
    }>;
  };
};

export type DirectoryUsersQueryVariables = Exact<{
  directoryId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type DirectoryUsersQuery = {
  __typename?: 'Query';
  directory: {
    __typename?: 'Directory';
    directoryUsers: {
      __typename?: 'DirectoryUsersList';
      data: Array<{
        __typename?: 'DirectoryUser';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        username?: string | null | undefined;
        state: DirectoryUserState;
        directoryGroups: Array<{
          __typename?: 'DirectoryGroup';
          id: string;
          name: string;
        }>;
      }>;
      listMetadata: {
        __typename?: 'ListMetadata';
        before?: string | null | undefined;
        after?: string | null | undefined;
      };
    };
  };
};

export type DirectoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type DirectoryQuery = {
  __typename?: 'Query';
  directory: {
    __typename?: 'Directory';
    id: string;
    type: DirectoryType;
    name: string;
    endpoint?: string | null | undefined;
    bearer_token?: string | null | undefined;
    state: DirectoryState;
    setup_url?: string | null | undefined;
    organization?:
      | {
          __typename?: 'Organization';
          id: string;
          name: string;
          domains: Array<{
            __typename?: 'OrganizationDomain';
            id: string;
            domain: string;
          }>;
        }
      | null
      | undefined;
    primaryDomain?:
      | { __typename?: 'OrganizationDomain'; domain: string }
      | null
      | undefined;
    provider?:
      | {
          __typename: 'BambooHrDirectory';
          subdomain?: string | null | undefined;
          apiKey?: string | null | undefined;
        }
      | { __typename: 'BreatheHrDirectory'; apiKey?: string | null | undefined }
      | {
          __typename: 'FourthHrDirectory';
          organizationId?: string | null | undefined;
          username?: string | null | undefined;
          password?: string | null | undefined;
        }
      | { __typename: 'GustoDirectory' }
      | {
          __typename: 'HibobDirectory';
          serviceUserId?: string | null | undefined;
          apiToken?: string | null | undefined;
        }
      | { __typename: 'RipplingDirectory'; apiKey?: string | null | undefined }
      | {
          __typename: 'WorkdayDirectory';
          userEndpoint?: string | null | undefined;
          groupEndpoint?: string | null | undefined;
          username?: string | null | undefined;
          password?: string | null | undefined;
        }
      | null
      | undefined;
    directoryAttributeMap?:
      | {
          __typename?: 'DirectoryAttributeMap';
          id: string;
          external_id_attribute?: string | null | undefined;
          username_attribute?: string | null | undefined;
          emails_attribute?: string | null | undefined;
          first_name_attribute?: string | null | undefined;
          last_name_attribute?: string | null | undefined;
          group_name_attribute?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type EmailNotificationPreferencesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailNotificationPreferencesQuery = {
  __typename?: 'Query';
  emailNotificationPreferences: {
    __typename?: 'EmailNotificationPreferences';
    preferences: Array<{
      __typename?: 'EmailNotificationPreference';
      category: EmailNotificationCategory;
      preference: EmailNotificationPreferenceConfiguration;
    }>;
  };
};

export type EnvironmentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type EnvironmentQuery = {
  __typename?: 'Query';
  environment: {
    __typename?: 'Environment';
    id: string;
    name: string;
    logoUrl?: string | null | undefined;
    sandbox: boolean;
    clientId: string;
    portalSettings: {
      __typename?: 'PortalSettings';
      id: string;
      defaultRedirectLink: string;
    };
    ssoDemoApplication?:
      | {
          __typename?: 'SSODemoApplication';
          connectionType: ConnectionType;
          id: string;
          idpId?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type OrganizationWithDomainsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrganizationWithDomainsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    allowProfilesOutsideOrganization: boolean;
    domains: Array<{
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    }>;
  };
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    allowProfilesOutsideOrganization: boolean;
    domains: Array<{ __typename?: 'OrganizationDomain'; id: string }>;
  };
};

export type OrganizationsQueryVariables = Exact<{
  environmentId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationList';
    data: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      createdAt: string;
      domains: Array<{
        __typename?: 'OrganizationDomain';
        id: string;
        domain: string;
      }>;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type ProfileForIdpIdQueryVariables = Exact<{
  idp_id: Scalars['String'];
  project_id: Scalars['String'];
}>;

export type ProfileForIdpIdQuery = {
  __typename?: 'Query';
  profileForIdpId: {
    __typename?: 'Profile';
    id: string;
    lastLoginAt?: string | null | undefined;
  };
};

export type RedirectUrisQueryVariables = Exact<{
  environmentId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RedirectUrisQuery = {
  __typename?: 'Query';
  redirectUris: {
    __typename?: 'RedirectUriList';
    data: Array<{
      __typename?: 'RedirectURI';
      id: string;
      uri: string;
      isDefault: boolean;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type RequestQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type RequestQuery = {
  __typename?: 'Query';
  request: {
    __typename?: 'Request';
    date: string;
    id: string;
    method: string;
    path: string;
    status: number;
    source?: string | null | undefined;
    request_query?: any | null | undefined;
    request_body?: any | null | undefined;
    response_body?: any | null | undefined;
  };
};

export type RequestsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RequestsQuery = {
  __typename?: 'Query';
  requests: {
    __typename?: 'RequestList';
    data: Array<{
      __typename?: 'Request';
      date: string;
      id: string;
      method: string;
      path: string;
      status: number;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type UsersInCurrentTeamQueryVariables = Exact<{ [key: string]: never }>;

export type UsersInCurrentTeamQuery = {
  __typename?: 'Query';
  me: { __typename?: 'User'; id: string };
  currentTeam?:
    | {
        __typename?: 'Team';
        id: string;
        name: string;
        users_teams: Array<{
          __typename?: 'UsersTeam';
          id: string;
          role: UsersOrganizationsRole;
          state: UsersOrganizationsState;
          user: {
            __typename?: 'User';
            id: string;
            name?: string | null | undefined;
            email: string;
          };
        }>;
      }
    | null
    | undefined;
};

export type WebhookEndpointQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WebhookEndpointQuery = {
  __typename?: 'Query';
  webhookEndpoint: {
    __typename?: 'WebhookEndpoint';
    id: string;
    endpoint_url: string;
    state: WebhookEndpointState;
    secret: string;
    events: Array<string>;
  };
};

export type WebhookEndpointsQueryVariables = Exact<{
  environmentId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type WebhookEndpointsQuery = {
  __typename?: 'Query';
  webhookEndpoints: {
    __typename?: 'WebhookEndpointList';
    data: Array<{
      __typename?: 'WebhookEndpoint';
      id: string;
      endpointUrl: string;
      events: Array<string>;
      state: WebhookEndpointState;
      createdAt: string;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null | undefined;
      after?: string | null | undefined;
    };
  };
};

export type WebhookEventsWithFixturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WebhookEventsWithFixturesQuery = {
  __typename?: 'Query';
  webhookEvents: Array<{
    __typename?: 'WebhookEvent';
    id: string;
    description: string;
    fixtures: Array<{
      __typename?: 'WebhookFixture';
      name: string;
      payload: any;
    }>;
  }>;
};

export type WebhookEventsQueryVariables = Exact<{ [key: string]: never }>;

export type WebhookEventsQuery = {
  __typename?: 'Query';
  webhookEvents: Array<{
    __typename?: 'WebhookEvent';
    id: string;
    description: string;
  }>;
};

export type WebhookQueryVariables = Exact<{
  webhookId: Scalars['String'];
}>;

export type WebhookQuery = {
  __typename?: 'Query';
  webhook: {
    __typename?: 'Webhook';
    id: string;
    webhook_endpoint_id: string;
    event: string;
    state: WebhookState;
    attempt: number;
    deliver_after?: string | null | undefined;
    metadata: any;
    request_body?: any | null | undefined;
    response_body?: any | null | undefined;
  };
};

export type WebhooksForEndpointQueryVariables = Exact<{
  webhookEndpointId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type WebhooksForEndpointQuery = {
  __typename?: 'Query';
  webhookEndpoint: {
    __typename?: 'WebhookEndpoint';
    webhooks: {
      __typename?: 'WebhookList';
      data: Array<{
        __typename?: 'Webhook';
        id: string;
        webhook_endpoint_id: string;
        event: string;
        state: WebhookState;
        attempt: number;
        deliver_after?: string | null | undefined;
      }>;
      listMetadata: {
        __typename?: 'ListMetadata';
        before?: string | null | undefined;
        after?: string | null | undefined;
      };
    };
  };
};

export const AuditTrailEventFragmentDoc = gql`
  fragment AuditTrailEvent on Event {
    id
    group
    location
    type
    action {
      name
    }
    actor {
      id
      name
    }
    target {
      id
      name
    }
    metadata
    occurredAt
  }
`;
export const AuthedUserFragmentDoc = gql`
  fragment AuthedUser on User {
    id
    name
    email
    users_teams {
      id
      role
      state
      user {
        id
        name
        email
      }
    }
  }
`;
export const SamlX509CertificateFragmentDoc = gql`
  fragment SamlX509Certificate on SamlX509Certificate {
    id
    value
    notBefore
    notAfter
  }
`;
export const ConnectionAttributeMapFragmentDoc = gql`
  fragment ConnectionAttributeMap on AttributeMap {
    id
    attributes {
      idpId
      email
      firstName
      lastName
    }
  }
`;
export const OrganizationDomainFragmentDoc = gql`
  fragment OrganizationDomain on OrganizationDomain {
    id
    domain
  }
`;
export const ConnectionFragmentDoc = gql`
  fragment Connection on Connection {
    id
    name
    type
    oidc_client_id
    oidc_client_secret
    oidc_discovery_endpoint
    oidc_redirect_uri
    state
    saml_entity_id
    saml_sp_metadata_url
    saml_acs_url
    saml_idp_url
    saml_idp_metadata_url
    samlX509Certificates {
      ...SamlX509Certificate
    }
    adpConnectionSslCertificates {
      createdAt
      id
      value
    }
    attributeMap {
      ...ConnectionAttributeMap
    }
    organization {
      id
      name
    }
    organizationDomains {
      ...OrganizationDomain
    }
  }
  ${SamlX509CertificateFragmentDoc}
  ${ConnectionAttributeMapFragmentDoc}
  ${OrganizationDomainFragmentDoc}
`;
export const CurrentTeamFragmentDoc = gql`
  fragment CurrentTeam on Team {
    id
    name
    billingDetails {
      email
      hasPaymentMethod
    }
    onboarding {
      __typename
      ... on TeamOnboarding {
        nextStep
      }
    }
    ssoDemoUser {
      id
      idp_id
      email
      password
    }
  }
`;
export const DirectorySyncRunFragmentDoc = gql`
  fragment DirectorySyncRun on DirectorySyncRun {
    id
    state
    updatedAt
    errors {
      __typename
      ... on MalformedDirectoryUserError {
        code
        reason
      }
      ... on MalformedDirectoryGroupError {
        code
        reason
      }
    }
  }
`;
export const DirectoryUserFragmentDoc = gql`
  fragment DirectoryUser on DirectoryUser {
    id
    firstName
    lastName
    username
    directoryGroups {
      id
      name
    }
    state
  }
`;
export const BambooHrDirectoryFragmentDoc = gql`
  fragment BambooHrDirectory on BambooHrDirectory {
    subdomain
    apiKey
  }
`;
export const BreatheHrDirectoryFragmentDoc = gql`
  fragment BreatheHrDirectory on BreatheHrDirectory {
    apiKey
  }
`;
export const FourthHrDirectoryFragmentDoc = gql`
  fragment FourthHrDirectory on FourthHrDirectory {
    organizationId
    username
    password
  }
`;
export const HibobDirectoryFragmentDoc = gql`
  fragment HibobDirectory on HibobDirectory {
    serviceUserId
    apiToken
  }
`;
export const RipplingDirectoryFragmentDoc = gql`
  fragment RipplingDirectory on RipplingDirectory {
    apiKey
  }
`;
export const WorkdayDirectoryFragmentDoc = gql`
  fragment WorkdayDirectory on WorkdayDirectory {
    userEndpoint
    groupEndpoint
    username
    password
  }
`;
export const DirectoryAttributeMapFragmentDoc = gql`
  fragment DirectoryAttributeMap on DirectoryAttributeMap {
    id
    external_id_attribute
    username_attribute
    emails_attribute
    first_name_attribute
    last_name_attribute
    group_name_attribute
  }
`;
export const DirectoryFragmentDoc = gql`
  fragment Directory on Directory {
    id
    type
    name
    endpoint
    bearer_token
    state
    setup_url
    organization {
      id
      name
      domains {
        ...OrganizationDomain
      }
    }
    primaryDomain {
      domain
    }
    provider {
      __typename
      ... on BambooHrDirectory {
        ...BambooHrDirectory
      }
      ... on BreatheHrDirectory {
        ...BreatheHrDirectory
      }
      ... on FourthHrDirectory {
        ...FourthHrDirectory
      }
      ... on HibobDirectory {
        ...HibobDirectory
      }
      ... on RipplingDirectory {
        ...RipplingDirectory
      }
      ... on WorkdayDirectory {
        ...WorkdayDirectory
      }
    }
    directoryAttributeMap {
      ...DirectoryAttributeMap
    }
  }
  ${OrganizationDomainFragmentDoc}
  ${BambooHrDirectoryFragmentDoc}
  ${BreatheHrDirectoryFragmentDoc}
  ${FourthHrDirectoryFragmentDoc}
  ${HibobDirectoryFragmentDoc}
  ${RipplingDirectoryFragmentDoc}
  ${WorkdayDirectoryFragmentDoc}
  ${DirectoryAttributeMapFragmentDoc}
`;
export const EnvironmentFragmentDoc = gql`
  fragment Environment on Environment {
    id
    name
    logoUrl
    sandbox
    clientId
    portalSettings {
      id
      defaultRedirectLink
    }
    ssoDemoApplication {
      connectionType
      id
      idpId
    }
  }
`;
export const EnvironmentWithProjectV2FragmentDoc = gql`
  fragment EnvironmentWithProjectV2 on Environment {
    ...Environment
    projectV2 {
      id
      name
      environments {
        ...Environment
      }
    }
  }
  ${EnvironmentFragmentDoc}
`;
export const KeyFragmentDoc = gql`
  fragment Key on Key {
    id
    createdAt
    expiredAt
    name
    displayValue
  }
`;
export const NewlyCreatedKeyFragmentDoc = gql`
  fragment NewlyCreatedKey on Key {
    ...Key
    value
  }
  ${KeyFragmentDoc}
`;
export const OAuthCredentialFragmentDoc = gql`
  fragment OAuthCredential on OAuthCredential {
    id
    state
    redirect_uri
    client_id
    client_secret
  }
`;
export const ProjectV2FragmentDoc = gql`
  fragment ProjectV2 on ProjectV2 {
    id
    name
    environments {
      ...Environment
    }
  }
  ${EnvironmentFragmentDoc}
`;
export const RedirectUriFragmentDoc = gql`
  fragment RedirectUri on RedirectURI {
    id
    uri
    isDefault
  }
`;
export const WebhookEndpointFragmentDoc = gql`
  fragment WebhookEndpoint on WebhookEndpoint {
    id
    endpoint_url
    state
    secret
    events
  }
`;
export const WebhookEventWithFixtureFragmentDoc = gql`
  fragment WebhookEventWithFixture on WebhookEvent {
    id
    description
    fixtures {
      name
      payload
    }
  }
`;
export const AddAdpConnectionSslCertificateDocument = gql`
  mutation addAdpConnectionSslCertificate(
    $input: AddAdpConnectionSslCertificateInput!
  ) {
    addAdpConnectionSslCertificate(input: $input) {
      __typename
      ... on AdpConnectionSslCertificateAdded {
        adpConnectionSslCertificate {
          id
          value
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
    }
  }
`;
export const AddUserToTeamDocument = gql`
  mutation addUserToTeam(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: UsersOrganizationsRole!
  ) {
    addUserToTeam(
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
    ) {
      id
      role
      state
      user {
        id
        email
        name
      }
    }
  }
`;
export const AddPaymentMethodDocument = gql`
  mutation addPaymentMethod(
    $billingEmail: String!
    $stripePaymentMethodId: String!
  ) {
    addPaymentMethod(
      billingEmail: $billingEmail
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      team {
        ...CurrentTeam
      }
    }
  }
  ${CurrentTeamFragmentDoc}
`;
export const AddUsersToTeamDocument = gql`
  mutation addUsersToTeam($input: AddUsersToTeamInput!) {
    addUsersToTeam(input: $input) {
      invitedMembers {
        id
        role
      }
    }
  }
`;
export const CreateBillingPortalSessionDocument = gql`
  mutation createBillingPortalSession($returnUrl: String!) {
    createBillingPortalSession(returnUrl: $returnUrl) {
      billingPortalSessionUrl
    }
  }
`;
export const CreateConnectionDocument = gql`
  mutation createConnection($input: CreateConnectionInput!) {
    createConnection(input: $input) {
      __typename
      ... on ConnectionCreated {
        connection {
          id
          name
          type
        }
      }
      ... on OrganizationNotFound {
        organizationId
      }
    }
  }
`;
export const CreateDirectoryCustomAttributeDocument = gql`
  mutation createDirectoryCustomAttribute(
    $input: CreateDirectoryCustomAttributeInput!
  ) {
    createDirectoryCustomAttribute(input: $input) {
      __typename
      ... on DirectoryCustomAttributeCreated {
        directoryCustomAttribute {
          id
          name
        }
      }
    }
  }
`;
export const CreateDirectoryDocument = gql`
  mutation createDirectory(
    $organizationId: String!
    $name: String!
    $type: DirectoryType!
    $domain: String
  ) {
    createDirectory(
      organization_id: $organizationId
      name: $name
      type: $type
      domain: $domain
    ) {
      id
    }
  }
`;
export const CreateKeyDocument = gql`
  mutation createKey($input: CreateKeyInput!) {
    createKey(input: $input) {
      __typename
      ... on KeyCreated {
        key {
          ...NewlyCreatedKey
        }
      }
    }
  }
  ${NewlyCreatedKeyFragmentDoc}
`;
export const CreateOAuthCredentialsDocument = gql`
  mutation createOAuthCredentials($input: CreateOauthCredentialsInput!) {
    createOauthCredentials(input: $input) {
      oauthCredentials {
        ...OAuthCredential
      }
    }
  }
  ${OAuthCredentialFragmentDoc}
`;
export const CreateOrganizationDocument = gql`
  mutation createOrganization(
    $allowProfilesOutsideOrganization: Boolean!
    $domains: [String!]
    $environmentId: String!
    $name: String!
  ) {
    createOrganization(
      allowProfilesOutsideOrganization: $allowProfilesOutsideOrganization
      domains: $domains
      environmentId: $environmentId
      name: $name
    ) {
      id
    }
  }
`;
export const CreateRedirectUriDocument = gql`
  mutation createRedirectUri(
    $environmentId: String!
    $uri: String!
    $isDefault: Boolean!
  ) {
    createRedirectUri(
      environmentId: $environmentId
      uri: $uri
      isDefault: $isDefault
    ) {
      id
      uri
      default
    }
  }
`;
export const CreateWebhookEndpointDocument = gql`
  mutation createWebhookEndpoint(
    $endpointUrl: String!
    $environmentId: String!
    $events: [String!]!
  ) {
    createWebhookEndpoint(
      endpointUrl: $endpointUrl
      environmentId: $environmentId
      events: $events
    ) {
      id
    }
  }
`;
export const DeleteAdpConnectionSslCertificateDocument = gql`
  mutation deleteAdpConnectionSslCertificate(
    $input: DeleteAdpConnectionSslCertificateInput!
  ) {
    deleteAdpConnectionSslCertificate(input: $input) {
      __typename
      ... on AdpConnectionSslCertificateDeleted {
        certificateId
      }
      ... on AdpConnectionSslCertificateNotFound {
        certificateId
      }
    }
  }
`;
export const DeleteConnectionDocument = gql`
  mutation deleteConnection($id: String!) {
    deleteConnection(id: $id)
  }
`;
export const DeleteDirectoryCustomAttributeDocument = gql`
  mutation deleteDirectoryCustomAttribute(
    $input: DeleteDirectoryCustomAttributeInput!
  ) {
    deleteDirectoryCustomAttribute(input: $input) {
      __typename
      ... on DirectoryCustomAttributeDeleted {
        directoryCustomAttribute {
          id
          name
        }
      }
    }
  }
`;
export const DeleteDirectoryDocument = gql`
  mutation deleteDirectory($input: DeleteDirectoryInput!) {
    deleteDirectory(input: $input) {
      directory {
        id
      }
    }
  }
`;
export const DeleteOrganizationDocument = gql`
  mutation deleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;
export const DeleteRedirectUriDocument = gql`
  mutation deleteRedirectUri($id: String!) {
    deleteRedirectUri(id: $id)
  }
`;
export const DeleteSamlX509CertificateDocument = gql`
  mutation deleteSamlX509Certificate($input: DeleteSamlX509CertificateInput!) {
    deleteSamlX509Certificate(input: $input) {
      ... on SamlX509CertificateDeleted {
        samlX509Certificate {
          id
        }
      }
      ... on SamlX509CertificateNotFound {
        samlX509CertificateId
      }
    }
  }
`;
export const DeleteWebhookEndpointDocument = gql`
  mutation deleteWebhookEndpoint($id: String!) {
    deleteWebhookEndpoint(id: $id)
  }
`;
export const ExpireKeyDocument = gql`
  mutation expireKey($input: ExpireKeyInput!) {
    expireKey(input: $input) {
      __typename
      ... on KeyExpired {
        expiredKey {
          ...Key
        }
        newKey {
          ...NewlyCreatedKey
        }
      }
    }
  }
  ${KeyFragmentDoc}
  ${NewlyCreatedKeyFragmentDoc}
`;
export const Onboarding_CompleteStepDocument = gql`
  mutation onboarding_completeStep($input: OnboardingCompleteStepInput!) {
    onboarding_completeStep(input: $input) {
      team {
        ...CurrentTeam
      }
    }
  }
  ${CurrentTeamFragmentDoc}
`;
export const Onboarding_EnterTeamDetailsDocument = gql`
  mutation onboarding_enterTeamDetails(
    $input: OnboardingEnterTeamDetailsInput!
  ) {
    onboarding_enterTeamDetails(input: $input) {
      user {
        id
      }
      team {
        id
      }
    }
  }
`;
export const ProvisionTestAppAndUserDocument = gql`
  mutation provisionTestAppAndUser($input: ProvisionTestAppAndUserInput!) {
    provisionTestAppAndUser(input: $input) {
      ...CurrentTeam
    }
  }
  ${CurrentTeamFragmentDoc}
`;
export const RemoveUserFromTeamDocument = gql`
  mutation removeUserFromTeam($usersOrganizationsId: String!) {
    removeUserFromTeam(usersOrganizationsId: $usersOrganizationsId)
  }
`;
export const SendTestWebhookDocument = gql`
  mutation sendTestWebhook(
    $webhookEndpointId: String!
    $event: WebhookEventFixtures!
  ) {
    testWebhookEndpoint(id: $webhookEndpointId, event: $event) {
      id
      state
      metadata
      request_body
      response_body
    }
  }
`;
export const SetDirectoryCustomAttributeMappingsDocument = gql`
  mutation setDirectoryCustomAttributeMappings(
    $input: SetDirectoryCustomAttributeMappingsInput!
  ) {
    setDirectoryCustomAttributeMappings(input: $input) {
      __typename
      ... on DirectoryCustomAttributeMappingsSet {
        mappings {
          id
          attribute
          directory {
            id
          }
          customAttribute {
            id
          }
        }
      }
      ... on DirectoryNotFound {
        directoryId
      }
      ... on DirectoryCustomAttributeNotFound {
        directoryCustomAttributeId
      }
    }
  }
`;
export const SetDomainsForOrganizationDocument = gql`
  mutation setDomainsForOrganization($input: SetDomainsForOrganizationInput!) {
    setDomainsForOrganization(input: $input) {
      organization {
        id
        domains {
          id
          domain
        }
      }
    }
  }
`;
export const SetEmailNotificationPreferencesDocument = gql`
  mutation setEmailNotificationPreferences(
    $input: SetEmailNotificationPreferencesInput!
  ) {
    setEmailNotificationPreferences(input: $input) {
      __typename
      ... on EmailNotificationPreferences {
        preferences {
          category
          preference
        }
      }
    }
  }
`;
export const SetRedirectUrisDocument = gql`
  mutation setRedirectUris($input: SetRedirectUrisInput!) {
    setRedirectUris(input: $input) {
      __typename
      ... on RedirectUrisSet {
        redirectUris {
          uri
          isDefault
          id
        }
      }
      ... on InvalidRedirectUriProcotol {
        message
        uri
      }
    }
  }
`;
export const UpdateAttributeMapDocument = gql`
  mutation updateAttributeMap(
    $id: String!
    $idp_id_attribute: String
    $email_attribute: String
    $first_name_attribute: String
    $last_name_attribute: String
  ) {
    updateAttributeMap(
      attribute_map_id: $id
      idp_id_attribute: $idp_id_attribute
      email_attribute: $email_attribute
      first_name_attribute: $first_name_attribute
      last_name_attribute: $last_name_attribute
    ) {
      ...ConnectionAttributeMap
    }
  }
  ${ConnectionAttributeMapFragmentDoc}
`;
export const UpdateConnectionFromMetadataUrlDocument = gql`
  mutation updateConnectionFromMetadataUrl(
    $input: UpdateConnectionFromMetadataUrlInput!
  ) {
    updateConnectionFromMetadataUrl(input: $input) {
      __typename
      ... on ConnectionUpdatedFromMetadataUrl {
        connection {
          id
          state
          name
          type
          oidc_client_id
          oidc_client_secret
          oidc_discovery_endpoint
          saml_acs_url
          saml_entity_id
          saml_idp_url
          saml_idp_metadata_url
          samlX509Certificates {
            ...SamlX509Certificate
          }
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on MetadataFetchFailed {
        reason
      }
    }
  }
  ${SamlX509CertificateFragmentDoc}
`;
export const UpdateConnectionFromMetadataXmlDocument = gql`
  mutation updateConnectionFromMetadataXml(
    $input: UpdateConnectionFromMetadataXmlInput!
  ) {
    updateConnectionFromMetadataXml(input: $input) {
      __typename
      ... on ConnectionUpdatedFromMetadataXml {
        connection {
          id
          state
          name
          type
          oidc_client_id
          oidc_client_secret
          oidc_discovery_endpoint
          saml_acs_url
          saml_entity_id
          saml_idp_url
          saml_idp_metadata_url
          samlX509Certificates {
            ...SamlX509Certificate
          }
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on MetadataParseFailed {
        reason
      }
    }
  }
  ${SamlX509CertificateFragmentDoc}
`;
export const UpdateConnectionDocument = gql`
  mutation updateConnection($input: UpdateConnectionInput!) {
    updateConnection(input: $input) {
      __typename
      ... on ConnectionUpdated {
        connection {
          id
          state
          name
          type
          oidc_client_id
          oidc_client_secret
          oidc_discovery_endpoint
          saml_acs_url
          saml_entity_id
          saml_idp_url
          samlX509Certificates {
            id
            value
          }
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
    }
  }
`;
export const UpdateDirectoryAttributeMapDocument = gql`
  mutation updateDirectoryAttributeMap(
    $directory_attribute_map_id: String!
    $emails_attribute: String
    $external_id_attribute: String
    $first_name_attribute: String
    $group_name_attribute: String
    $last_name_attribute: String
    $username_attribute: String
  ) {
    updateDirectoryAttributeMap(
      directory_attribute_map_id: $directory_attribute_map_id
      emails_attribute: $emails_attribute
      external_id_attribute: $external_id_attribute
      first_name_attribute: $first_name_attribute
      group_name_attribute: $group_name_attribute
      last_name_attribute: $last_name_attribute
      username_attribute: $username_attribute
    ) {
      ...DirectoryAttributeMap
    }
  }
  ${DirectoryAttributeMapFragmentDoc}
`;
export const UpdateDirectoryCustomAttributeDocument = gql`
  mutation updateDirectoryCustomAttribute(
    $input: UpdateDirectoryCustomAttributeInput!
  ) {
    updateDirectoryCustomAttribute(input: $input) {
      __typename
      ... on DirectoryCustomAttributeUpdated {
        directoryCustomAttribute {
          id
          name
        }
      }
    }
  }
`;
export const UpdateDirectoryDocument = gql`
  mutation updateDirectory($input: UpdateDirectoryInput!) {
    updateDirectory(input: $input) {
      __typename
      ... on DirectoryUpdated {
        directory {
          ...Directory
        }
      }
      ... on DirectoryNotFound {
        directoryId
      }
      ... on DirectoryProviderErrors {
        errors {
          message
        }
      }
    }
  }
  ${DirectoryFragmentDoc}
`;
export const ChangeRoleDocument = gql`
  mutation changeRole(
    $usersOrganizationsId: String!
    $role: UsersOrganizationsRole!
  ) {
    changeRole(usersOrganizationsId: $usersOrganizationsId, role: $role) {
      id
      role
    }
  }
`;
export const UpdateOAuthCredentialDocument = gql`
  mutation updateOAuthCredential(
    $id: String!
    $client_secret: String
    $client_id: String
  ) {
    updateOAuthCredential(
      oauth_credential_id: $id
      client_secret: $client_secret
      client_id: $client_id
    ) {
      id
      state
      redirect_uri
      client_id
      client_secret
    }
  }
`;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization(
    $id: ID!
    $name: String!
    $allowProfilesOutsideOrganization: Boolean!
  ) {
    updateOrganization(
      id: $id
      name: $name
      allowProfilesOutsideOrganization: $allowProfilesOutsideOrganization
    ) {
      organization {
        id
        name
      }
    }
  }
`;
export const UpdatePortalSettingsDocument = gql`
  mutation updatePortalSettings($id: String!, $defaultRedirectLink: String!) {
    updatePortalSettings(
      portal_settings_id: $id
      default_redirect_link: $defaultRedirectLink
    ) {
      id
      defaultRedirectLink
    }
  }
`;
export const UpdateSessionDocument = gql`
  mutation updateSession(
    $currentEnvironmentId: String
    $currentProjectID: String
  ) {
    updateSession(
      session: {
        currentEnvironmentId: $currentEnvironmentId
        currentProjectID: $currentProjectID
      }
    ) {
      currentEnvironment {
        ...EnvironmentWithProjectV2
      }
    }
  }
  ${EnvironmentWithProjectV2FragmentDoc}
`;
export const UpdateWebhookEndpointDocument = gql`
  mutation updateWebhookEndpoint(
    $webhookEndpointId: String!
    $endpointUrl: String
    $state: WebhookEndpointState
    $events: [String!]
  ) {
    updateWebhookEndpoint(
      webhookEndpointId: $webhookEndpointId
      endpointUrl: $endpointUrl
      state: $state
      events: $events
    ) {
      id
    }
  }
`;
export const ApiKeysDocument = gql`
  query apiKeys($environmentId: String!) {
    activeKeys: keys(environmentId: $environmentId, scope: Active) {
      data {
        ...Key
      }
    }
    expiredKeys: keys(environmentId: $environmentId, scope: RecentlyExpired) {
      data {
        ...Key
      }
    }
  }
  ${KeyFragmentDoc}
`;
export const AuditTrailEventDocument = gql`
  query auditTrailEvent($id: String!) {
    event(id: $id) {
      ...AuditTrailEvent
    }
  }
  ${AuditTrailEventFragmentDoc}
`;
export const AuditTrailEventsDocument = gql`
  query auditTrailEvents(
    $after: String
    $before: String
    $environmentId: String!
    $limit: Int
    $search: String
  ) {
    events(
      after: $after
      before: $before
      environmentId: $environmentId
      limit: $limit
      search: $search
    ) {
      data {
        ...AuditTrailEvent
      }
      listMetadata {
        before
        after
      }
    }
  }
  ${AuditTrailEventFragmentDoc}
`;
export const ConnectionDocument = gql`
  query connection($id: String!) {
    connection(id: $id) {
      ...Connection
    }
  }
  ${ConnectionFragmentDoc}
`;
export const ConnectionsByTypeDocument = gql`
  query connectionsByType($environmentId: String!, $type: ConnectionType!) {
    oauthCredentialsByType(environmentId: $environmentId, type: $type) {
      data {
        ...OAuthCredential
      }
    }
  }
  ${OAuthCredentialFragmentDoc}
`;
export const ConnectionsDocument = gql`
  query connections(
    $before: String
    $after: String
    $limit: Int
    $search: String
    $organizationId: String!
  ) {
    connections(
      before: $before
      after: $after
      limit: $limit
      search: $search
      organizationId: $organizationId
    ) {
      data {
        id
        type
        state
        createdAt
      }
      listMetadata {
        before
        after
      }
    }
  }
`;
export const DashboardSessionDocument = gql`
  query dashboardSession {
    currentEnvironment {
      ...EnvironmentWithProjectV2
    }
    me {
      ...AuthedUser
    }
    currentTeam {
      ...CurrentTeam
    }
  }
  ${EnvironmentWithProjectV2FragmentDoc}
  ${AuthedUserFragmentDoc}
  ${CurrentTeamFragmentDoc}
`;
export const DirectoriesDocument = gql`
  query directories(
    $organizationId: String!
    $before: String
    $after: String
    $limit: Int
    $search: String
  ) {
    directories(
      organizationId: $organizationId
      before: $before
      after: $after
      limit: $limit
      search: $search
    ) {
      data {
        id
        type
        name
        state
        createdAt
      }
      listMetadata {
        before
        after
      }
    }
  }
`;
export const DirectoryCustomAttributeMappingsDocument = gql`
  query directoryCustomAttributeMappings($directoryId: String!) {
    directoryCustomAttributeMappings(directoryId: $directoryId) {
      data {
        id
        attribute
        customAttribute {
          name
        }
        directory {
          id
        }
      }
    }
  }
`;
export const DirectoryCustomAttributesDocument = gql`
  query directoryCustomAttributes($environmentId: String!) {
    directoryCustomAttributes(environmentId: $environmentId) {
      data {
        id
        name
      }
    }
  }
`;
export const DirectoryGroupsDocument = gql`
  query directoryGroups($directoryId: String!) {
    directory(id: $directoryId) {
      directoryGroups {
        data {
          id
          name
          summary {
            memberCount
          }
        }
      }
    }
  }
`;
export const DirectorySummaryDocument = gql`
  query directorySummary(
    $directoryId: String!
    $limit: Int = 1
    $state: DirectorySyncRunState
  ) {
    directory(id: $directoryId) {
      state
      directorySyncRuns(limit: $limit, state: $state) {
        data {
          ...DirectorySyncRun
        }
      }
      summary {
        userCount
        groupCount
      }
    }
  }
  ${DirectorySyncRunFragmentDoc}
`;
export const DirectoryUserDocument = gql`
  query directoryUser($id: String!) {
    directoryUser(id: $id) {
      ...DirectoryUser
      json
    }
  }
  ${DirectoryUserFragmentDoc}
`;
export const DirectoryUsersDocument = gql`
  query directoryUsers(
    $directoryId: String!
    $before: String
    $after: String
    $limit: Int
    $search: String
  ) {
    directory(id: $directoryId) {
      directoryUsers(
        before: $before
        after: $after
        limit: $limit
        search: $search
      ) {
        data {
          ...DirectoryUser
        }
        listMetadata {
          before
          after
        }
      }
    }
  }
  ${DirectoryUserFragmentDoc}
`;
export const DirectoryDocument = gql`
  query directory($id: String!) {
    directory(id: $id) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;
export const EmailNotificationPreferencesDocument = gql`
  query emailNotificationPreferences {
    emailNotificationPreferences {
      preferences {
        category
        preference
      }
    }
  }
`;
export const EnvironmentDocument = gql`
  query environment($id: String!) {
    environment(id: $id) {
      ...Environment
    }
  }
  ${EnvironmentFragmentDoc}
`;
export const OrganizationWithDomainsDocument = gql`
  query organizationWithDomains($id: String!) {
    organization(id: $id) {
      id
      name
      allowProfilesOutsideOrganization
      domains {
        ...OrganizationDomain
      }
    }
  }
  ${OrganizationDomainFragmentDoc}
`;
export const OrganizationDocument = gql`
  query organization($id: String!) {
    organization(id: $id) {
      id
      name
      allowProfilesOutsideOrganization
      domains {
        id
      }
    }
  }
`;
export const OrganizationsDocument = gql`
  query organizations(
    $environmentId: String!
    $before: String
    $after: String
    $limit: Int
    $search: String
  ) {
    organizations(
      environmentId: $environmentId
      before: $before
      after: $after
      limit: $limit
      search: $search
    ) {
      data {
        id
        name
        createdAt
        domains {
          id
          domain
        }
      }
      listMetadata {
        before
        after
      }
    }
  }
`;
export const ProfileForIdpIdDocument = gql`
  query profileForIdpId($idp_id: String!, $project_id: String!) {
    profileForIdpId(idp_id: $idp_id, project_id: $project_id) {
      id
      lastLoginAt
    }
  }
`;
export const RedirectUrisDocument = gql`
  query redirectUris(
    $environmentId: String!
    $before: String
    $after: String
    $limit: Int = 100
  ) {
    redirectUris(
      environmentId: $environmentId
      before: $before
      after: $after
      limit: $limit
    ) {
      data {
        ...RedirectUri
      }
      listMetadata {
        before
        after
      }
    }
  }
  ${RedirectUriFragmentDoc}
`;
export const RequestDocument = gql`
  query request($id: String!) {
    request(id: $id) {
      date
      id
      method
      path
      status
      source
      request_query
      request_body
      response_body
    }
  }
`;
export const RequestsDocument = gql`
  query requests(
    $before: String
    $after: String
    $environmentId: String!
    $search: String
    $limit: Int
  ) {
    requests(
      before: $before
      after: $after
      environmentId: $environmentId
      search: $search
      limit: $limit
    ) {
      data {
        date
        id
        method
        path
        status
      }
      listMetadata {
        before
        after
      }
    }
  }
`;
export const UsersInCurrentTeamDocument = gql`
  query usersInCurrentTeam {
    me {
      id
    }
    currentTeam {
      id
      name
      users_teams {
        id
        role
        state
        user {
          id
          name
          email
        }
      }
    }
  }
`;
export const WebhookEndpointDocument = gql`
  query webhookEndpoint($id: String!) {
    webhookEndpoint(id: $id) {
      ...WebhookEndpoint
    }
  }
  ${WebhookEndpointFragmentDoc}
`;
export const WebhookEndpointsDocument = gql`
  query webhookEndpoints(
    $environmentId: String!
    $before: String
    $after: String
    $limit: Int
  ) {
    webhookEndpoints(
      environmentId: $environmentId
      before: $before
      after: $after
      limit: $limit
    ) {
      data {
        id
        endpointUrl
        events
        state
        createdAt
      }
      listMetadata {
        before
        after
      }
    }
  }
`;
export const WebhookEventsWithFixturesDocument = gql`
  query webhookEventsWithFixtures {
    webhookEvents {
      ...WebhookEventWithFixture
    }
  }
  ${WebhookEventWithFixtureFragmentDoc}
`;
export const WebhookEventsDocument = gql`
  query webhookEvents {
    webhookEvents {
      id
      description
    }
  }
`;
export const WebhookDocument = gql`
  query webhook($webhookId: String!) {
    webhook(id: $webhookId) {
      id
      webhook_endpoint_id
      event
      state
      attempt
      deliver_after
      metadata
      request_body
      response_body
    }
  }
`;
export const WebhooksForEndpointDocument = gql`
  query webhooksForEndpoint(
    $webhookEndpointId: String!
    $before: String
    $after: String
    $limit: Int
    $search: String
  ) {
    webhookEndpoint(id: $webhookEndpointId) {
      webhooks(before: $before, after: $after, limit: $limit, search: $search) {
        data {
          id
          webhook_endpoint_id
          event
          state
          attempt
          deliver_after
        }
        listMetadata {
          before
          after
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    addAdpConnectionSslCertificate(
      variables: AddAdpConnectionSslCertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AddAdpConnectionSslCertificateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddAdpConnectionSslCertificateMutation>(
            AddAdpConnectionSslCertificateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'addAdpConnectionSslCertificate',
      );
    },
    addUserToTeam(
      variables: AddUserToTeamMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AddUserToTeamMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddUserToTeamMutation>(
            AddUserToTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'addUserToTeam',
      );
    },
    addPaymentMethod(
      variables: AddPaymentMethodMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AddPaymentMethodMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddPaymentMethodMutation>(
            AddPaymentMethodDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'addPaymentMethod',
      );
    },
    addUsersToTeam(
      variables: AddUsersToTeamMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AddUsersToTeamMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddUsersToTeamMutation>(
            AddUsersToTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'addUsersToTeam',
      );
    },
    createBillingPortalSession(
      variables: CreateBillingPortalSessionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateBillingPortalSessionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateBillingPortalSessionMutation>(
            CreateBillingPortalSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createBillingPortalSession',
      );
    },
    createConnection(
      variables: CreateConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateConnectionMutation>(
            CreateConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createConnection',
      );
    },
    createDirectoryCustomAttribute(
      variables: CreateDirectoryCustomAttributeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateDirectoryCustomAttributeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateDirectoryCustomAttributeMutation>(
            CreateDirectoryCustomAttributeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createDirectoryCustomAttribute',
      );
    },
    createDirectory(
      variables: CreateDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateDirectoryMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateDirectoryMutation>(
            CreateDirectoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createDirectory',
      );
    },
    createKey(
      variables: CreateKeyMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateKeyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateKeyMutation>(CreateKeyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createKey',
      );
    },
    createOAuthCredentials(
      variables: CreateOAuthCredentialsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateOAuthCredentialsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOAuthCredentialsMutation>(
            CreateOAuthCredentialsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createOAuthCredentials',
      );
    },
    createOrganization(
      variables: CreateOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateOrganizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOrganizationMutation>(
            CreateOrganizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createOrganization',
      );
    },
    createRedirectUri(
      variables: CreateRedirectUriMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateRedirectUriMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateRedirectUriMutation>(
            CreateRedirectUriDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createRedirectUri',
      );
    },
    createWebhookEndpoint(
      variables: CreateWebhookEndpointMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateWebhookEndpointMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateWebhookEndpointMutation>(
            CreateWebhookEndpointDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createWebhookEndpoint',
      );
    },
    deleteAdpConnectionSslCertificate(
      variables: DeleteAdpConnectionSslCertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteAdpConnectionSslCertificateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteAdpConnectionSslCertificateMutation>(
            DeleteAdpConnectionSslCertificateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteAdpConnectionSslCertificate',
      );
    },
    deleteConnection(
      variables: DeleteConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteConnectionMutation>(
            DeleteConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteConnection',
      );
    },
    deleteDirectoryCustomAttribute(
      variables: DeleteDirectoryCustomAttributeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteDirectoryCustomAttributeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteDirectoryCustomAttributeMutation>(
            DeleteDirectoryCustomAttributeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteDirectoryCustomAttribute',
      );
    },
    deleteDirectory(
      variables: DeleteDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteDirectoryMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteDirectoryMutation>(
            DeleteDirectoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteDirectory',
      );
    },
    deleteOrganization(
      variables: DeleteOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteOrganizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteOrganizationMutation>(
            DeleteOrganizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteOrganization',
      );
    },
    deleteRedirectUri(
      variables: DeleteRedirectUriMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteRedirectUriMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteRedirectUriMutation>(
            DeleteRedirectUriDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteRedirectUri',
      );
    },
    deleteSamlX509Certificate(
      variables: DeleteSamlX509CertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteSamlX509CertificateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteSamlX509CertificateMutation>(
            DeleteSamlX509CertificateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteSamlX509Certificate',
      );
    },
    deleteWebhookEndpoint(
      variables: DeleteWebhookEndpointMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteWebhookEndpointMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteWebhookEndpointMutation>(
            DeleteWebhookEndpointDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteWebhookEndpoint',
      );
    },
    expireKey(
      variables: ExpireKeyMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ExpireKeyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExpireKeyMutation>(ExpireKeyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'expireKey',
      );
    },
    onboarding_completeStep(
      variables: Onboarding_CompleteStepMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<Onboarding_CompleteStepMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Onboarding_CompleteStepMutation>(
            Onboarding_CompleteStepDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'onboarding_completeStep',
      );
    },
    onboarding_enterTeamDetails(
      variables: Onboarding_EnterTeamDetailsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<Onboarding_EnterTeamDetailsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Onboarding_EnterTeamDetailsMutation>(
            Onboarding_EnterTeamDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'onboarding_enterTeamDetails',
      );
    },
    provisionTestAppAndUser(
      variables: ProvisionTestAppAndUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ProvisionTestAppAndUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProvisionTestAppAndUserMutation>(
            ProvisionTestAppAndUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'provisionTestAppAndUser',
      );
    },
    removeUserFromTeam(
      variables: RemoveUserFromTeamMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<RemoveUserFromTeamMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveUserFromTeamMutation>(
            RemoveUserFromTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'removeUserFromTeam',
      );
    },
    sendTestWebhook(
      variables: SendTestWebhookMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SendTestWebhookMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendTestWebhookMutation>(
            SendTestWebhookDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'sendTestWebhook',
      );
    },
    setDirectoryCustomAttributeMappings(
      variables: SetDirectoryCustomAttributeMappingsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SetDirectoryCustomAttributeMappingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetDirectoryCustomAttributeMappingsMutation>(
            SetDirectoryCustomAttributeMappingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'setDirectoryCustomAttributeMappings',
      );
    },
    setDomainsForOrganization(
      variables: SetDomainsForOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SetDomainsForOrganizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetDomainsForOrganizationMutation>(
            SetDomainsForOrganizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'setDomainsForOrganization',
      );
    },
    setEmailNotificationPreferences(
      variables: SetEmailNotificationPreferencesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SetEmailNotificationPreferencesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetEmailNotificationPreferencesMutation>(
            SetEmailNotificationPreferencesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'setEmailNotificationPreferences',
      );
    },
    setRedirectUris(
      variables: SetRedirectUrisMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SetRedirectUrisMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetRedirectUrisMutation>(
            SetRedirectUrisDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'setRedirectUris',
      );
    },
    updateAttributeMap(
      variables: UpdateAttributeMapMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateAttributeMapMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateAttributeMapMutation>(
            UpdateAttributeMapDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateAttributeMap',
      );
    },
    updateConnectionFromMetadataUrl(
      variables: UpdateConnectionFromMetadataUrlMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateConnectionFromMetadataUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateConnectionFromMetadataUrlMutation>(
            UpdateConnectionFromMetadataUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateConnectionFromMetadataUrl',
      );
    },
    updateConnectionFromMetadataXml(
      variables: UpdateConnectionFromMetadataXmlMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateConnectionFromMetadataXmlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateConnectionFromMetadataXmlMutation>(
            UpdateConnectionFromMetadataXmlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateConnectionFromMetadataXml',
      );
    },
    updateConnection(
      variables: UpdateConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateConnectionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateConnectionMutation>(
            UpdateConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateConnection',
      );
    },
    updateDirectoryAttributeMap(
      variables: UpdateDirectoryAttributeMapMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateDirectoryAttributeMapMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateDirectoryAttributeMapMutation>(
            UpdateDirectoryAttributeMapDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateDirectoryAttributeMap',
      );
    },
    updateDirectoryCustomAttribute(
      variables: UpdateDirectoryCustomAttributeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateDirectoryCustomAttributeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateDirectoryCustomAttributeMutation>(
            UpdateDirectoryCustomAttributeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateDirectoryCustomAttribute',
      );
    },
    updateDirectory(
      variables: UpdateDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateDirectoryMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateDirectoryMutation>(
            UpdateDirectoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateDirectory',
      );
    },
    changeRole(
      variables: ChangeRoleMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ChangeRoleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChangeRoleMutation>(ChangeRoleDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'changeRole',
      );
    },
    updateOAuthCredential(
      variables: UpdateOAuthCredentialMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateOAuthCredentialMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOAuthCredentialMutation>(
            UpdateOAuthCredentialDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateOAuthCredential',
      );
    },
    updateOrganization(
      variables: UpdateOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateOrganizationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateOrganizationMutation>(
            UpdateOrganizationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateOrganization',
      );
    },
    updatePortalSettings(
      variables: UpdatePortalSettingsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdatePortalSettingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePortalSettingsMutation>(
            UpdatePortalSettingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updatePortalSettings',
      );
    },
    updateSession(
      variables?: UpdateSessionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateSessionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateSessionMutation>(
            UpdateSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateSession',
      );
    },
    updateWebhookEndpoint(
      variables: UpdateWebhookEndpointMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateWebhookEndpointMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateWebhookEndpointMutation>(
            UpdateWebhookEndpointDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateWebhookEndpoint',
      );
    },
    apiKeys(
      variables: ApiKeysQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ApiKeysQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ApiKeysQuery>(ApiKeysDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'apiKeys',
      );
    },
    auditTrailEvent(
      variables: AuditTrailEventQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AuditTrailEventQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuditTrailEventQuery>(
            AuditTrailEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditTrailEvent',
      );
    },
    auditTrailEvents(
      variables: AuditTrailEventsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AuditTrailEventsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuditTrailEventsQuery>(
            AuditTrailEventsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditTrailEvents',
      );
    },
    connection(
      variables: ConnectionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ConnectionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConnectionQuery>(ConnectionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'connection',
      );
    },
    connectionsByType(
      variables: ConnectionsByTypeQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ConnectionsByTypeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConnectionsByTypeQuery>(
            ConnectionsByTypeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'connectionsByType',
      );
    },
    connections(
      variables: ConnectionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ConnectionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConnectionsQuery>(ConnectionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'connections',
      );
    },
    dashboardSession(
      variables?: DashboardSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DashboardSessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DashboardSessionQuery>(
            DashboardSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'dashboardSession',
      );
    },
    directories(
      variables: DirectoriesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoriesQuery>(DirectoriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'directories',
      );
    },
    directoryCustomAttributeMappings(
      variables: DirectoryCustomAttributeMappingsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoryCustomAttributeMappingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoryCustomAttributeMappingsQuery>(
            DirectoryCustomAttributeMappingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'directoryCustomAttributeMappings',
      );
    },
    directoryCustomAttributes(
      variables: DirectoryCustomAttributesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoryCustomAttributesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoryCustomAttributesQuery>(
            DirectoryCustomAttributesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'directoryCustomAttributes',
      );
    },
    directoryGroups(
      variables: DirectoryGroupsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoryGroupsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoryGroupsQuery>(
            DirectoryGroupsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'directoryGroups',
      );
    },
    directorySummary(
      variables: DirectorySummaryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectorySummaryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectorySummaryQuery>(
            DirectorySummaryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'directorySummary',
      );
    },
    directoryUser(
      variables: DirectoryUserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoryUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoryUserQuery>(DirectoryUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'directoryUser',
      );
    },
    directoryUsers(
      variables: DirectoryUsersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoryUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoryUsersQuery>(
            DirectoryUsersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'directoryUsers',
      );
    },
    directory(
      variables: DirectoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DirectoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DirectoryQuery>(DirectoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'directory',
      );
    },
    emailNotificationPreferences(
      variables?: EmailNotificationPreferencesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<EmailNotificationPreferencesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EmailNotificationPreferencesQuery>(
            EmailNotificationPreferencesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'emailNotificationPreferences',
      );
    },
    environment(
      variables: EnvironmentQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<EnvironmentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EnvironmentQuery>(EnvironmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'environment',
      );
    },
    organizationWithDomains(
      variables: OrganizationWithDomainsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<OrganizationWithDomainsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrganizationWithDomainsQuery>(
            OrganizationWithDomainsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'organizationWithDomains',
      );
    },
    organization(
      variables: OrganizationQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<OrganizationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrganizationQuery>(OrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'organization',
      );
    },
    organizations(
      variables: OrganizationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<OrganizationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrganizationsQuery>(OrganizationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'organizations',
      );
    },
    profileForIdpId(
      variables: ProfileForIdpIdQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<ProfileForIdpIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProfileForIdpIdQuery>(
            ProfileForIdpIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'profileForIdpId',
      );
    },
    redirectUris(
      variables: RedirectUrisQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<RedirectUrisQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RedirectUrisQuery>(RedirectUrisDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'redirectUris',
      );
    },
    request(
      variables: RequestQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<RequestQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RequestQuery>(RequestDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'request',
      );
    },
    requests(
      variables: RequestsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<RequestsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RequestsQuery>(RequestsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'requests',
      );
    },
    usersInCurrentTeam(
      variables?: UsersInCurrentTeamQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UsersInCurrentTeamQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UsersInCurrentTeamQuery>(
            UsersInCurrentTeamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'usersInCurrentTeam',
      );
    },
    webhookEndpoint(
      variables: WebhookEndpointQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WebhookEndpointQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebhookEndpointQuery>(
            WebhookEndpointDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'webhookEndpoint',
      );
    },
    webhookEndpoints(
      variables: WebhookEndpointsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WebhookEndpointsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebhookEndpointsQuery>(
            WebhookEndpointsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'webhookEndpoints',
      );
    },
    webhookEventsWithFixtures(
      variables?: WebhookEventsWithFixturesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WebhookEventsWithFixturesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebhookEventsWithFixturesQuery>(
            WebhookEventsWithFixturesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'webhookEventsWithFixtures',
      );
    },
    webhookEvents(
      variables?: WebhookEventsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WebhookEventsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebhookEventsQuery>(WebhookEventsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'webhookEvents',
      );
    },
    webhook(
      variables: WebhookQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WebhookQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebhookQuery>(WebhookDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'webhook',
      );
    },
    webhooksForEndpoint(
      variables: WebhooksForEndpointQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WebhooksForEndpointQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WebhooksForEndpointQuery>(
            WebhooksForEndpointDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'webhooksForEndpoint',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export function getSdkWithHooks(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  const sdk = getSdk(client, withWrapper);
  const genKey = <V extends Record<string, unknown> = Record<string, unknown>>(
    name: string,
    object: V = {} as V,
  ): SWRKeyInterface => [
    name,
    ...Object.keys(object)
      .sort()
      .map((key) => object[key]),
  ];
  return {
    ...sdk,
    useApiKeys(
      variables: ApiKeysQueryVariables,
      config?: SWRConfigInterface<ApiKeysQuery, ClientError>,
    ) {
      return useSWR<ApiKeysQuery, ClientError>(
        genKey<ApiKeysQueryVariables>('ApiKeys', variables),
        () => sdk.apiKeys(variables),
        config,
      );
    },
    useAuditTrailEvent(
      variables: AuditTrailEventQueryVariables,
      config?: SWRConfigInterface<AuditTrailEventQuery, ClientError>,
    ) {
      return useSWR<AuditTrailEventQuery, ClientError>(
        genKey<AuditTrailEventQueryVariables>('AuditTrailEvent', variables),
        () => sdk.auditTrailEvent(variables),
        config,
      );
    },
    useAuditTrailEvents(
      variables: AuditTrailEventsQueryVariables,
      config?: SWRConfigInterface<AuditTrailEventsQuery, ClientError>,
    ) {
      return useSWR<AuditTrailEventsQuery, ClientError>(
        genKey<AuditTrailEventsQueryVariables>('AuditTrailEvents', variables),
        () => sdk.auditTrailEvents(variables),
        config,
      );
    },
    useConnection(
      variables: ConnectionQueryVariables,
      config?: SWRConfigInterface<ConnectionQuery, ClientError>,
    ) {
      return useSWR<ConnectionQuery, ClientError>(
        genKey<ConnectionQueryVariables>('Connection', variables),
        () => sdk.connection(variables),
        config,
      );
    },
    useConnectionsByType(
      variables: ConnectionsByTypeQueryVariables,
      config?: SWRConfigInterface<ConnectionsByTypeQuery, ClientError>,
    ) {
      return useSWR<ConnectionsByTypeQuery, ClientError>(
        genKey<ConnectionsByTypeQueryVariables>('ConnectionsByType', variables),
        () => sdk.connectionsByType(variables),
        config,
      );
    },
    useConnections(
      variables: ConnectionsQueryVariables,
      config?: SWRConfigInterface<ConnectionsQuery, ClientError>,
    ) {
      return useSWR<ConnectionsQuery, ClientError>(
        genKey<ConnectionsQueryVariables>('Connections', variables),
        () => sdk.connections(variables),
        config,
      );
    },
    useDashboardSession(
      variables?: DashboardSessionQueryVariables,
      config?: SWRConfigInterface<DashboardSessionQuery, ClientError>,
    ) {
      return useSWR<DashboardSessionQuery, ClientError>(
        genKey<DashboardSessionQueryVariables>('DashboardSession', variables),
        () => sdk.dashboardSession(variables),
        config,
      );
    },
    useDirectories(
      variables: DirectoriesQueryVariables,
      config?: SWRConfigInterface<DirectoriesQuery, ClientError>,
    ) {
      return useSWR<DirectoriesQuery, ClientError>(
        genKey<DirectoriesQueryVariables>('Directories', variables),
        () => sdk.directories(variables),
        config,
      );
    },
    useDirectoryCustomAttributeMappings(
      variables: DirectoryCustomAttributeMappingsQueryVariables,
      config?: SWRConfigInterface<
        DirectoryCustomAttributeMappingsQuery,
        ClientError
      >,
    ) {
      return useSWR<DirectoryCustomAttributeMappingsQuery, ClientError>(
        genKey<DirectoryCustomAttributeMappingsQueryVariables>(
          'DirectoryCustomAttributeMappings',
          variables,
        ),
        () => sdk.directoryCustomAttributeMappings(variables),
        config,
      );
    },
    useDirectoryCustomAttributes(
      variables: DirectoryCustomAttributesQueryVariables,
      config?: SWRConfigInterface<DirectoryCustomAttributesQuery, ClientError>,
    ) {
      return useSWR<DirectoryCustomAttributesQuery, ClientError>(
        genKey<DirectoryCustomAttributesQueryVariables>(
          'DirectoryCustomAttributes',
          variables,
        ),
        () => sdk.directoryCustomAttributes(variables),
        config,
      );
    },
    useDirectoryGroups(
      variables: DirectoryGroupsQueryVariables,
      config?: SWRConfigInterface<DirectoryGroupsQuery, ClientError>,
    ) {
      return useSWR<DirectoryGroupsQuery, ClientError>(
        genKey<DirectoryGroupsQueryVariables>('DirectoryGroups', variables),
        () => sdk.directoryGroups(variables),
        config,
      );
    },
    useDirectorySummary(
      variables: DirectorySummaryQueryVariables,
      config?: SWRConfigInterface<DirectorySummaryQuery, ClientError>,
    ) {
      return useSWR<DirectorySummaryQuery, ClientError>(
        genKey<DirectorySummaryQueryVariables>('DirectorySummary', variables),
        () => sdk.directorySummary(variables),
        config,
      );
    },
    useDirectoryUser(
      variables: DirectoryUserQueryVariables,
      config?: SWRConfigInterface<DirectoryUserQuery, ClientError>,
    ) {
      return useSWR<DirectoryUserQuery, ClientError>(
        genKey<DirectoryUserQueryVariables>('DirectoryUser', variables),
        () => sdk.directoryUser(variables),
        config,
      );
    },
    useDirectoryUsers(
      variables: DirectoryUsersQueryVariables,
      config?: SWRConfigInterface<DirectoryUsersQuery, ClientError>,
    ) {
      return useSWR<DirectoryUsersQuery, ClientError>(
        genKey<DirectoryUsersQueryVariables>('DirectoryUsers', variables),
        () => sdk.directoryUsers(variables),
        config,
      );
    },
    useDirectory(
      variables: DirectoryQueryVariables,
      config?: SWRConfigInterface<DirectoryQuery, ClientError>,
    ) {
      return useSWR<DirectoryQuery, ClientError>(
        genKey<DirectoryQueryVariables>('Directory', variables),
        () => sdk.directory(variables),
        config,
      );
    },
    useEmailNotificationPreferences(
      variables?: EmailNotificationPreferencesQueryVariables,
      config?: SWRConfigInterface<
        EmailNotificationPreferencesQuery,
        ClientError
      >,
    ) {
      return useSWR<EmailNotificationPreferencesQuery, ClientError>(
        genKey<EmailNotificationPreferencesQueryVariables>(
          'EmailNotificationPreferences',
          variables,
        ),
        () => sdk.emailNotificationPreferences(variables),
        config,
      );
    },
    useEnvironment(
      variables: EnvironmentQueryVariables,
      config?: SWRConfigInterface<EnvironmentQuery, ClientError>,
    ) {
      return useSWR<EnvironmentQuery, ClientError>(
        genKey<EnvironmentQueryVariables>('Environment', variables),
        () => sdk.environment(variables),
        config,
      );
    },
    useOrganizationWithDomains(
      variables: OrganizationWithDomainsQueryVariables,
      config?: SWRConfigInterface<OrganizationWithDomainsQuery, ClientError>,
    ) {
      return useSWR<OrganizationWithDomainsQuery, ClientError>(
        genKey<OrganizationWithDomainsQueryVariables>(
          'OrganizationWithDomains',
          variables,
        ),
        () => sdk.organizationWithDomains(variables),
        config,
      );
    },
    useOrganization(
      variables: OrganizationQueryVariables,
      config?: SWRConfigInterface<OrganizationQuery, ClientError>,
    ) {
      return useSWR<OrganizationQuery, ClientError>(
        genKey<OrganizationQueryVariables>('Organization', variables),
        () => sdk.organization(variables),
        config,
      );
    },
    useOrganizations(
      variables: OrganizationsQueryVariables,
      config?: SWRConfigInterface<OrganizationsQuery, ClientError>,
    ) {
      return useSWR<OrganizationsQuery, ClientError>(
        genKey<OrganizationsQueryVariables>('Organizations', variables),
        () => sdk.organizations(variables),
        config,
      );
    },
    useProfileForIdpId(
      variables: ProfileForIdpIdQueryVariables,
      config?: SWRConfigInterface<ProfileForIdpIdQuery, ClientError>,
    ) {
      return useSWR<ProfileForIdpIdQuery, ClientError>(
        genKey<ProfileForIdpIdQueryVariables>('ProfileForIdpId', variables),
        () => sdk.profileForIdpId(variables),
        config,
      );
    },
    useRedirectUris(
      variables: RedirectUrisQueryVariables,
      config?: SWRConfigInterface<RedirectUrisQuery, ClientError>,
    ) {
      return useSWR<RedirectUrisQuery, ClientError>(
        genKey<RedirectUrisQueryVariables>('RedirectUris', variables),
        () => sdk.redirectUris(variables),
        config,
      );
    },
    useRequest(
      variables: RequestQueryVariables,
      config?: SWRConfigInterface<RequestQuery, ClientError>,
    ) {
      return useSWR<RequestQuery, ClientError>(
        genKey<RequestQueryVariables>('Request', variables),
        () => sdk.request(variables),
        config,
      );
    },
    useRequests(
      variables: RequestsQueryVariables,
      config?: SWRConfigInterface<RequestsQuery, ClientError>,
    ) {
      return useSWR<RequestsQuery, ClientError>(
        genKey<RequestsQueryVariables>('Requests', variables),
        () => sdk.requests(variables),
        config,
      );
    },
    useUsersInCurrentTeam(
      variables?: UsersInCurrentTeamQueryVariables,
      config?: SWRConfigInterface<UsersInCurrentTeamQuery, ClientError>,
    ) {
      return useSWR<UsersInCurrentTeamQuery, ClientError>(
        genKey<UsersInCurrentTeamQueryVariables>(
          'UsersInCurrentTeam',
          variables,
        ),
        () => sdk.usersInCurrentTeam(variables),
        config,
      );
    },
    useWebhookEndpoint(
      variables: WebhookEndpointQueryVariables,
      config?: SWRConfigInterface<WebhookEndpointQuery, ClientError>,
    ) {
      return useSWR<WebhookEndpointQuery, ClientError>(
        genKey<WebhookEndpointQueryVariables>('WebhookEndpoint', variables),
        () => sdk.webhookEndpoint(variables),
        config,
      );
    },
    useWebhookEndpoints(
      variables: WebhookEndpointsQueryVariables,
      config?: SWRConfigInterface<WebhookEndpointsQuery, ClientError>,
    ) {
      return useSWR<WebhookEndpointsQuery, ClientError>(
        genKey<WebhookEndpointsQueryVariables>('WebhookEndpoints', variables),
        () => sdk.webhookEndpoints(variables),
        config,
      );
    },
    useWebhookEventsWithFixtures(
      variables?: WebhookEventsWithFixturesQueryVariables,
      config?: SWRConfigInterface<WebhookEventsWithFixturesQuery, ClientError>,
    ) {
      return useSWR<WebhookEventsWithFixturesQuery, ClientError>(
        genKey<WebhookEventsWithFixturesQueryVariables>(
          'WebhookEventsWithFixtures',
          variables,
        ),
        () => sdk.webhookEventsWithFixtures(variables),
        config,
      );
    },
    useWebhookEvents(
      variables?: WebhookEventsQueryVariables,
      config?: SWRConfigInterface<WebhookEventsQuery, ClientError>,
    ) {
      return useSWR<WebhookEventsQuery, ClientError>(
        genKey<WebhookEventsQueryVariables>('WebhookEvents', variables),
        () => sdk.webhookEvents(variables),
        config,
      );
    },
    useWebhook(
      variables: WebhookQueryVariables,
      config?: SWRConfigInterface<WebhookQuery, ClientError>,
    ) {
      return useSWR<WebhookQuery, ClientError>(
        genKey<WebhookQueryVariables>('Webhook', variables),
        () => sdk.webhook(variables),
        config,
      );
    },
    useWebhooksForEndpoint(
      variables: WebhooksForEndpointQueryVariables,
      config?: SWRConfigInterface<WebhooksForEndpointQuery, ClientError>,
    ) {
      return useSWR<WebhooksForEndpointQuery, ClientError>(
        genKey<WebhooksForEndpointQueryVariables>(
          'WebhooksForEndpoint',
          variables,
        ),
        () => sdk.webhooksForEndpoint(variables),
        config,
      );
    },
  };
}
export type SdkWithHooks = ReturnType<typeof getSdkWithHooks>;
