import classnames from 'classnames';
import { EnvironmentFragment } from 'graphql/generated';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { isEnvironmentAgnosticRoute } from 'utils/routing/environment-agnostic-routes';

export interface SidebarItem {
  href?: string;
  icon: JSX.Element;
  name: string;
  newTab?: boolean;
  onClick?: () => void;
  path?: string;
  paths: string[];
}

interface SidebarItemLinkProps {
  currentEnvironment: EnvironmentFragment | undefined;
  item: SidebarItem;
}

export const SidebarItemLink: FC<Readonly<SidebarItemLinkProps>> = ({
  currentEnvironment,
  item,
}): JSX.Element => {
  const router = useRouter();

  const active = !!item.paths.find((path) => router.asPath.includes(path));

  const itemClasses = classnames(
    'cursor-pointer mb-4 text-sm transition duration-200',
    'hover:text-primary dark:hover:text-gray-darkmode-400',
    'focus:outline-none focus:text-primary-darken',
    {
      'text-gray-lightmode-400 dark:text-gray-darkmode-300 dark:focus:text-gray-darkmode-300': !active,
      'text-primary font-medium dark:text-gray-darkmode-400': active,
    },
  );

  const ItemDisplay = () => (
    <div className="flex items-center">
      {item.icon}
      <span className="ml-1.5">{item.name}</span>
    </div>
  );

  if (item.href) {
    return (
      <a
        key={item.name}
        className={itemClasses}
        data-testid={`sidebar-item-link-${item.name}`}
        href={item.href || '#'}
        onClick={() => {
          if (item.onClick) {
            item.onClick();
          }
        }}
        rel="noreferrer"
        target={item.newTab ? '_blank' : '_self'}
      >
        <ItemDisplay />
      </a>
    );
  }

  if (!item.path) {
    return (
      <span
        key={item.name}
        className={itemClasses}
        onClick={() => {
          if (item.onClick) {
            item.onClick();
          }
        }}
      >
        <ItemDisplay />
      </span>
    );
  }

  return (
    <Link
      key={item.name}
      href={{
        pathname: item.path,
        query: !isEnvironmentAgnosticRoute(item.path)
          ? { environmentId: currentEnvironment?.id }
          : {},
      }}
    >
      <a className={itemClasses} data-testid={`sidebar-item-link-${item.name}`}>
        <ItemDisplay />
      </a>
    </Link>
  );
};
