import { pipe } from 'fp-ts/lib/function';
import * as R from 'fp-ts/lib/Record';
import { EnvironmentFragment } from 'graphql/generated';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEnvironmentAgnosticRoute } from './routing/environment-agnostic-routes';
import { sdk } from './sdk';

interface ContextValue {
  availableEnvironments: EnvironmentFragment[];
  setAvailableEnvironments: (
    availableEnvironments: EnvironmentFragment[],
  ) => void;
  currentEnvironment?: EnvironmentFragment;
  setCurrentEnvironment: (environment: EnvironmentFragment) => void;
  environmentId: string;
}

export const CurrentEnvironmentContext = createContext<ContextValue | null>(
  null,
);

export interface CurrentEnvironmentProviderProps {}

const ENVIRONMENT_ROUTE_REGEX = /^(\/\[environmentId\]\/([^\/]+))\//;

// Some page types do not include the top level environment route in the path.
// This lookup maps those types to the corresponding base environment page
const pageTypeLookup: Record<string, string> = {
  'directory-sync': '/[environmentId]/organizations',
  'sso': '/[environmentId]/organizations',
};

const getBaseEnvironmentRoute = (pathname: string): string | undefined => {
  const routeMatchGroups = pathname.match(ENVIRONMENT_ROUTE_REGEX);
  if (routeMatchGroups && routeMatchGroups.length > 1) {
    const basePath = routeMatchGroups[1];
    const pageType = routeMatchGroups[2];
    return (pageType && pageTypeLookup[pageType]) || basePath;
  }
};

const omitParamsOnEnvironmentChange = new Set<string>([
  'before',
  'after',
  'limit',
]);

export const CurrentEnvironmentProvider: React.FC<
  Readonly<CurrentEnvironmentProviderProps>
> = ({ children }) => {
  const [
    currentEnvironment,
    setCurrentEnvironment,
  ] = useState<EnvironmentFragment>();

  const [availableEnvironments, setAvailableEnvironments] = useState<
    EnvironmentFragment[]
  >([]);

  const router = useRouter();

  const updateSessionSelectedEnvironment = useCallback(
    async (environment: EnvironmentFragment) => {
      await sdk().updateSession({
        currentEnvironmentId: environment?.id,
        currentProjectID: environment?.clientId,
      });
    },
    [],
  );

  const setEnvironment = useCallback(
    async (environment: EnvironmentFragment) => {
      const isFirstLoad = !currentEnvironment;

      setCurrentEnvironment(environment);

      void updateSessionSelectedEnvironment(environment);

      // Route to the correct URL properly
      // If we have changed the environment on a deep link to a specific resource, we should go to the base page
      const baseEnvironmentRoute = getBaseEnvironmentRoute(router.pathname);
      if (baseEnvironmentRoute && !isFirstLoad) {
        // Don't re-direct on the first load
        return router.replace({
          pathname: baseEnvironmentRoute,
          query: {
            environmentId: environment.id,
          },
        });
      }

      const hasEnvironmentIdInPath =
        router.query.environmentId ||
        router.pathname.includes('[environmentId]');

      const queryParams = pipe(
        router.query,
        R.filterWithIndex((key) => !omitParamsOnEnvironmentChange.has(key)),
      );

      if (isEnvironmentAgnosticRoute(router.pathname)) {
        return router.replace(
          {
            pathname: router.pathname,
            query: { ...queryParams, environmentId: environment.id },
          },
          router.asPath,
        );
      } else if (!hasEnvironmentIdInPath) {
        return router.replace({
          pathname: `/[environmentId]${router.pathname}`,
          query: { ...queryParams, environmentId: environment.id },
        });
      } else {
        return router.push({
          pathname: router.pathname,
          query: { ...queryParams, environmentId: environment.id },
        });
      }
    },
    [currentEnvironment, router, updateSessionSelectedEnvironment],
  );

  return (
    <CurrentEnvironmentContext.Provider
      value={{
        availableEnvironments,
        setAvailableEnvironments,
        currentEnvironment,
        setCurrentEnvironment: setEnvironment,
        environmentId: currentEnvironment?.id || '',
      }}
    >
      {children}
    </CurrentEnvironmentContext.Provider>
  );
};

export function useCurrentEnvironment(): ContextValue {
  const currentEnvironmentContext = useContext(CurrentEnvironmentContext);
  if (!currentEnvironmentContext) {
    throw new TypeError(
      '`useCurrentEnvironment` must be called from within an `CurrentEnvironmentProvider`',
    );
  }

  return currentEnvironmentContext;
}
