import * as t from 'io-ts';

export const ApiLogIdCopied = t.strict({}, 'ApiLogIdCopied');

export type ApiLogIdCopied = typeof ApiLogIdCopied;

export const BillingLinkViewed = t.strict({}, 'BillingLinkViewed');

export type BillingLinkViewed = typeof BillingLinkViewed;

export const DomainAddedToOrganization = t.strict(
  {},
  'DomainAddedToOrganization',
);

export type DomainAddedToOrganization = typeof DomainAddedToOrganization;

export const EmailNotificationPreferencesUpdated = t.strict(
  {
    category: t.string,
    preference: t.string,
  },
  'EmailNotificationPreferencesUpdated',
);

export type EmailNotificationPreferencesUpdated = typeof EmailNotificationPreferencesUpdated;

export const OrganizationCreated = t.strict(
  {
    withDomains: t.union([t.boolean, t.undefined]),
  },
  'OrganizationCreated',
);

export type OrganizationCreated = typeof OrganizationCreated;

export const ThemeChanged = t.strict(
  {
    theme: t.string,
  },
  'ThemeChanged',
);

export type ThemeChanged = typeof ThemeChanged;

export const UserSignedUp = t.strict(
  {
    method: t.union([t.literal('google_oauth'), t.literal('email_password')]),
  },
  'UserSignedUp',
);

export type UserSignedUp = typeof UserSignedUp;

export const WebhookSecretCopied = t.strict({}, 'WebhookSecretCopied');

export type WebhookSecretCopied = typeof WebhookSecretCopied;

export type AnalyticEvent =
  | ApiLogIdCopied
  | BillingLinkViewed
  | DomainAddedToOrganization
  | EmailNotificationPreferencesUpdated
  | OrganizationCreated
  | ThemeChanged
  | UserSignedUp
  | WebhookSecretCopied;
