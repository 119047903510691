import Head from 'next/head';
import React, { FC } from 'react';

export interface UnauthenticatedPageProps {}

export const UnauthenticatedPage: FC<Readonly<UnauthenticatedPageProps>> = ({
  children,
}) => (
  <>
    <Head>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
    </Head>

    <div className="w-full min-h-screen bg-gray-lightmode-100 dark:bg-gray-darkmode-100">
      <div className="max-w-4xl px-8 mx-auto">{children}</div>
    </div>
  </>
);
