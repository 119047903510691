import classnames from 'classnames';
import React, { FC } from 'react';
import { CardBody } from './card-body';
import { CardFooter } from './card-footer';
import { CardHeader } from './card-header';

export interface CardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

export interface Card {
  Header: typeof CardHeader;
  Body: typeof CardBody;
  Footer: typeof CardFooter;
}

export const Card: FC<Readonly<CardProps>> & Card = ({
  className,
  children,
  ...props
}) => (
  <div
    {...props}
    className={classnames(
      'overflow-hidden rounded-lg border',
      'bg-white border-gray-lightmode-200',
      'dark:bg-gray-darkmode-50 dark:border-gray-darkmode-200',
      className,
    )}
  >
    {children}
  </div>
);

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;
