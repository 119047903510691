import { UsersOrganizationsRole } from 'graphql/generated';
import { useAuth } from 'utils/auth-context';

interface UseUserRole {
  isUserAdmin: boolean;
  role?: UsersOrganizationsRole;
}

export const useUserRole = (): UseUserRole => {
  const { user } = useAuth();

  const usersTeams = user?.users_teams ?? [];

  const role = usersTeams.find((usersTeam) => usersTeam.user.id === user?.id)
    ?.role;

  const isUserAdmin = role === UsersOrganizationsRole.Admin;

  return {
    isUserAdmin,
    role,
  };
};
