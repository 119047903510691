import classnames from 'classnames';
import {
  EnvironmentFragment,
  EnvironmentWithProjectV2Fragment,
} from 'graphql/generated';
import React, { FC } from 'react';
import { Box } from 'react-feather';

interface EnvironmentDisplayProps {
  environment: EnvironmentWithProjectV2Fragment | EnvironmentFragment;
  isHoverEnabled: boolean;
}

export const EnvironmentDisplay: FC<Readonly<EnvironmentDisplayProps>> = ({
  environment,
  isHoverEnabled,
}): JSX.Element => (
  <div className="flex group items-center">
    <div className="mr-1 w-4">
      <Box
        className={classnames([
          {
            'text-green': !environment.sandbox,
            'text-orange-lighten': environment.sandbox,
          },
        ])}
        data-testid={`environment-display-icon-${environment.id}`}
        size={16}
      />
    </div>

    <span
      className={classnames([
        'font-medium line-clamp-1 pr-1 text-gray-lightmode-400 dark:text-gray-darkmode-400 text-left text-sm duration-200 ease-in-out transition',
        {
          'group-hover:text-primary': isHoverEnabled,
        },
      ])}
      title={environment.name}
    >
      {environment.name}
    </span>
  </div>
);
