import Bugsnag, { NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AuthedUserFragment } from 'graphql/generated';
import React from 'react';

// Configuration options are here: https://docs.bugsnag.com/platforms/javascript/react/#basic-configuration
Bugsnag.start({
  // Bugsnag tries to be smart and check if the API Key's length is greater than 0
  // otherwise it will throw a TypeError "No Bugsnag API Key set".
  // Next.js _also_ tries to be smart and during build, this function is run.
  // This results in a broken CI since the environment variable is not available.
  // To fix, we will fallback to an empty string, who's length is greater than 0.
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || ' ',
  // Only report errors in production or staging
  enabledReleaseStages: ['production', 'staging'],
  // Turn off logging in development mode, otherwise don't set one
  logger: process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? null : undefined,
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.NEXT_PUBLIC_NODE_ENV,
});

export const setBugsnagSession = (user: AuthedUserFragment): void => {
  Bugsnag.setUser(user.id, undefined, user.name || undefined);
};

export const sendBugsnagError = (error: NotifiableError): void => {
  Bugsnag.notify(error);
};

export default Bugsnag;
